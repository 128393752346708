import React,{useState} from "react";
import "./HeroBanner.css";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios"
import {Spinner} from "react-bootstrap"

const listPoint = ["Work Permit & LMIA", "Family Sponsorship","Permanent Residence & Citizenship", "Refugee claims","Express Entry & PNPs", "H&C applications","Business Visa", "Inadmissibility","Study Permit"]

const HeroBanner = ({
  Heading,
  subHeading,
  NewLinespanHeading,
  headingSmall=false,
  preSpanHeading="",
  spanHeading="",
  postParagraph="",
  postParagraphCenter=false,
  Paragraph,
  btnText,
  bgImg,
  btn=false,
  socialLinks=false,
  serviceForm=false,
  darkBg=false,
  headingCenter=false,
  sideImage=false,
  sideImageRef,
  statSection=false,
  isChevron=false
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form-main", {
        email: email,
        name:name,
        message:message,
        phoneNum:phone,
        formType: "Free Call Booking",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Message sent Successfully!");
          setLoading(false);
          setEmail("");
          window.open("/thank-you", '_blank');
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Failed to send message, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  
  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); 
    const maxLength = 25;


    if (numericValue.length <= maxLength) {
      setPhone(numericValue);
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(inputValue) || inputValue === '') {
      setName(inputValue);
    }
  };



  const paragraphs = Paragraph.split('\n').map((paragraph, index) => <p key={index} className="body-paragraph my-2" style={headingCenter ? {textAlign:"center",margin:"auto",color:"white"}:{color:"white"}}>{paragraph}</p>);



  return (
    <div
      className={`common_hero_section ${!socialLinks && "mb-4"} ${serviceForm || sideImage && "d-grid gap-md-0 gap-5"} `}
      style={bgImg == ""? {} :{ backgroundImage: `url(${bgImg})`,backgroundRepeat:"no-repeat",backgroundSize:"cover" }}
    >
      <div className="justify-self-start align-item-start">
        <Header darkBg={darkBg} />
      </div>

      <div className={`container text-left-container d-flex justify-content-center align-items-center justify-self-center ${headingCenter && "justify-content-center"} ${sideImage ? "py-lg-1 py-5" : "py-5"} `}>
        <div className={`row gy-2 d-flex  py-lg-0 w-100 ${sideImage ? "align-items-center":"align-items-center"} ${headingCenter && "justify-content-center"} `}>
          <div
            className={
              serviceForm || sideImage
                ? "col-lg-6 "
                : "col-12 hero_content_wrapper"
            }
            style={serviceForm ? {paddingTop:"30px",paddingBottom:"30px",minHeight:"90vh",display:"flex",alignItems:"center"}:{}}
          >
            <div className="hero-text-content px-md-1 px-4 w-100">
            <p className={`sub-heading-text ${darkBg && "text-center"}`}>{subHeading}</p>
            <h1 className={`hero-section-heading ${headingCenter && "text-center"}`}
             id={`${headingSmall && "heading-small"}`}
            style={darkBg ? {color:"#FFFFFF",maxWidth:"100%",textAlign:"center"} : {}}>
              <span className="color-yellow">{preSpanHeading}</span>
              {Heading}
              <span className="color-yellow">{spanHeading}</span>
              <br></br>
              <span className="color-yellow">{NewLinespanHeading}</span>
            </h1>
            <h5 className="post-para py-1" style={postParagraphCenter ? {textAlign:"center"}:{}}>{postParagraph}</h5>
            <div className="para" >{paragraphs}</div>
            {statSection && 
            <div className='row gy-3 py-2 '>
                {
                    listPoint.map(ele =>{
                        return(
                            <div className='d-flex align-items-center col-lg-6 gap-lg-1 gap-xl-2 gap-2 icon-list'>
                                <img src='/images/icons/hero-bullets.png' alt="icon" className='list-bullet'/>
                                <p className='list-para mb-0'>{ele}</p>
                            </div>)
                    })
                }
            </div>
            }
            {btn && <button className="hero-btn genral-btn d-flex align-items-center gap-2">{btnText} <img src="/images/icons/button-arrow-right.svg" alt="icon"/></button>}
            {isChevron && <div className="chevron-container d-flex align-items-center justify-content-center mt-4"><img src="/images/icons/cheveron-icon.png" alt="icon" className="chevron-icon"/></div>}
            {socialLinks && (
              <div className="social-links">
                <div className="first-row d-flex flex-md-row flex-column gap-3">
                  <div className="link-container d-flex align-items-center gap-1 my-2">
                    <img src="/images/icons/hero-phone.svg" alt="icon" />
                    <p className="mb-0" style={{userSelect:"all"}}>416-891-0909</p>
                  </div>
                  <div className="link-container d-flex align-items-center gap-1 my-2">
                    <img src="/images/icons/hero-wa.svg" alt="icon" />
                    <p className="mb-0" style={{userSelect:"all"}}>437-422-0422</p>
                  </div>
                </div>
                <div className="link-container d-flex align-items-center gap-1 my-2">
                  <img src="/images/icons/hero-mail.svg" alt="icon"/>
                  <p className="mb-0" style={{userSelect:"all"}}>info@vcicimmigration.com</p>
                </div>
                <div className="link-container d-flex align-items-center gap-1 my-2">
                  <img src="/images/icons/hero-location.svg" alt="icon"/>
                  <p className="mb-0">Vajra Canadian Immigration Consultancy Inc. 863 Whitlock Ave Milton, ON L9E 1R8</p>
                </div>
              </div>
            )}
          </div>
          </div>
          {sideImage && (
            <div className="col-lg-6">
              <img className="img-fluid side-image" alt="image" src={sideImageRef} />
            </div>
          )}
          {serviceForm && (
            <div className="col-lg-6">
              <form className="service-form d-flex flex-column p-4 py-5" onSubmit={formSubmitHandler}>
                <input
                   type="text" 
                   value={name}
                   required
                   maxLength="36"
                   onChange={handleNameChange}
                   placeholder="Your Full Name"  
                   className="inp-field"
                />
                <input 
                   type="tel" 
                   value={phone}
                   maxLength="25"
                   required
                   onChange={handlePhoneChange}
                   placeholder="Your Phone Number"
                  className="inp-field"
                />
                <input
                 type="email" 
                 value={email}
                 maxLength="66"
                 required
                 onChange={(e) => setEmail(e.target.value)}
                 placeholder="Your E-mail" 
                 className="inp-field"
                 />
                <textarea 
                  className="inp-field" rows={4} 
                  value={message}
                  maxLength="350"
                  required
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type Your Message Here...">

                  </textarea>
                <div className="d-flex align-items-end justify-content-end">
                  <button className="service-from-btn genral-btn d-flex align-items-center justify-content-center" type="submit">Submit Now
                  {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                                    />
                                ) : (
                                success ? (
                                    <div>
                                    {setLoading(false)}
                                    {setSuccess(false)}
                                    </div>
                                ) : (
                                    error && (
                                    <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                    </div>
                                    )
                                )
                                )}
                  
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroBanner; 