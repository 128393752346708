import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import CardBlog from "../components/CardBlog/CardBlog"
import InadmissibleText from '../components/InadmissibleText/InadmissibleText'
import InadmissibilityPoints from '../components/InadmissibilityPoints/InadmissibilityPoints'
import InadmissibilityCall from '../components/InadmissibilityCall/InadmissibilityCall'
import { Helmet } from 'react-helmet'

const listData1=[
    {
        title:"Criminality",
        content:"Anybody who have committed or convicted for a criminal offence."
    },
    {
        title:"Security concerns",
        content:"Anybody who is regarded as a security threat to Canada"
    },
    {
        title:"Health issues",
        content:"Anybody who has a contagious disease that poses a health risk to the general public."
    },
    {
        title:"Financial reasons",
        content:"Anybody who doesn’t have the financial resources to support themselves or their family members."
    },
    {
        title:"Misrepresentation",
        content:"Anybody who provides false information to obtain a visa"
    },
]

const listData2 =[
    {
        title:"Deemed Rehabilitation",
        content:"it means that enough time has passed since you were criminally convicted which may no longer be the reason to stop you from entering Canada. It depends upon the crime, if sufficient time has passed after you have served the sentence or if you have committed more than one crime. In all cases, you can be deemed rehabilitated if the crime you were convicted for in your country has a punishment of less than 10 years if you committed the same crime in Canada."
    },
    {
        title:"Individual Rehabilitation",
        content:"You have to prove to the Immigration officer that you are not likely to commit any crimes in the future. you need to show that you meet the criteria. More than 5 years have passed since you served your sentence and committed the crime."
    },
    {
        title:"Record Suspension or discharge",
        content:"If you were convicted in Canada, you can apply for a record suspension. If you are successful at getting your record suspended, you are no longer inadmissible to Canada. If you were convicted outside of Canada and you receive a record suspension, you need to check with the visa office located in the country where you committed the crime."
    },
]

function Inadmissibe() {
    return (
        <>
        <Helmet>
            <title>Inadmissibility to Canada - VCIC Immigration</title>
            <meta name="description" content='Are you facing inadmissibility to Canada? Check this guide out on reasons and ways to overcome inadmissibility. Book a free call today!'/>
            <link rel="canonical" href="https://vcicimmigration.com/inadmissibility/"></link>
        </Helmet>
        <HeroBanner
            Heading="Inadmissibility to"
            spanHeading=" Canada"
            subHeading=""
            postParagraph=''
            Paragraph="Our team tackles inadmissibility issues and challenges head-on, helping you navigate obstacles and achieve your immigration goals."
            btnText=""
            bgImg="/images/creatives/inadmissible-bg.jpg"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            isChevron={true}
        />
    
        <div style={{marginTop:"130px"}}></div>
    
        <InadmissibleText
            heading={"What Does"}
            spanHeading={" Inadmissibility In Canada Mean?"}
             img="/images/creatives/inadmissible-text-1.png"
             shiftRow={true}
             content1="Inadmissibility is when an individual is denied entry into Canada by the Canadian Immigration authorities."
             postHeading="What Are Some Reasons For Inadmissibility?"
             content2="There are multiple reasons why an individual can be denied entry into Canada and is rendered ‘inadmissible’. Here are some of the reasons:"
             listPoint={listData1}
        />
        <InadmissibleText
            heading={"How To Overcome"}
            spanHeading={" Inadmissibility In Canada?"}
             img="/images/creatives/inadmissible-text-2.png"
             shiftRow={false}
             content1="This is what you can do to overcome inadmissibility:"
             postHeading=""
             content2=""
             listPoint={listData2}
             topHeading={true}
        />
    
        <InadmissibilityPoints/>

        <InadmissibilityCall/>
    </>
      )
}

export default Inadmissibe