import React from 'react'
import { useNavigate } from 'react-router-dom'

function PopularBlogCard({
    img,
    title,
    date,
    anchor,
    redirectionFunction
}) {

  const navigate = useNavigate("/")
  return (
    <div className='popular-card d-flex  gap-3 align-items-center my-3 py-1' onClick={()=>{redirectionFunction(anchor)}}>
        <img src={img} className='popular-blog-img' alt='blog-img'/>
        <div className='blog-details d-flex flex-column gap-3'>
            <h5 className='card-title'>{title}</h5>
            <p className='card-date mb-0'>{date}</p>
        </div>
    </div>
  )
}

export default PopularBlogCard