import React from 'react'
import Header from '../components/Header/Header'
import ECardSection from "../components/ECardSection/ECardSection"

function ECards() {
  return (
    <div>
        <Header
            darkBg={true}
            disableOnMobile={true}
        />

        <ECardSection/>
    </div>
  )
}

export default ECards