import React from 'react'
import "./CenteredTextLineDiv.css"
import { useNavigate } from 'react-router-dom';

function CenteredText({
    heading="",
    spanHeading="",
    afterSpanHeading="",
    content="",
    btn=false,
    btnText="",
    btnRef="",
    dividerLength=false
}) {
    const navigate = useNavigate()
    let paragraphs = content.split('\n')
    paragraphs = paragraphs.map((paragraph, index) =>{ return(<> <p key={index} className='body-paragraph my-4'>{paragraph}</p> {index < (paragraphs.length-1) && index!==0 ?<div className={`line-break ${dividerLength && "shortDivider"}`}></div> :<></>} </>)});
  return (
    <div className='centered-text-line-container standard-margin-space'>
        <div className='text-content container text-center mx-auto standard-padding-space' >
            <h3 className='body-heading mx-auto' style={{width:"90%"}}>
                {heading}
                <span className='color-yellow'>
                    {spanHeading}
                </span>
                {afterSpanHeading}
            </h3>
            <div className=' mt-3 mx-auto' style={{width:"95%"}}>
                {paragraphs}
            </div>
        { btn && <button className="mt-4 genral-btn mx-auto" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
        </div>
    </div>
  )
}

export default CenteredText