import React from 'react'
import "./IECCenteredText.css"
import { useNavigate } from 'react-router-dom'

function IECCenteredText() {
  return (
    <div className='standard-padding-space'>
    <CenteredTextCustom
        heading="Working Holiday"
        spanHeading=""
        afterSpanHeading=""
        content={"Participants in this program can receive an open work permit, valid for one to two years. Open work permits allow participants to work anywhere in Canada for almost any Canadian employer. Nationals of some countries may be allowed to stay in Canada for more or less than one year."}
        btn={false}
    />
    <CenteredTextCustom
        heading="Young Professionals"
        spanHeading=""
        afterSpanHeading=""
        content={"The Young Professionals category Covers all foreign youth, particularly post-secondary graduates, who wish to pursue their careers by gaining professional work experience in Canada.\n Participants must have a signed employment offer letter or contract of employment with a Canadian employer before applying. The job offered must be classified as a National Occupation Code (NOC) Skill Type Level 0, A, or B."}
        btn={false}
    />
    <CenteredTextCustom
        heading="International Co-op (Internship)"
        spanHeading=""
        afterSpanHeading=""
        content={"The International Co-op (Internship) category allows foreign youth who are enrolled at a post-secondary institution in their country of citizenship.\n Applicants must want to complete a work placement or internship in Canada to fulfill part of their academic curriculum and be registered students for the duration of the internship. Permits issued under this category are generally valid for up to 12 months.\n Applicants must have a signed job offer letter or contract for a work placement or internship in Canada that meets the requirements of their current academic curriculum and must be registered students for the duration of the internship."}
        btn={false}
    />
    </div>
  )
}

function CenteredTextCustom({
    heading="",
    spanHeading="",
    afterSpanHeading="",
    content="",
    btn=false,
    btnText="",
    btnRef="",
}) {
    const navigate = useNavigate()
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-2 mb-4'>{paragraph}</p>);
  return (
    <div className={`iec-centered-text-container my-5`}>
        <div className='text-content container text-center mx-auto ' >
            <h3 className='body-heading mx-auto mb-2' style={{width:"90%"}}>
                {heading}
                <span className='color-yellow'>
                    {spanHeading}
                </span>
                {afterSpanHeading}
            </h3>
            <div className=' mt-3 mx-auto' style={{width:"95%"}}>
                {paragraphs}
            </div>
        { btn && <button className="mt-4 genral-btn mx-auto" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
        </div>
    </div>
  )
}

export default IECCenteredText