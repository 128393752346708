import React from 'react'
import "./CitizenApplication.css"
import { useNavigate } from 'react-router-dom'

function CitizenApplication() {
    const navigate = useNavigate()
  return (
    <div className='application-main-container standarad-margin-space pb-5'>
        <div className='container'>
            <div className='text-center m-auto'>
                <h3 className='body-heading'><span className='color-yellow'>Minor Application </span>(under 18 years of age)- Minors under 18 years of age may apply for citizenship with parents.</h3>
            </div>
        </div>
            <div className='application-container my-4 d-flex align-items-center justify-content-center'>
                <div className='container text-container text-center d-flex flex-column align-items-center justify-content-center gap-3 w-75'>
                    <h3 className='body-heading w-md-75 w-100 m-auto' style={{color:"white"}}>Applying for your Citizenship? We can help you out!</h3>
                    <button className='call-btn mt-3' onClick={()=>{navigate("/book-a-free-call")}}>BOOK A FREE CALL</button>
                </div>
            </div>
    </div>
  )
}

export default CitizenApplication