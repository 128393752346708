import React from 'react'
import "./WorkPermitEligibleText.css"

function WorkPermitEligibleText() {
  return (
    <div className='WorkPermitEligibleText-container standard-margin-space'>
        <div className='container'>
            <div className='text-content text-center'>
                <h3 className='body-heading mx-auto' style={{width:"90%"}}>
                    Who Is <span className='color-yellow'>Eligible To Work In Canada?</span>
                </h3>
                <div className=' mt-3 mx-auto' style={{width:"95%"}}>
                    <p className='body-paragraph my-2 mb-4'>In order to be employed in Canada, you must be either a Canadian citizen, a permanent resident, or have a valid work permit.</p>
                </div>
            </div>
            <div className='px-2 mt-5'>
                <div className='d-flex align-items-start gap-md-3 gap-2 icon-list mb-3'>
                    <img src='/images/icons/hero-bullets.png' className='list-bullet mt-1'  alt='img' style={{width:"18px",height:"15px"}}/>
                    <div>
                        <h4 className='title body-heading ' style={{fontSize:"24px",fontWeight:"normal"}}>Temporary Foreign Worker Program (TFWP) & International Mobility Program (IMP)</h4>
                        <p className='body-paragraph my-4'>The Temporary Foreign Worker Program (TFWP) and International Mobility Program (IMP) are two initiatives from the Canadian government that allow foreign individuals to work in Canada temporarily.</p>
                    </div>
                </div>
                <div className='d-flex align-items-start gap-md-3 gap-2 icon-list mb-3'>
                    <img src='/images/icons/hero-bullets.png' className='list-bullet mt-1' alt='img' style={{width:"18px",height:"15px"}}/>
                    <div>
                        <h4 className='title body-heading ' style={{fontSize:"24px",fontWeight:"normal"}}>Temporary Foreign Worker Program (TFWP)</h4>
                        <p className='body-paragraph my-4'>When Canadians are unavailable for the position, TFWP permits Canadian firms to fill temporary labor needs by hiring foreign workers. Businesses and foreign workers must follow this program’s unique rules and restrictions.</p>
                        <p className='body-paragraph my-4'>Employers must obtain a Labour Market Impact Assessment (LMIA) from Employment and Social Development Canada to be authorized to hire a foreign worker (ESDC). A foreign worker should fill the position if neither a Canadian citizen nor a permanent resident is qualified.</p>
                        <p className='body-paragraph my-4'>Also, foreign workers must apply for a work permit and fulfill requirements like education, work experience, language ability, and medical tests. The low-wage and high-wage streams are the two categories under the TFWP.</p>

                    </div>
                </div>
                <div className='d-flex align-items-start gap-md-3 gap-2 icon-list mb-3'>
                    <img src='/images/icons/hero-bullets.png' className='list-bullet mt-1' alt='img' style={{width:"18px",height:"15px"}}/>
                    <div>
                        <h4 className='title body-heading ' style={{fontSize:"24px",fontWeight:"normal"}}>International Mobility Program (IMP)</h4>
                        <p className='body-paragraph my-4'>Foreign nationals can enter Canada to work temporarily thanks to the IMP suite of applications, which eliminates the requirement for an LMIA. The IMP was established to aid Canada in achieving its cultural and economic goals. It consists of initiatives like:</p>
                        <p className='body-paragraph my-3 mt-4'>1. International Experience Canada (IEC) program: Some foreign nationals can travel and work in Canada for up to two years. </p>
                        <p className='body-paragraph my-3'>2. Intra-Company Transfer (ICT) program: It enables global corporations to relocate staff to Canada temporarily. </p>
                        <p className='body-paragraph my-3'>3. Global Talent Stream (GTS): By making it easier for employers to find top talent globally, it aims to draw highly trained workers to Canada.</p>

                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default WorkPermitEligibleText