import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import PartnerCompanies from '../components/PartnerCompanies/PartnerCompanies'
import TextContent from '../components/TextContent/TextContent'
import CardBlog from "../components/CardBlog/CardBlog"
import AboutText from '../components/AboutText/AboutText'
import ServicesInclude from '../components/ServicesInclude/ServicesInclude'
import CardBoxAboutSection from '../components/CardsForAboutSection/CardBoxAboutSection'
import CardCarousel from "../components/CardCarousel/CardBlog"
import { Helmet } from 'react-helmet'

const locationData = [
  "Milton", "Ottawa", "Hamilton" , "Orangeville",     
  "Guelph" ,"Waterloo" ,"Oakville","Windsor",
  "Mississauga" ,"Whitby","Burlington","Oshawa",
  "Toronto" ,"Kingston","London","Markham",
  "Brampton", "Newmarket","North York","Barrie",
  "Scarborough", "Niagara Falls","Kitchener","Etobicoke",
  "Vaughan" ,"Caledon","Cambridge","St.Catharines",
]

function about() {
  return (
    <>
    <Helmet>
      <title>About Us - VCIC Immigration - Immigration Consultant of Canada</title>
      <meta name="description" content='We are an Immigration Consultant located in Canada. We provide tailor made solutions based on our client needs. Book a free call today!'/>
      <link rel="canonical" href="https://vcicimmigration.com/about-us/"></link>
    </Helmet>
        <HeroBanner
            Heading="About "
            subHeading=""
            spanHeading="Us"
            Paragraph="We pride ourselves on being a leading immigration consultancy firm committed to providing top-notch services to individuals and families seeking to relocate."
            btnText=""
            bgImg="/images/creatives/about-hero-bg.jpg"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            isChevron={true}
        />
        
        {/* <TextContent
            shiftRow={true}
            img="/images/creatives/text-side-2.png"
            heading="Meet our"
            spanHeading=" Immigration Consultant"
            subHeading=""
            bioImg={true}
            postHeading="Rajiv Sharma (Director, RCIC)"
            postParagraph='"My goal is to make sure all my clients have all the resources to settle in Canada"'
            content={"Vajra Canadian Immigration Consultancy Inc. was created to fulfil a need for high quality professional services. It is led by Rajiv Sharma who is a Regulated Canadian Immigration Consultant #R709167. Our holistic, realistic, systematic and straighforward approach helps our clients to take informed decisions about their future in Canada. We are transparent and purposeful in our guidance and provide tailored solutions based on client needs and aspirations. \n Mr. Rajiv Sharma is authorized by the Government of Canada to represent clients in their immigration applications with IRCC. He has over 15 years of experience as an Education Counsellor. He is a member in good standing of the College of Immigration and Citizenship Consultants(CICC). He has everything covered when it comes to Canadian Immigration Consultation including Express Entry as Permanent Residency applications, Student Permit, Work permit, Provincial Nominee Programs, LMIA, Visitor Visa, H&C applications, Inadmissibility, Spousal and Family Sponsorship, Refugee claims, Business Immigration, Intra Company transfers, Working holidays, Citizenship, Caregiver applications and much more"}
            btn={false}
            statSection={false}
        /> */}
        <CardBoxAboutSection
          subHeading="About Us"
          heading="Meet our"
          spanHeading=" Team"
          content={"Our team is a diverse group of dedicated professionals committed to delivering exceptional results. With a wealth of experience and a passion for innovation, we strive to exceed our clients' expectations."}
        />
     
        <PartnerCompanies/>

        <AboutText/>

        <TextContent
            shiftRow={false}
            img="/images/creatives/text-3.png"
            preSpanHeading="Service"
            heading=" Areas"
            spanHeading=""
            subHeading="VCIC Immigration"
            content={"VCIC Immigration offers comprehensive guidance when it comes to Canadian Immigration Consultation and offers a wide range of immigration services. We are professionals and straightforward in our approach and we honour ourselves to give honest immigration advice. \n Our Immigration Consultants Provide A Variety Of Immigration Services In Each Of The Following Areas:"}
            locationList={true}
            location={locationData}
            btn={true}
            btnText={"BOOK A FREE 30 MINUTE CONSULTATION"}
            btnRef='/book-a-free-call'
            statSection={false}
            advantagesList={false}
        />

        <ServicesInclude/>


        <CardBlog/>
    </>
  )
}

export default about