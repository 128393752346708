import "./DiscoverServices.css";
import { useNavigate } from "react-router-dom";







const serviceData = [
  {
    img: "/images/icons/discover-services-citizenship-icon.svg",
    title: "CITIZENSHIP",
    anchor: "/citizenship-in-canada",
    content:
      "Embark on your Canadian journey with expert citizenship application support. We guide you through legal intricacies, ensuring a smooth process. Personalized strategies, coupled with expert advice, maximize your potential for success in realizing your Canadian dream.",
  },
  {
    img: "/images/icons/discover-services-bussiness-icon.svg",
    title: "BUSSINESS IMMIGRATION",
    anchor: "/business-immigration-in-canada",
    content:
      "Invest wisely in your future through customized business immigration solutions. Access investor, entrepreneur, and self-employed programs tailored to your needs. Leverage your expertise to contribute to Canada's thriving economy, supported by VCIC's expert guidance and personalized strategies for optimal success.",
  },
  {
    img: "/images/icons/discover-services-provincal-icon.svg",
    title: "PROVINCIAL NOMINEE",
    anchor: "/provincial-nominee-program",
    content:
      "Explore diverse paths to Canadian residency via provincial programs. Identify the perfect province aligned with your skills and aspirations. Trust VCIC's proven success record to secure your future and navigate the intricacies of provincial nominee programs effectively, ensuring a successful and smooth transition.",
  },

  {
    img: "/images/icons/discover-services-inadmissibility-icon.svg",
    title: "INADMISSIBILITY",
    anchor: "/inadmissibility",
    content:
      "Receive expert legal guidance from VCIC to navigate complex inadmissibility issues. Overcome challenges and explore potential solutions, securing your right to confidently enter and stay in Canada, ensuring a smooth immigration process.",
  },
  {
    img: "/images/icons/discover-services-care-icon.svg",
    title: "CARE GIVER",
    anchor: "/caregiver-visa-canada",
    content:
      "Make a meaningful impact in Canadian lives while building your own future. Fulfill the critical need for skilled caregivers with dedicated support. Gain valuable Canadian experience, paving the way for permanent residency and a fulfilling life in Canada with our comprehensive assistance.",
  },
  {
    img: "/images/icons/discover-services-fedral-trade-icon.svg",
    title: "FEDERAL SKILLED TRADES CLASS",
    anchor: "/federal-skilled-trades-class",
    content:
      "Fast-track your Canadian residency through in-demand skilled trades. VCIC assists in navigating the process and showcasing your qualifications effectively. Build a rewarding career and embrace the benefits of Canadian life with confidence, supported by VCIC's tailored guidance and strategies.",
  },

  {
    img: "/images/icons/discover-services-canadian-icon.svg",
    title: "CANADIAN EXPRESS CLASS",
    anchor: "/canadian-experience-class",
    content:
      "Efficiently achieve your Canadian goals by optimizing your profile for faster processing times. VCIC enhances success chances and guides you through navigating the competitive Express Entry system seamlessly, ensuring a smooth path to residency.",
  },
  {
    img: "/images/icons/discover-services-fedral-skilled-icon.svg",
    title: "FEDRAL SKILLED WORKER",
    anchor: "/federal-skilled-worker-program",
    content:
      "Qualify for Canadian residency based on your skills and experience. VCIC identifies the ideal program, maximizing your eligibility. Contribute to Canada's workforce and build a fulfilling life, supported by VCIC's expert guidance throughout the immigration journey.",
  },
  {
    img: "/images/icons/discover-services-intra-icon.svg",
    title: "INTRA COMPANY TRANSFER",
    anchor: "/intra-company-transfers",
    content:
      "Seamlessly continue your career with a multinational company in Canada. VCIC simplifies the intra-company transfer process, ensuring a smooth relocation. Experience professional growth and stability in Canada's vibrant work environment with comprehensive support from VCIC.",
  },
];

function WhyChooseUs() {
  const navigate = useNavigate()
  return (
    <div className="discover-container standard-padding-space mb-4">
      <div className="container ">
        <div className="row">
          <div className="col-sm-12 text-center my-5 text-content">
            <h3 className="body-heading">We Offer A Wide Variety Of <span className="color-yellow">Immigration Services</span></h3>
            <p className="body-paragraph mt-3">BTW, We can assess your profile for free! Start an initial online assessment by entering your personal information and CV for us to properly take a look at your case and get back to you with a professional and honest opinion</p>
          </div>
          <div className="col-sm-12">
            <div className="container mt-4">
              <div className="row gy-4">
                {
                  serviceData.map((ele)=>{
                    return(
                      <div className=" col-md-4 col-sm-6 col-12">
                        <div className="discover-card-box mx-auto text-center h-100">
                          <div className="card-icon">
                              <img src={ele.img} alt={`${ele.title} icon`}/>
                          </div>
                          <h5 className="card-title">{ele.title}</h5>
                          <p className="card-content">{ele.content}</p>
                          <a className="read-more-anchor color-yellow" onClick={()=>{navigate(`${ele.anchor}`)}}>Learn More <img src="/images/icons/carousel-btn-icon.svg" className="btn-icon mx-1" alt="carousel-btn"/></a>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseUs;
