import React from 'react'
import "./ExpressEntryTextContent.css"
import { useNavigate } from 'react-router-dom'

function ExpressEntryTextContent({
    shiftRow=false,
    img,
    subHeading="",
    heading="",
    spanHeading="",
    content="",
    content2="",
    preSpanHeading="",
    mobileTextCenter=true,
    pointList=false,
    point=null,
    point2=null,
    pointList2=false,
    point3=null,
    pointList3=false,
    btn=false,
    btnText="",
    btnRef="",
}) {

    const navigate = useNavigate()
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='content body-paragraph my-1 mb-2'>{paragraph}</p>);
    const paragraphs2 = content2.split('\n').map((paragraph, index) => <p key={index} className='content body-paragraph my-1 mb-2'>{paragraph}</p>);

  return (
    <div className='expres-entry-text-content-container'>
        <div className='container'>
            <div className={`row d-flex ${ shiftRow ? "flex-row-reverse" : ""} align-items-center justify-content-between gy-4`}>
                <div className='col-md-6 h-100 my-auto'>
                    <img src={ img} className='img-fluid' alt='text content' />
                </div>
                <div className='col-md-6' style={shiftRow?{paddingLeft:"10px"}:{paddingRight:"10px"}}>
                    <div className={`text-content d-flex flex-column align-items-start justify-content-center gap-lg-1 h-100 ${mobileTextCenter && "px-md-auto px-2"} `} >
                        { subHeading == "" ? <></> :<h5 className='sub-heading'>{ subHeading}</h5>}
                        <h3 className='heading body-heading'><span className='color-yellow'>{ preSpanHeading}</span>{ heading}<span className='color-yellow'>{ spanHeading}</span></h3>
                        <p className='content body-paragraph mb-0'>
                            <div>{paragraphs}</div>
                        </p>
                        
                        {
                            pointList &&
                            <div className='location-list-container my-4'>
                                <div className='row'>
                                    {
                                        point.map((ele)=>{
                                            return(
                                                <div className='d-flex my-2 align-items-start col-lg-6 gap-xl-2 gap-2 icon-list'>
                                                    <img src='/images/icons/hero-bullets.png' className='list-bullet mt-1' alt='hero-img'/>
                                                    <p className='list-para mb-0'>{ele}</p>
                                                </div>)
                                        })
                                    }
                                </div>
                            </div>    
                        }
                        <p className='content body-paragraph mb-0'>
                            <div>{paragraphs2}</div>
                        </p>
                        
                        {
                            pointList2 &&
                            <div className='location-list-container my-4'>
                                <div className='row'>
                                    {
                                        point2.map((ele)=>{
                                            return(
                                                <div className='d-flex my-2 align-items-start col-lg-6 gap-xl-2 gap-2 icon-list'>
                                                    <img src='/images/icons/hero-bullets.png' className='list-bullet mt-1' alt='hero-img'/>
                                                    <p className='list-para mb-0'>{ele}</p>
                                                </div>)
                                        })
                                    }
                                </div>
                            </div>    
                        }

                        { btn && <button className="mt-3 genral-btn" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
                    </div>
                </div>
            </div>
            <div className='mt-2'>
                <p className='body-paragraph'>You can also Ask us a Question</p>
                <p className='body-paragraph'>If you are applying through the Express Entry system it is best to apply and get accepted in to the Express Entry waiting pool until you meet the cutoff and receive an Invitation to apply (ITA) While you are in the Express Entry waiting pool, you can focus on improving your Express Entry score. Once you receive an Invitation to Apply (ITA), you have 60 calendar days to either submit a Permanent Residence application or to decline the ITA</p>
                <p className='body-paragraph'>You must fill up an express entry profile online as a prospective applicant. You will be better able to share pertinent details about your:</p>
                {
                    pointList3 &&
                    <div className='location-list-container my-4'>
                        <div className='row'>
                            {
                                point3.map((ele)=>{
                                    return(
                                        <div className='d-flex align-items-start my-2 col-12 gap-xl-2 gap-2 icon-list'>
                                            <img src='/images/icons/hero-bullets.png' className='list-bullet mt-1' alt='hero-img'/> 
                                            <p className='list-para mb-0'>{ele}</p>
                                        </div>)
                                })
                            }
                        </div>
                    </div>    
                }
                <p className='body-paragraph'>We recommend applying for jobs through job recruiters. Additionally, we help all our clients to find the perfect job offer for them to get a permanent resident is Canada. If you are struggling to find a job and need some tips on how to find a job for yourself, you can Ask us a Question.</p>
            </div>
        </div>
    </div>
  )
}

export default ExpressEntryTextContent