
function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

function CardBox(props) {
  const title = truncateText(props.title,50)
  const content = truncateText(props.content,100)
  return (
    <div className="card-box" onClick={()=>{props.redirectionFunction(props.anchor)}}>
        <img src={props.cardImg} alt={`blog thumbnail`} />
        <div className="card-text-content">
          <h5 className="mb-0">{title}</h5>
          <div className="body-paragraph" dangerouslySetInnerHTML={{ __html: content}}></div>
        </div>
    </div>
  )
}

export default CardBox