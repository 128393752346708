import React from 'react'
import "./AboutText.css"

function AboutText() {
  return (
    <div className='about-text-container standard-padding-space'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-4 m-auto py-4 px-2 col-md-6 text-center d-flex flex-column align-items-center gap-4'>
                    <img src='/images/icons/text-icon-1.svg' alt="icon" className='about-icon'/>
                    <h3 className='color-yellow m-0'>OUR VISION</h3>
                    <p className='body-paragraph m-0'>To become the leading Canadian immigration service provider renowned for integrity, expertise, and an unwavering commitment to client success. Envision a future where client's Canadian dreams become reality through our dedicated and reliable services.</p>
                </div>
                <div className='col-lg-4 m-auto py-4 px-2 col-md-6 text-center d-flex flex-column align-items-center gap-4'>
                    <img src='/images/icons/text-icon-2.svg' alt="icon" className='about-icon'/>
                    <h3 className='color-yellow m-0'>OUR MISSION</h3>
                    <p className='body-paragraph m-0'>Empower individuals and families to achieve Canadian aspirations with expert immigration guidance, tailored solutions, and unwavering support. Navigate the immigration landscape seamlessly, maximizing success on the journey to a new life in Canada.</p>
                </div>
                <div className='col-lg-4 m-auto py-4 px-2 col-md-6 text-center d-flex flex-column align-items-center gap-4'>
                    <img src='/images/icons/text-icon-3.svg' alt="icon" className='about-icon'/>
                    <h3 className='color-yellow m-0'>OUR VALUES</h3>
                    <p className='body-paragraph m-0'>Uphold the highest ethical standards, ensuring transparency and honesty in every step of the immigration process. Your trust forms the foundation of our commitment to excellence, guiding individuals and families toward a successful and trustworthy immigration experience.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutText