import React from 'react'
import "./ServicesInclude.css"

const servicesData = [
    {img:"/images/icons/services-include-1.svg",title:"Express Entry"},
    {img:"/images/icons/services-include-2.svg",title:"Study Permit"},
    {img:"/images/icons/services-include-3.svg",title:"Work permit"},
    {img:"/images/icons/services-include-4.svg",title:"Visitor Visa"},
    {img:"/images/icons/services-include-5.svg",title:"Refugee applications"},
    {img:"/images/icons/services-include-6.svg",title:"Inadmissibility"},
    {img:"/images/icons/services-include-7.svg",title:"H&C applications"},
    {img:"/images/icons/services-include-8.svg",title:"Family sponsorships"},
    {img:"/images/icons/services-include-9.svg",title:"Business Immigration"},
    {img:"/images/icons/services-include-10.svg",title:"PNP"},
    {img:"/images/icons/services-include-11.svg",title:"Intra-Company transfers"},
    {img:"/images/icons/services-include-12.svg",title:"Citizenship"},
    {img:"/images/icons/services-include-13.svg",title:"Immigration Appeals"},
    {img:"/images/icons/services-include-14.svg",title:"Visa Refusals"},
]

function ServicesInclude() {
  return (
    <div className='services-include-container standard-padding-space'>
        <div className='container'>
            <div className='text-container text-center'>
                <h3 className='body-heading'>Why Choose <span className='color-yellow'>VCIC?</span></h3>
                <p className='body-paragraph py-3'>Committed to delivering efficient and dependable Immigration services to Canada</p>
            </div>
            <div className='choose-card-list py-4'>
                <div className='d-flex flex-column flex-md-row flex-wrap gap-4 align-items-center justify-content-center'>
                    {
                        servicesData.map((ele)=>{
                            return(
                                <div className='width-fit'>
                                    <div className='card-box m-auto d-flex flex-column justify-content-evenly align-items-center'>
                                        <img src={ele.img} className='card-img' alt={`${ele.title} icon`} />
                                        <p className='body-paragraph'>{ele.title}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServicesInclude