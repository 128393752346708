// CardBox.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CardBox.css'; // Import your CSS file for styling

function CardBox(props) {
  const navigate = useNavigate();

  return (
    <>
    {
      props.anchor
      ?
      <a className='' style={{textDecoration:"none"}} href={props.anchor} target='blank'>
        <div className="card-box">
          <img src={props.cardImg} alt={props.title} />
          <div className="card-body">
            <h2>{props.title}
              {
                props.nextLineTitle &&
                <>
                  <br></br>
                  {props.nextLineTitle}
                </>
              }
            </h2>
            <div className="card-content">
              <p>{props.content}</p>
            </div>
            
          </div>
        </div>
      </a>
      :
      <div className="card-box">
        <img src={props.cardImg} alt={props.title} />
        <div className="card-body">
          <h2>{props.title}
            {
                props.nextLineTitle &&
                <>
                  <br></br>
                  {props.nextLineTitle}
                </>
              }
          </h2>
          <div className="card-content">
            <p>{props.content}</p>
          </div>
          {/* <a onClick={() => navigate(props.anchor)} className="read-more-anchor color-yellow">
            Learn More <img src="/images/icons/carousel-btn-icon.svg" className="btn-icon" alt="btn-icon" />
          </a> */}
        </div>
      </div>
    }
    </>
    
  );
}

export default CardBox;
