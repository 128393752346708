import {useNavigate} from "react-router-dom";

function CardBox(props) {
  const navigate = useNavigate()
  return (
    <div className="card-box">
      <div>
        <img src={props.cardImg} alt={props.title} />
        <div className="card-text-content">
          <h2>{props.title}</h2>
          <p>{props.content}</p>
        </div>
      </div>
        <a onClick={()=>{navigate(props.anchor)}} className="read-more-anchor color-yellow">Learn More <img src="/images/icons/carousel-btn-icon.svg" className="btn-icon" alt="btn-icon"/></a>
    </div>
  )
}

export default CardBox