import React,{useEffect,useState} from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import BlogDetails from '../components/BlogDetails/BlogDetails'
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';


function NewsInner() {
  
  const [blogsData, setBlogsData] = useState();
  const [recentBlogs, setRecentBlogs] = useState([]);
  
  const [loading, setLoading] = useState(true);
  
  const navigate = useNavigate()

  let blogAPICalledId = false;
  let allBlogsCalled = false;

  const getAllBlogs = async (slug) => {
    if (allBlogsCalled) return;
    allBlogsCalled = true;

    setLoading(true);
    axios
      .get(`/blogs`, {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_recent_blogs = [];
          res.data.data.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_creative: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
              main_heading: item.title,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 3));
          let blog = res.data.data.find((item) => item.slug_url == slug);
          setBlogsData(blog);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    const handleLocationChange = () => {
      const newPath = window.location.pathname;
      let pathId = newPath.substring(1)
      if (pathId.endsWith('/')) {
        pathId = pathId.slice(0, -1);
      }
      getAllBlogs(pathId);
    };

      handleLocationChange()
    window.addEventListener('popstate', handleLocationChange);
   
    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, [navigate]);

  return (
    <>



{loading && !blogsData  ? (<>
       
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner
              style={{ color: "#DB831C", width: "120px", height: "120px" }}
            />
          </div>
          </>) : (
          blogsData && (
            <>
              <Helmet>
              <title>{blogsData.metaTitle}</title>
              {blogsData.metaDescription && <meta name="description" content={blogsData.metaDescription} />}
              {blogsData.canonicalLink && <link rel="canonical" href={blogsData.canonicalLink}></link>}
              </Helmet>
              <HeroBanner
                subHeading="BY VCIC IMMIGRATION"
                Heading={blogsData.title}
                spanHeading=""
                Paragraph=""
                darkBg={true}
                btnText=""
                bgImg="/images/creatives/inner-news-bg.jpg"
                btn={false}
                statSection={false}
                socialLinks={false}
                serviceForm={false}
                sideImage={false}
              />
              {recentBlogs && <BlogDetails
                blogsData={blogsData.blogs_content}
                datePublished={blogsData.published_date}
                text={blogsData.brief_paragraph}
                recentBlogs={recentBlogs}
              />}
            </>
          )
        )}
         
    </>
  ) 
}

export default NewsInner