import React from 'react'
import "./IECTextContent.css"
import { useNavigate } from 'react-router-dom';

const listPointLeft=[
    "There must be no intent to enter the Canadian labor market",
    "That a business visitor will engage in cross-border activities only" ,
    "The primary source of the worker’s compensation is outside of Canada", 
    "The principal place of employment is located outside of Canada", 
    "The employer’s profits are accrued outside of Canada"
]

const listPointRight = [
    "After Sales Service",
    "​Board of Directors Meetings​",
    "Employees of Short-Term Temporary Residents",
    "​Employees of Foreign Companies Contracting Canadian Companies",
    "​If an employee of a foreign company is sent to Canada for this purpose, they may be considered a business visitor provided they fulfill the following criteria:"
]

function IECTextContent({
        content,
        shiftRow=false,
        img,
        bio,
        subHeading,
        heading,
        spanHeading,
        postSubHeading="",
        btn,
        btnText ="Read More", 
        btnRef="/",
        preSpanHeading="",
        listPointList=false,
        listPoint=[],
        mobileTextCenter=false,
}) {
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='content body-paragraph my-1 mb-2'>{paragraph}</p>);
    const navigate = useNavigate("/")

  return (
    <div className='iec-text-content-container'>
        <div className='container'>
            <div className={`row d-flex ${ shiftRow ? "flex-row-reverse" : ""} align-items-center justify-content-between gy-4`}>
                <div className='col-md-6 h-100 my-auto'>
                    <img src={ img} className='img-fluid' alt='img' style={ bio ? {WebkitTransform:"scaleX(-1)",transform:"scaleX(-1)"} : {}}/>
                </div>
                <div className='col-md-6 px-2' style={shiftRow?{paddingLeft:"10px"}:{paddingRight:"10px"}}>
                    <div className={`text-content d-flex flex-column align-items-start justify-content-center gap-lg-1 h-100 ${mobileTextCenter && "px-md-auto px-2"} `} >
                        { subHeading == "" ? <></> :<h5 className='sub-heading'>{ subHeading}</h5>}
                        <h3 className='heading body-heading'><span className='color-yellow'>{ preSpanHeading}</span>{ heading}<span className='color-yellow'>{ spanHeading}</span></h3>
                        {postSubHeading==""?<></>:<h4 className='body-heading mt-2' style={{fontSize:"24px",fontWeight:"normal"}}>{postSubHeading}</h4>}
                        
                        <p className='content body-paragraph mb-0'>
                            { bio && <p className='content body-paragraph mb-2'><img src='/images/icons/location-arrow-icon.svg' alt='arrow-icon'/> Milton, Ontario</p>}
                            <div>{paragraphs}</div>
                        </p>
                        
                        {
                            listPointList && 
                            <div className='list-point-container d-flex flex-column gap-3'>
                                {listPoint.map(ele =>{
                                    return(
                                        <div className='d-flex align-items-start my-0 icon-list'>
                                            <img src='/images/icons/hero-bullets.png' className='list-bullet mt-1' alt='hero-img'/>
                                            <p className='list-para my-0'>{ele}</p>
                                        </div>)
                                })}
                            </div>
                        }

                        { btn && <button className="mt-3 genral-btn" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
                    </div>
                </div>
            </div>
            <div className='row mt-3 gap-md-0 gap-3'>
                <div className='col-md-6'>
                        <div className='left-content px-2'>
                            <h4 className='title my-3'>All business visitors must meet the following general criteria:</h4>
                            {
                                <div className='list-point-container d-flex flex-column gap-3'>
                                    {listPointLeft.map(ele =>{
                                        return(
                                            <div className='d-flex align-items-start my-0 icon-list'>
                                                <img src='/images/icons/hero-bullets.png' className='list-bullet mt-1' alt='hero-img'/>
                                                <p className='list-para my-0'>{ele}</p>
                                            </div>)
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                <div className='col-md-6'>
                        <div className='right-content px-2'>
                            <h4 className='title my-3'>Business visitors may fall into the following sub-categories:</h4>
                            {
                                <div className='list-point-container d-flex flex-column gap-3'>
                                    {listPointRight.map(ele =>{
                                        return(
                                            <div className='d-flex align-items-start my-0 icon-list'>
                                                <img src='/images/icons/hero-bullets.png' className='list-bullet mt-1' alt='hero-img'/>
                                                <p className='list-para my-0'>{ele}</p>
                                            </div>)
                                    })}
                                </div>
                            }
                        </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default IECTextContent