import "./CardBlog.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import CardBox from "./CardBox";

const handlePrevButtonClick = () => {
  const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
  swiperInstance.slidePrev();
};

const handleNextButtonClick = () => {
  const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
  swiperInstance.slideNext();
};

function CardTestimonial({
  subHeading,
  heading,
  content,
  spanHeading
}) {

  const blogData = [
    {
      cardImg: "./images/creatives/Immigrate_to_Canada.png",
      title: "Immigrate to Canada",
      anchor:"/immigrate",
      content:
        "Are you looking to start a new life in Canada? Let us assist you in immigrating to Canada.",
    },
    {
      cardImg: "./images/creatives/Work_In_Canada.png",
      title: "Work In Canada",
      anchor:"/work-permit-in-canada",
      content:
        "Are you ready to take your career to the next level? Let us help you discover job opportunities and get a work permit in Canada",
    },
    {
      cardImg: "./images/creatives/Study_in_Canada.png",
      title: "Study in Canada",
      anchor:"/study-permit-for-canada",
      content:
        "Are you ready to take your career to the next level? Let us help you discover job opportunities and get a work permit in Canada",
    },
    {
      cardImg: "./images/creatives/Sponsor_to_Canada.png",
      title: "Sponsor to Canada",
      anchor:"/spousal-sponsorship-canada",
      content:
        "Do you miss the comfort of being around your loved ones? Sponsor your family to Canada with our help.",
    },
    {
      cardImg: "./images/creatives/Invest_In_Canda.png",
      title: "Invest In Canada",
      anchor:"/business-immigration-in-canada",
      content:
        "Are you interested in the financial growth and security of Canada? Explore various business and investment opportunities with our business immigration expertise in Canada.",
    },
    {
      cardImg: "./images/creatives/Not_Sure.png",
      title: "Not Sure",
      anchor:"/start-your-free-initial-assessment",
      content:
        "After you finish our assessment, we will determine your eligibility for all immigration programmes.",
    },
  ];

  return (
    <div className='blog-container standard-padding-space container my-4'>
      <div className="blog-upper-content mb-4">
        <div className="container">
          <div className="row d-flex justify-content-between align-items-end">
            <div className="col-md-6">
              <div className="blog-text-content">
                <p className="sub-heading">{subHeading}</p>
                <h3 className="body-heading mb-0">{heading}<span className="color-yellow">{spanHeading}</span></h3>   
                {content && <p className="body-paragraph">{content}</p>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="blog-btn my-md-auto mt-4">
                <button className="swiper-button-prev" onClick={handlePrevButtonClick}><img src="/images/icons/btn-icon-left.svg" className="btn-arrow-icon mb-1" alt="arro-icon"/></button>
                <button className="swiper-button-next" onClick={handleNextButtonClick}><img src="/images/icons/btn-icon-right.svg" className="btn-arrow-icon mb-1" alt="arro-icon"/></button>
              </div>
            </div>
          </div>
        </div>
      </div>

        <Swiper
            spaceBetween={50}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            loop={true}
            autoplay={
              {
                delay:1500,
                disableOnInteraction:false
              }
            }
            modules={[Autoplay]}
            pagination={{ clickable: true }}
            observer ={true}
            observeParents={true}
            parallax={true}
            breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                992: {
                  slidesPerView: 3,
                }
              }}
            className="card-list py-4 px-3"
            id="articles-cards-slider"
            >
            {blogData.map((blogData,ind)=>{
              return (<>
                <SwiperSlide key={ind}>
                  <CardBox cardImg={blogData.cardImg} title={blogData.title} content={blogData.content} anchor={blogData.anchor}/>
                </SwiperSlide>
              </>)
            })}
            
        </Swiper>
        
    </div>
  )
}

export default CardTestimonial