import React from 'react'
import "./BgImageContent.css"
import { useNavigate } from 'react-router-dom'

function BgImageContent({
    heading="",
    img="",
    content="",
    smallContent="",
    btn=false,
    btnText ="", 
    btnRef="/",
}) {
    const navigate = useNavigate()
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph mb-2' style={{color:"white",fontSize:"33px",fontWeight:"normal"}}>{paragraph}</p>);
  return (
    <div 
        className='bg-img-container-main-container standarad-margin-space' 
        style={img == ""? {} :{ backgroundImage: `url(${img})`,backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPosition:"center" }}
    >
        <div className='application-container my-4 d-flex align-items-center justify-content-center'>
            <div className='container text-container text-center d-flex flex-column align-items-center justify-content-center gap-3 w-md-75 w-100'>
                <h3 className='body-heading w-md-75 w-100 m-auto' style={{color:"white",fontSize:"43px"}}>{heading}</h3>
                {content==""?<></>:<p className='body-paragraph' style={{color:"white",fontSize:"33px",fontWeight:"normal"}}>{paragraphs}</p>}
                {smallContent=="" ?<></> :<p className='body-paragraph' style={{color:"white",fontSize:"20px",fontWeight:"normal"}}>{smallContent}</p>}
                { btn && <button className="call-btn mt-3" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
            </div>
        </div>
    </div>
  )
}

export default BgImageContent