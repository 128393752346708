import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TextContent from '../components/TextContent/TextContent'
import CardBlog from "../components/CardBlog/CardBlog"
import TestimonialForm from '../components/TestimonialForm/TestimonialForm'
import CenteredText from '../components/CenteredText/CenteredText'
import {Helmet} from "react-helmet"

const eligibilityList1 = [
    "Meet the eligibility requirements, and ",
    "Have a job offer to work in one of these occupations"
]
const eligibilityList2 = [
    "Is occupation-restricted (so you have to work in that specific occupation)",
    "Doesn’t need a Labour Market Impact Assessment (LMIA)" ,
    "Lets you get the work experience you need to be eligible for permanent residence",
]


function CareGiver() {
    return (
        <>
        <Helmet>
            <title>Guide to getting a Caregiver Visa in Canada - VCIC Immigration</title>
            <meta name="description" content='Find out what it takes to get a Caregiver Visa for Canada! Our guide has all the information on eligibility and application processes.'/>
            <link rel="canonical" href="https://vcicimmigration.com/caregiver-visa-canada/"></link>
        </Helmet>
        <HeroBanner
            Heading="Guide to getting a"
            spanHeading=" Canadian Caregiver Visa"
            subHeading=""
            postParagraph=''
            Paragraph="Explore caregiver opportunities and let us guide you through a fulfilling and meaningful career in Canada's healthcare sector."
            btnText=""
            bgImg="/images/creatives/caregiver-hero-bg.jpg"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            isChevron={true}
        />
    
        <div style={{marginTop:"80px"}}></div>

        <CenteredText
            heading="Home Child Care Provider Pilot And "
            spanHeading="Home Support Worker Pilot"
            content="Looking for a way to move to Canada and become an in-home caregiver? Getting Canadian Caregiver Visa is your ticket to becoming a permanent resident. Find out here what it takes to be eligible and navigate the application process with ease."
        />
    
        <TextContent
            shiftRow={false}
            img="/images/creatives/caregiver-text-1.png"
            heading="Check If You Meet The "
            spanHeading="Eligibility Criteria."
            subHeading=""
            postHeading=""
            postParagraph=''
            postListContent='Through these pilots, candidates can get an open work permit to come to Canada and work temporarily. This work permit:'
            content={"The first step in your visa application is to check if you meet all the eligibility criteria. You must be a minimum of 22 years of age, demonstrate relevant work experience and higher-level education in the field of childcare or healthcare, have an employment offer from a Canadian employer, pass language tests and health exams, and meet other general admissibility requirements. Carefully review all the requirements before starting your application. \n You may be able to apply for permanent residence through the Home Child Care Provider Pilot or Home Support Worker Pilot if you:"}
            btn={false}
            btnText=''
            btnRef=''
            statSection={false}
            listPointList={true}
            listPoint={eligibilityList1}
            mobileTextCenter={true}
            postListPointList={true}
            postListPoint={eligibilityList2}
        />
        <TextContent
            shiftRow={true}
            img="/images/creatives/caregiver-text-2.png"
            heading="Submit Your "
            spanHeading="Caregiver Visa Application To Immigration, Refugees And Citizenship Canada (IRCC)."
            content={"Once you have all your documents ready, it’s time to submit your Caregiver Visa application to IRCC. You must first create an online profile on their website and then upload all the necessary documents. You can also choose to send the documents by mail if you cannot complete the online application. Submitting your application does not guarantee that it will be accepted, but it is a necessary step in order for IRCC to review it and make a decision. Make sure to check up on the status of your application as processing times vary from one case to another."}
            btn={true}
            btnText='BOOK A FREE CALL'
            btnRef='/book-a-free-call'
            statSection={false}
            listPointList={false}
            mobileTextCenter={true}
        />
        <TextContent
            shiftRow={false}
            img="/images/creatives/caregiver-text-3.png"
            heading="Receive Proof Of Permanent Residence From IRCC; "
            spanHeading="Attend Your Visa Interview With An Immigration Officer At A Canadian Visa Office, If Required; Pay Any Applicable Fees or Taxes; And Collect Your Visa!"
            content={"When you submit your application, an immigration officer at IRCC will evaluate it and contact you for more information or to schedule an interview. If your application is approved, you will receive a letter of introduction with advice on how to proceed. This includes paying any appropriate fees, such as medical exams or processing charges, attending an interview with an immigration officer if necessary and receiving your visa from the visa office nearest you."}
            btn={true}
            btnText='BOOK A FREE CALL'
            btnRef='/book-a-free-call'
            statSection={false}
            listPointList={false}
            mobileTextCenter={true}
        />
        
        <TestimonialForm/>
        <CardBlog/>
        
    </>
      )
}

export default CareGiver