import React,{useState} from "react"
import "./Footer.css"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { Spinner } from "react-bootstrap"

function Footer() {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const formSubmitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
          .post("/send-newsletter", {
            email: email,
            formType: "Newsletter",
          })
          .then((response) => {
            if (response.data.msg === "success") {
              setError(false);
              setSuccess("Newsletter Subscribed Successfully!");
              setLoading(false);
              setEmail("");
            } else if (response.data.msg === "fail") {
              setSuccess(false);
              setError("Email failed to send, try again by reloading the page.");
              setLoading(false);
              setEmail("");
            }
          });
      };

  return (
    <div className="footer-container">
        <div className="container">
        {/* newsletter Upper section */}
        <div className="upper-newsletter-container ">
            <div className="text-container">
                <img  src="/images/creatives/footer-mail.png" alt="mail icon" className="newsletter-img"/>
                <div className="text-content">
                    <h3 className="mb-0">Subscribe Our Newsletter</h3>
                    {/* <p className="body-paragraph mb-0" style={{color:"#949494"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                </div>
            </div>
            <form className="newsletter-form" onSubmit={formSubmitHandler}>
                {loading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ color: "#D77F18" }}
                          />
                    ) : (
                      success ? (
                        <div>
                          {setLoading(false)}
                          {alert(success)}
                          {setSuccess(false)}
                        </div>
                      ) : (
                        error && (
                          <div>
                            {setLoading(false)}
                            {alert(error)}
                            {setError(false)}
                          </div>
                        )
                      )
                    )}
                <input
                 required
                 type="email"
                 value={email}
                 maxLength="36"
                 onChange={(e) => setEmail(e.target.value)}
                 placeholder="Enter your email" 
                 />
                <button type="submit"><span className="subscribe-btn-text">Subscribe</span> <img src="/images/icons/bell-icon.svg" alt="bell icon" /> </button>
            </form>
            

        </div>

       
        <div className="line-seprator"></div>

     

        <div className="middle-links-container">
            <div className="left">
                <img src="/images/creatives/logo-dark.png" className="py-2 " alt="company logo" onClick={()=>{navigate("/home")}}/>
                <p className="body-paragraph" style={{color:"black"}}>Rajiv Sharma is of the director of Vajra Canadian Immigration Consultancy Inc. He is a member in good standing of the CICC..</p>
                <ul className="lower-link-list">
                    <li><a target="blank" href="https://www.instagram.com/vcic.immigration">
                      <img src="/images/icons/insta-icon.svg" alt="instagram logo" />
                      </a>
                      </li>
                    <li><a target="blank" href="https://twitter.com/VcicImmigration">
                    <img src="/images/icons/twitter-icon.svg" alt="twitter logo" /></a></li>
                    <li><a target="blank" href="https://www.facebook.com/VCICimmigration/">
                    <img src="/images/icons/facebook-icon.svg" alt="facebook logo" /></a></li>
                    <li><a target="blank" href="https://www.linkedin.com/company/vcic-immigration/?viewAsMember=true">
                      <img src="/images/icons/linked-icon.svg" alt="linked in logo" /></a></li>
                    <li><a target="blank" href="https://www.tiktok.com/@vcicimmigration">
                      <img src="/images/icons/tiktok-icon.png" alt="tiktok in logo" /></a></li>
                </ul>
            </div>
            <div className="right">
                <ul className="right-upper font-roboto gap-md-4 gap-3 justify-content-md-between justify-content-center">
                    <li onClick={()=>{navigate("/home")}}>Home</li>
                    <li onClick={()=>{navigate("/start-your-free-initial-assessment")}}>Assesment</li>
                    {/* <li onClick={()=>{navigate("/visa-to-visit-canada")}}>Visit</li>
                    <li onClick={()=>{navigate("/study-permit-for-canada")}}>Study</li> */}
                    <li onClick={()=>{navigate("/blog")}}>Blogs</li>
                    <li onClick={()=>{navigate("/about-us")}}>About</li>
                    <li onClick={()=>{navigate("/book-a-free-call")}}>Contact</li>
                    <li onClick={()=>{navigate("/privacy-policy")}}>Privacy policy</li>
                </ul>
                
                <ul className="right-bottom d-flex flex-column  align-items-center  align-items-md-start align-items-xl-center justify-content-xl-start justify-content-center px-md-0 px-2 py-lg-0 py-4">
                  <div className="d-flex flex-column flex-xl-row gap-md-0 gap-2 align-items-start justify-content-start justify-content-xl-start gap-xl-5 gap-4 gap-md-2 w-100">
                    <li style={{flex:"1"}}>
                        <img src="/images/icons/phone-icon.svg" alt="phone icon" />
                        <a href="tel:416-891-0909" className="num-a">416-891-0909</a>
                    </li>
                    <li style={{flex:"1"}}>
                        <img src="/images/icons/email-icon.svg" alt="mail icon" />
                        <a href="mailto:info@vcicimmigration.com">info@vcicimmigration.com</a>
                    </li>
                    <li style={{flex:"1"}} className="d-none d-xl-block">
                        
                    </li>
                    </div>
                    <div className="d-flex flex-column flex-xl-row gap-4 gap-md-2 w-100" >
                      <li className="d-flex flex-column align-items-start " style={{flex:"1"}}>
                        <div className="d-flex align-items-start gap-1 mb-2">
                          <img src="/images/icons/location-icon.svg" alt="location navigation icon" className=""/>
                          <h2 className="sub-heading mb-0" style={{color:"black",whiteSpace:"nowrap"}}>Registered office</h2>
                        </div>
                          <a>863 Whitlock Ave Milton, ON L9E 1R8, Canada</a>
                      </li>
                      <li className="d-flex flex-column align-items-start" style={{flex:"1"}}>
                        <div className="d-flex align-items-start gap-1 mb-2">
                          <img src="/images/icons/location-icon.svg" alt="location navigation icon" className=""/>
                          <h2 className="sub-heading mb-0" style={{color:"black",whiteSpace:"nowrap"}}>Brampton Location</h2>
                        </div>
                          <a>8 Strathearn Avenue, Unit #17, Brampton, ON L6T 4L7, Canada</a>
                      </li>
                      <li className="d-flex flex-column align-items-start " style={{flex:"1"}}>
                        <div className="d-flex align-items-start  gap-1">
                          <img src="/images/icons/location-icon.svg" alt="location navigation icon" className=""/>
                          <h2 className="sub-heading" style={{color:"black",whiteSpace:"nowrap"}}>Nova Scotia Location</h2>
                          </div>
                          <a>6156 Quinpool Road Halifax, Nova Scotia B3L 1A3, Canada</a>
                      </li>
                    </div>
                </ul>
            </div>
        </div>

        </div>
        {/* Lower sections */}

        <div className="lower-container">
           <div className="container d-flex flex-column flex-md-row align-items-center justify-content-md-between justify-content-center text-center">
            <small style={{color:"black"}}>Made With ❤️ By <a href="https://www.creativesquad.ca" target="blank">Creative Squad</a></small>
            <small style={{color:"black"}}>Copyright © 2010-2024 VCIC IMMIGRATION . All rights reserved.</small>
           </div>
        </div>
    </div>
  )
}

export default Footer