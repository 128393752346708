import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TextContent from '../components/TextContent/TextContent'
import CardBlog from "../components/CardBlog/CardBlog"
import TestimonialForm from '../components/TestimonialForm/TestimonialForm'
import IECCenteredText from '../components/IECCenteredText/IECCenteredText'
import IECTextContent from '../components/IECTextContent/IECTextContent'
import { Helmet } from 'react-helmet'

const list1 = 
    [
        "Working Holiday",
        "Young Professionals", 
        "International Co-op"
    ]

const list2=
    [
        "Be a citizen with valid passport holder of one of the 35 countries that have a bilateral youth mobility agreement with Canada;",
        "Be between the ages of 18 to 35 at the time of application",
        "Have the equivalent of $2,500 CAN upon landing to help cover initial expenses;",
        "Must have health insurance for the duration of their stay",
        "Must have a round-trip ticket prior to arrival to Canada.",
        "Not be accompanied by dependents; and", 
        "Pay the appropriate fees."
    ]

const list3 =[
    "Attending business meetings, conferences, conventions, fairs, etc", 
    "Buying Canadian goods or services on behalf of a foreign entity", 
    "Taking orders for goods or services", 
    "Providing after-sales service, excluding hands-on work in the construction trades", 
    "Being trained by a Canadian parent company for work outside of Canada", 
    "Training employees of a Canadian subsidiary of a foreign company"
]

function IEC() {
  return (
    <>
     <Helmet>
        <title>Working Holiday Visa in Canada - VCIC Immigration</title>
        <meta name="description" content='Looking to work in Canada during your holidays. Here is everything you need to know about working holiday visa in Canada (IEC).'/>
        <link rel="canonical" href="https://vcicimmigration.com/work-permit-in-canada/working-holiday-visa-in-canada/"></link>
    </Helmet>
    <HeroBanner
        preSpanHeading='Working holidays'
        Heading=" (IEC) Visa in Canada"
        spanHeading=""
        subHeading=""
        postParagraph=''
        Paragraph="Our working holiday solutions offer global opportunities for an enriching experience. Access our network of employers and job opportunities, facilitating a seamless transition into the workforce."
        btnText=""
        bgImg="/images/creatives/iec-hero-bg.jpg"
        darkBg={true}
        headingCenter={true}
        btn={false}
        socialLinks={false}
        serviceForm={false}
        isChevron={true}
    />

    <div style={{marginTop:"80px"}}></div>

    <TextContent
        shiftRow={true}
        img="/images/creatives/iec-text-1.png"
        heading="Who Is Eligible For A"
        spanHeading=" Working Holiday Visa In Canada?"
        content={"Those who are eligible for a Working Holiday Visa in Canada include citizens of participating countries, who generally must be between the ages of 18 and 35 (inclusive, depending on country). The programme allows individuals to experience life in Canada while also contributing to the rapidly growing economy through part-time and full-time employment.\n ​Canada has a bilateral youth mobility agreement with approximately 35 countries, Permitting international youth to live, work and travel in Canada for 12 – 24 months on an open work permit. (depending on your nationality).​\n The IEC program is composed of three categories:​"}
        btn={false}
        statSection={false}
        listPointList={true}
        listPoint={list1}
        postListContent={""}
        mobileTextCenter={true}
    />
    
   <IECCenteredText/>

    <TextContent
        shiftRow={false}
        img="/images/creatives/iec-text-2.png"
        heading="Eligibility "
        spanHeading="requirements for the IEC program"
        postSubHeading='Candidates must:'
        content={""}
        btn={false}
        statSection={false}
        listPointList={true}
        listPoint={list2}
        mobileTextCenter={true}
        postListContent='This is how the pool works.'
    />
<div style={{marginTop:"80px"}}></div>
    <IECTextContent
        shiftRow={true}
        img="/images/creatives/iec-text-3.png"
        heading="Business "
        spanHeading="Visitors"
        content='An individual can Visit in Canada as a business visitor without a work permit. There are a number of reason including:'
        btn={false}
        listPointList={true}
        listPoint={list3}
        mobileTextCenter={true}
    />

    <TestimonialForm/>
    <CardBlog/>
    
</>
  )
}

export default IEC