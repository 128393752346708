import React from 'react'
import "./WorkPermitHeadingText.css"
import { useNavigate } from 'react-router-dom'

function WorkPermitHeadingText() {
  return (
    <div className='standard-padding-space'>
        <CustomCenteredText
            heading="What Is "
            spanHeading="LMIA?"
            afterSpanHeading=""
            content={"A Canadian work permit application may be processed in a few weeks or several months. For instance, a conventional work permit application typically takes 2 to 4 months, whereas a Global Talent Stream work visa application may take 2 weeks."}
            btn={false}
        />
        <CustomCenteredText
            heading=""
            spanHeading=""
            afterSpanHeading=""
            content={"A Labour Market Impact Assessment (LMIA) is for employers to certify to the Government of Canada that hiring foreign workers will not adversely affect Canada’s existing workforce. The Federal Department of Employment and Social Development (ESDC) aims to ensure that the hiring of foreign workers does not crowd out existing Canadian workers or put downward pressure on wages. \n Free trade agreements such as the former North American Free Trade Agreement, which is now known as the Canada-United States-Mexico Agreement, or CUSMA, have resulted in a variety of LMIA-exempt work permits. Foreign workers can apply for a work permit through these free trade agreements without their employer needing an LMIA. Working holidays, post-graduate work permits, and open spousal work permits are among the options available to foreign workers who do not yet have a job offer in addition to these employer-sponsored work permits. The International Mobility Program (IMP) covers workers who do not require an LMIA."}
            btn={false}
            bodyHeadingTitle='Temporary Foreign Worker Program (TFWP) & International Mobility Program (IMP)'
        />
        <CustomCenteredLineText
            heading="LMIA Applications Should Show The Following"
            content={"Efforts made to recruit available Canadian citizens/permanent residents \n Wages offered for the position are consistent with the prevailing wage rate paid to Canadians/permanent residents in the same occupation in the region \n Working conditions for the occupation meets the current provincial labour market standards \n Any potential benefits that hiring a foreign worker might bring to the Canadian labour market, such as the creation of new jobs or the transfer of skills and knowledge \n Transition plans will be required for high-wage positions whereby employers must demonstrate increased efforts to hire Canadians in the long-term."}
            btn={false}
            dividerLength={false}
        />
    </div>
  )
}

function CustomCenteredText({
    heading="",
    spanHeading="",
    afterSpanHeading="",
    content="",
    btn=false,
    btnText="",
    btnRef="",
    bodyHeadingTitle=""
}) {
    const navigate = useNavigate()
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-2 mb-4'>{paragraph}</p>);
  return (
    <div className={`wp-centered-text-container standard-margin-space`}>
        <div className='text-content container text-center mx-auto py-1' >
            <h3 className={` mx-auto ${bodyHeadingTitle==""?"body-heading":"body-heading-title"} `} style={{width:"90%"}}>
                {heading}
                {bodyHeadingTitle}
                <span className='color-yellow'>
                    {spanHeading}
                </span>
                {afterSpanHeading}
            </h3>
            <div className=' mt-3 mx-auto' style={{width:"95%"}}>
                {paragraphs}
            </div>
        { btn && <button className="mt-4 genral-btn mx-auto" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
        </div>
    </div>
  )
}

function CustomCenteredLineText({
    heading="",
    spanHeading="",
    afterSpanHeading="",
    content="",
    btn=false,
    btnText="",
    btnRef="",
    dividerLength=false
}) {
    const navigate = useNavigate()
    let paragraphs = content.split('\n')
    paragraphs = paragraphs.map((paragraph, index) =>{ return(<> <p key={index} className='body-paragraph my-4'>{paragraph}</p> {index < (paragraphs.length-1) ?<div className={`line-break ${dividerLength && "shortDivider"}`}></div> :<></>} </>)});
  return (
    <div className='wp-centered-text-line-container'>
        <div className='text-content container text-center mx-auto py-2' >
            <h3 className='body-heading-title mx-auto' style={{width:"90%"}}>
                {heading}
                <span className='color-yellow'>
                    {spanHeading}
                </span>
                {afterSpanHeading}
            </h3>
            <div className=' mt-3 mx-auto' style={{width:"95%"}}>
                {paragraphs}
            </div>
        { btn && <button className="mt-4 genral-btn mx-auto" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
        </div>
    </div>
  )
}

export default WorkPermitHeadingText