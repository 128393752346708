import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TextContent from '../components/TextContent/TextContent'
import TestimonialForm from "../components/TestimonialForm/TestimonialForm"
import CardBlog from "../components/CardBlog/CardBlog"
import { Helmet } from 'react-helmet'

const superVisaList = ["The sponsor must meet the Minimum Necessary Income requirement", "Purchase Canadian healthcare insurance of $100,000 coverage for Minimum 1 year.", "Complete a medical examination","Undertaking of financial support from the Sponsor."]
const trpList = ["If you’re otherwise inadmissible but have a reason that is justified and have a valid reason to travel to Canada, in those circumstances, you may be issued a temporary resident permit.","To qualify for temporary residence, your need to enter or reside in Canada must outweigh any health or safety risk to Canadian society, as determined by immigration or border authorities. Even if the reason you’re inadmissible seems minor, you must demonstrate that your visit is justified."]
const workVisa = ["Working Holiday", "Young Professionals", "International Co-op (Internship)"]

function visit() {
  return (
    <>
    <Helmet>
        <title>Visa to visit Canada - VCIC Immigration</title>
        <meta name="description" content='Everything You Need to Know About Obtaining a Visa to visit Canada. From Requirements to different types of visitor visas to Canada.'/>
        <link rel="canonical" href="https://vcicimmigration.com/visa-to-visit-canada/"></link>
    </Helmet>
    <HeroBanner
        Heading="Visa to "
        spanHeading="Visit Canada"
        subHeading=""
        postParagraph=''
        Paragraph="Our experienced team simplifies the process, making your journey stress-free and ensuring a smooth transition to your dream destination"
        btnText=""
        bgImg="/images/creatives/visit-bg.jpg"
        darkBg={true}
        headingCenter={true}
        btn={false}
        socialLinks={false}
        serviceForm={false}
        isChevron={true}
    />

    <div style={{marginTop:"80px"}}></div>
    
    <TextContent
        shiftRow={true}
        img="/images/creatives/visit-text-1.png"
        heading="Super Visa For "
        spanHeading="Parents And Grandparents"
        subHeading=""
        postHeading=""
        postParagraph=''
        content={"Canadian Citizens and Canadian Permanent Residents can bring Parents and Grandparents to Canada through the Super Visa program. This program allows family members to come to Canada as long-term visitors on a multiple entry visa that may last up to 10 years. \n The financial requirement for a Super Visa is less severe than PR sponsorship applications. \n To be eligible for a Super Visa:​"}
        btn={false}
        statSection={false}
        listPointList={true}
        listPoint={superVisaList}
        mobileTextCenter={true}
    />

    <TextContent
        shiftRow={false}
        img="/images/creatives/visit-text-2.png"
        heading="Temporary Resident Permit "
        spanHeading="(TRP)"
        subHeading=""
        postHeading=""
        postParagraph=''
        content=""
        btn={false}
        statSection={false}
        listPointList={true}
        listPoint={trpList}
        mobileTextCenter={true}
    />

    <TextContent
        shiftRow={true}
        img="/images/creatives/visit-text-3.png"
        heading="Visa To "
        spanHeading="Visit Canada For Business"
        subHeading=""
        postHeading=""
        postParagraph=''
        content={"A business visa is a type of travel authorization that allows an individual to visit a foreign nation for the purpose of conducting business. \n During the visit, they may engage in business activities that do not constitute work or employment."}
        btn={false}
        statSection={false}
        listPointList={false}
        mobileTextCenter={true}
    />

    <TextContent
        shiftRow={false}
        img="/images/creatives/visit-text-4.png"
        heading="Visa And Work "
        spanHeading="(18-35)"
        subHeading=""
        postHeading=""
        postParagraph=''
        content={"International Experience Canada (IEC) gives youth the opportunity to travel and work in Canada for up to 2 years. \n There are 3 different kinds of work and travel experiences available:"}
        btn={false}
        statSection={false}
        listPointList={true}
        listPoint={workVisa}
        mobileTextCenter={true}
        postListContent={"If you’re a citizen of a partner country, you may be able to apply to one or more of these 3 categories. \n If you aren’t a citizen of a partner country, you may still be able to apply to IEC through a recognized organization."}
    />

    <TextContent
        shiftRow={true}
        img="/images/creatives/visit-text-5.png"
        heading="TRV for "
        spanHeading="Spouse"
        subHeading=""
        postHeading=""
        postParagraph=''
        content={"For many years, IRCC’s internal policy has been to refuse TRV applications for foreigners who have a spouse who is a Canadian Citizen or PR. Oddly, international students or foreign workers in Canada have been allowed to obtain TRVs for their spouses, but Canadian Citizens or PRs could not obtain a TRV for their spouse until this recent policy change. This major shift in immigration policy is likely due to the COVID-19 travel restrictions and increased lobbying by individuals for more accommodative immigration laws. \n Our firm believes this policy change is designed to approve TRVs for spouses who have been issued a PR file number. A TRV application can be submitted along with proof of a Spousal Sponsorship PR application number, and additional arguments / evidence to convince IRCC that the foreign spouse will respect immigration law."}
        btn={false}
        statSection={false}
        listPointList={false}
        mobileTextCenter={true}
    />

    <TestimonialForm/>
    <CardBlog/>

    
</>
  )
}

export default visit