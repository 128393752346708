import React from 'react'
import "./HowWeHelp.css"

function HowWeHelp({
  heading,
  spanHeading,
  list=[]
}) {
  return (
    <div className='how-help-container standard-padding-space'>
      <div className='container'>
        <h3 className='heading body-heading my-2 text-center'>{ heading}<span className='color-yellow'>{ spanHeading}</span></h3>
        <div className='list-container d-flex flex-column align-items-center justify-content-center text-center gap-3 mt-5'>
            {
              list.map((ele,ind)=>{
                return(
                  <>
                    <h5 className='title'>{ele}</h5>
                    {ind !== list.length-1?<div className='line-seprator'></div>:<></>}
                  </>
               ) })
            }
        </div>
      </div>
    </div>
  )
}

export default HowWeHelp