
function TestimonialCard(props) {
 
  return (
    <div className="testimonial-card px-md-5">
        <div className="testimonial-card-info">
          {/* <div className="swiper-button-prev" onClick={props.handlePrevButtonClick}>
              <img src="/images/icons/arrow-left.svg" className="arrow-img"/>
          </div> */}
          <div className="profile-info px-2 w-100">
            
              <img src={props.clientImg} alt="person " />
              <img src="/images/icons/testimonial-rating.svg " className="my-3" alt="testimonial" style={{borderRadius:0}}/>
              <h5 className="name">{props.clientName}</h5>
              <p className="body-paragraph review-text">{props.clientReview}</p>
          </div>
          {/* <div className="swiper-button-next" onClick={props.handleNextButtonClick}>
              <img src="/images/icons/arrow-right.svg" className="arrow-img"/>
          </div> */}
        </div>
    </div>
  )
}

export default TestimonialCard