import React from 'react'
import "./TextContent.css"
import { useNavigate } from 'react-router-dom';

function TextContent( 
    {
        content,
        bgColor,
        shiftRow=false,
        img,
        bio,
        subHeading,
        heading,
        spanHeading,
        postSubHeading="",
        advantagesList,
        locationList=false,
        statSection,
        btn,
        btnText ="Read More", 
        btnRef="/",
        postHeading="",
        postParagraph="",
        preSpanHeading="",
        location=[],
        listPointList=false,
        listPoint=[],
        postListContent="",
        mobileTextCenter=false,
        postListPointList=false,
        postListPoint=[],
        postContentHeading="",
        postContentParagraph="",
        endContent="",
        bioImg=false,
        headingH2=false,
        contentWithHtml=false
    }) {

    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='content body-paragraph my-1 mb-2'>{paragraph}</p>);
    const postListParagraphs =  postListContent.split(/\r?\n/).map((paragraph, index) => <p key={index} className='content body-paragraph mb-2'>{paragraph}</p>);
    const endParagraphs =  endContent.split(/\r?\n/).map((paragraph, index) => <p key={index} className='content body-paragraph mb-2'>{paragraph}</p>);
    const postContentParagraphSplited =  postContentParagraph.split(/\r?\n/).map((paragraph, index) => <p key={index} className='content body-paragraph my-1 mb-3'>{paragraph}</p>);
    const navigate = useNavigate("/")

  return (
    <div className='text-container standard-padding-space ' style={{backgroundColor:  bgColor}}>
        <div className='container'>
            <div className={`row d-flex ${ shiftRow ? "flex-row-reverse" : ""} align-items-center justify-content-between gy-4`}>
                <div className='col-md-6 h-100 my-auto'>
                    <img src={ img} alt={`${heading} ${spanHeading} content`}  className='img-fluid' style={ bioImg ? {WebkitTransform:"scaleX(-1)",transform:"scaleX(-1)" , borderBottomLeftRadius:"15px",borderBottomRightRadius:"15px"} : {}}/>
                </div>
                <div className='col-md-6 px-4 px-md-4' style={shiftRow?{paddingLeft:"10px"}:{paddingRight:"10px"}}>
                    <div className={`text-content d-flex flex-column align-items-start justify-content-center gap-lg-1 h-100 ${mobileTextCenter && "px-md-auto px-2"} `} >
                        { subHeading == "" ? <></> :<h5 className='sub-heading'>{ subHeading}</h5>}
                        
                        {headingH2 ? 
                        <h2 className='heading body-heading'><span className='color-yellow'>{ preSpanHeading}</span>{ heading}<span className='color-yellow'>{ spanHeading}</span></h2>
                        :
                        <h3 className='heading body-heading'><span className='color-yellow'>{ preSpanHeading}</span>{ heading}<span className='color-yellow'>{ spanHeading}</span></h3>
                        }
                        {postSubHeading==""?<></>:<h4 className='body-heading mt-2' style={{fontSize:"24px",fontWeight:"normal"}}>{postSubHeading}</h4>}
                        {postHeading =="" && postParagraph==""
                            ?
                                <></>
                            :
                            <div className='bio-data'> 
                                <h5 className='mb-2'>{postHeading}</h5>
                                <p className='content body-paragraph mb-0'>{postParagraph}</p>    
                            </div>
                            }
                        { bio && 
                            <div className='bio-container'>
                                <div className='bio-data d-flex align-items-center justify-content-start gap-3 mb-2'>
                                    <h5 >RCIC: #R709167</h5>
                                    <div className='line-seprator'></div>
                                    <h5 >RAJIV SHARMA(RCIC)</h5>
                                </div>
                                <h2 style={{fontSize:"20px"}}>Regulated Canadian Immigration Consultant</h2>
                            </div>
                        }
                        
                        <p className='content body-paragraph mb-0'>
                            { bio && <p className='content body-paragraph mb-2'><img src='/images/icons/location-arrow-icon.svg' alt="icon" /> Milton, Ontario</p>}
                            {contentWithHtml
                                    ?
                                    <div className='content body-paragraph mb-0' dangerouslySetInnerHTML={{ __html: content}}></div>    
                                    :
                                    <div>{paragraphs}</div>
                                    
                                } 
                        </p>
                        {postContentHeading =="" && postContentParagraph==""
                            ?
                                <></>
                            :
                            <div className='bio-data'> 
                                <h5 className='mb-2 body-heading mt-4' style={{fontSize: "26px",fontWeight: "normal",color:"#373737"}}>{postContentHeading}</h5>
                                <p className='content body-paragraph mb-0'>{postContentParagraphSplited}</p>    
                            </div>
                        }
                        {
                            locationList &&
                            <div className='location-list-container'>
                                <div className='row'>
                                    {
                                        location.map((ele)=>{
                                            return(
                                                <div className='d-flex align-items-center col-xl-3 col-lg-4 col-md-6 gap-lg-1 gap-xl-2 gap-2 icon-list'>
                                                    <img src='/images/icons/location-list-icon.svg' alt="icon" className='list-bullet'/>
                                                    <p className='list-para mb-0'>{ele}</p>
                                                </div>)
                                        })
                                    }
                                </div>
                            </div>    
                        }
                        { advantagesList &&
                            <div className='advantages-list d-flex flex-column gap-4 mt-3'>
                                <div className='advantages-items d-flex align-items-center gap-2'>
                                    <img src="/images/icons/check-box.svg" alt="icon"/>
                                    <p className='mb-0' style={{lineHeight:"1.3"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum as beenthe industry's standard dummy text</p>
                                </div>
                                <div className='advantages-items d-flex align-items-center gap-2'>
                                    <img src="/images/icons/check-box.svg" alt="icon"/>
                                    <p className='mb-0' style={{lineHeight:"1.3"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum as beenthe industry's standard dummy text</p>
                                </div>
                                <div className='advantages-items d-flex align-items-center gap-2'>
                                    <img src="/images/icons/check-box.svg" alt="icon"/>
                                    <p className='mb-0' style={{lineHeight:"1.3"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum as beenthe industry's standard dummy text</p>
                                </div>
                            </div>
                        }

                        { statSection && <div className="stat-section d-flex flex-row align-items-center gap-4 ">
                            <div className="d-flex flex-column align-items-center">
                                <h2 className="state-title color-yellow" >12k+</h2>  
                                <p>SUCCESS JOURNEY</p>
                            </div>  
                            <div className="d-flex flex-column align-items-center">
                                <h2 className="state-title" >16+</h2>  
                                <p>AWARDS WINNING</p>
                            </div>  
                            <div className="d-flex flex-column align-items-center">
                                <h2 className="state-title color-yellow" >20+</h2>  
                                <p>YEARS OF EXPERIENCE</p>
                            </div>  
                            </div>
                        }

                        {
                            listPointList && 
                            <div className='list-point-container d-flex flex-column gap-3'>
                                {listPoint.map(ele =>{
                                    return(
                                        <div className='d-flex align-items-start my-0 icon-list'>
                                            <img src='/images/icons/hero-bullets.png' alt="icon" className='list-bullet mt-1'/>
                                            <p className='list-para my-0'>{ele}</p>
                                        </div>)
                                })}
                            </div>
                        }
                        <p className='content body-paragraph mb-0 mt-3'>
                            <div>{postListParagraphs}</div>
                        </p>
                        {
                            postListPointList && 
                            <div className='list-point-container d-flex flex-column gap-3 mt-3'>
                                {postListPoint.map(ele =>{
                                    return(
                                        <div className='d-flex align-items-start my-0 icon-list'>
                                            <img src='/images/icons/hero-bullets.png' alt="icon" className='list-bullet mt-1'/>
                                            <p className='list-para my-0'>{ele}</p>
                                        </div>)
                                })}
                            </div>
                        }
                        {
                            endContent === ""?
                            <></>
                            :
                            <p className='content body-paragraph mb-0 mt-3'>
                                <div>{endParagraphs}</div>
                            </p>
                        }
                        { btn && <button className="mt-3 genral-btn" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TextContent