import "./Testimonials.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import TestimonialCard from "./TestimonialCard";

function Testimonials() {
  
  const testimonialData = [
    {clientName:"KARAN PUREWAL",clientImg:"/images/creatives/testimonial-user.webp", clientReview:"Smooth , hassle free migration experience. Thanks from my family"},
    
    {clientName:"PRIYANSH GAIND",clientImg:"/images/creatives/testimonial-user.webp", clientReview:"Rajiv Sir helped me get my student visa after I got refused once. He also helped me after I moved to Canada!"},

    {clientName:"SIMMY NAGRA",clientImg:"/images/creatives/testimonial-female.webp", clientReview:"VCIC is one of the best consultant. My sister applied for his student visa in Canada through VCIC. Rajiv Sir help us to find an appropriate course for her. Highly recommend VCIC for any kind of assistance."},

    {clientName:"Pranothi Rama",clientImg:"/images/creatives/testimonial-female.webp", clientReview:"The team of experts provided invaluable guidance and support throughout my immigration journey. They were not only knowledgeable but also genuinely invested in my success. From the initial consultation to the successful visa approval, their professionalism and attention to detail were impressive. I highly recommend it."},

    {clientName:"P krishna",clientImg:"/images/creatives/testimonial-user.webp", clientReview:"I had an excellent experience with VCIC. They exhibited a high level of professionalism, expertise, and a keen focus on addressing my requirements. I highly recommend them, particularly Rajiv Sir, for their pivotal role in facilitating my overseas study. I'm grateful for the outstanding experience!.."},

    {clientName:"Neha Sandhu",clientImg:"/images/creatives/testimonial-female.webp", clientReview:"Our experience with the team at VCIC has been absolutely wonderful. We are grateful for their assistance in processing our application. My PR was rejected thrice and received it successfully when finally applied through them. Some other consultant screwed up my file, long story! Nevermind! Moreover they helped my brother with his work permit and finally with PR. Staff was attentive, empathetic, knowledgeable, professional, friendly and accessible. They provided guidance and kept us informed every step of the way. Thank you VCIC and special thanks Rajeev Sir. ONE OF THEIR HAPPIEST CLIENT. STRONGLY STRONGLY RECOMMEND VCIC. 👍😊"},

    {clientName:"Kanchan Thakur",clientImg:"/images/creatives/testimonial-user.webp", clientReview:"I had the privilege of working with vcic immigration company after my study  permit was initially rejected . With their expert guidance  and support, ll replied and successfully obtained approval. Highly recommended for their effective assistance in overcoming study permit challenge s."},

    {clientName:"waqar hasan",clientImg:"/images/creatives/testimonial-user.webp", clientReview:"I want to appreciate Team VCIC and Mr. Rajiv's effort, my Canadian visa was rejected eight months ago than VCIC helped me in getting the Visa  .Their team is so supportive and highly recommended for any Visa/immigration matters,  regards."},

    {clientName:"Anie khandelwal",clientImg:"/images/creatives/testimonial-female.webp", clientReview:"I’m so thankful to Rajiv sir at VCIC immigration. His expertise helped me obtain a permanent residence in Canada. My case had a lot of complexities and I was very anxious when I contacted him. He calmed me down and guided me throughout the process. He went an extra mile to help me and made sure I was okay. My experience with VCIC was amazing. I highly recommend VCIC. Thank you again Rajiv sir"},

    {clientName:"Basit Mahmood",clientImg:"/images/creatives/testimonial-user.webp", clientReview:"I am grateful to Vajra Immigration Services Canada for their invaluable assistance in obtaining my study permit. They have played a crucial role in facilitating the process, and I am incredibly thankful for their support."},

    {clientName:"Harjeet Singh harjeet",clientImg:"/images/creatives/testimonial-user.webp", clientReview:"I express my gratitude to the VCIC team, especially Neha and Rajiv, for their invaluable assistance in obtaining my visitor visa. Despite feeling disheartened after my initial visa application was rejected, the VCIC team's tireless efforts resulted in me receiving my visa in less than seven days after applying reconsideration. It was pleasantly surprising how quickly everything fell into place."},

    {clientName:"Ranjeet Kumar",clientImg:"/images/creatives/testimonial-user.webp", clientReview:"I refered my cousin for Canada visit visa. Sadly that got refused two days back. Not a good news for us. But we thank vajra immigration for proper guidnece and fair dealing. Reasonably fees charged. We will wait for some time and again apply through them only 100% trustworthy."},

    {clientName:"Anmol Singh",clientImg:"/images/creatives/testimonial-user.webp", clientReview:"I express my feelings and many thanks to Rajiv Sir for assisting my father in obtaining a visit visa for Canada. Came As a student just three months ago, I have been feeling quite lonely and missing my family terribly. However, receiving this wonderful news yesterday has renewed my energy and enthusiasm, and I am eagerly anticipating the chance to see my father soon. Rajiv Sir, thank you so much for your help."},

    {clientName:"Apoorva Grover",clientImg:"/images/creatives/testimonial-user.webp", clientReview:"My experience at VCIC was very good. Everyone was really helpful and supportive in every stage of visa application. Rajiv Sir was my consultant and recommended me the colleges available and I received my study visa within time for my intake. I highly recommend them! Special thank you for Rajiv Sir :)"},

    {clientName:"Apoorva Grover",clientImg:"/images/creatives/testimonial-user.webp", clientReview:"Vajta immigration worked tirelessly on my  case, which had been refused three times. Their preserverance paid off, as they managed to turn the  situation around and secure the approval. Highly recommendation for their unwavring dedication and exceptional result"},

    {clientName:"saba usman",clientImg:"/images/creatives/testimonial-female.webp", clientReview:"Iam Saba from Karachi Pakistan , iam highly thankful to Mr Rajiv Sharma sir on behalf of my Aunt for helping her gain the compassionate visa Canada . He was very helpful and cooperative and the process went out smooth. I highly recommend VCIC for all kinds of  visa processings . Always appreciated Thankyou"},

    {clientName:"Dhruv Ratna",clientImg:"/images/creatives/testimonial-female.webp", clientReview:"Much advised consultancy firm for study. My Visa got refused and applied a reconsideration by VCIC, which was accepted and got approval. Thanks to the team VCIC."},

    {clientName:"Ravinder Bir Singh",clientImg:"/images/creatives/testimonial-female.webp", clientReview:"I want to thank VCIC for helping me get my study permit and helping my family to get a visitor visa to visit me. My experience with Vcic was smooth, their approach was very honest, i recommend VCIC for any immigration related issues, best immigration consultant in canada"},

    {clientName:"Akhtar Ali",clientImg:"/images/creatives/testimonial-female.webp", clientReview:"I recently engaged with vcic immigration company  for my immigration  needs and was  impressed by their exceptional service they are a reliable and well orginazed company that places a strong  emphassiis on ethics. Thank u. Vccic."},

    {clientName:"Simmy Nagra",clientImg:"/images/creatives/testimonial-female.webp", clientReview:"VCIC is one of the best consultant. My sister applied for his student visa in Canada through VCIC. Rajiv Sir help us to find an appropriate course for her. Highly recommend VCIC for any kind of assistance"}

  ]
  // clientImg:"/images/creatives/testimonial-user.png"
  const handlePrevButtonClick = () => {
    const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
    swiperInstance.slidePrev();
  };
  
  const handleNextButtonClick = () => {
    const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
    swiperInstance.slideNext();
  };

  return (
    <div className="Testimonials-container standard-padding-space">
      <div className="container">
    <div className="row d-flex align-items-center">
      <div className="col-md-12 text-center">
        <p className="sub-heading">OUR CLIENT'S TESTIMONIALS</p>
        <h3 className="text-center body-heading genral-padding-space mb-4">Your Success <span className="color-yellow">Journey</span></h3>
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            pagination={{ clickable: true }}
            autoplay={{ delay: 2500,disableOnInteraction:false }}
            loop={true}
            modules={[Autoplay]}
            observer ={true}
            observeParents={true}
            parallax={true}
            className="testimonials-list"
            id="articles-cards-slider"
            >
              {
                testimonialData.map((clientData,ind)=>{
                  return(<>
                    <SwiperSlide key={ind}>
                      <TestimonialCard clientImg={clientData.clientImg} clientName={clientData.clientName} clientReview={clientData.clientReview} handleNextButtonClick={handleNextButtonClick} handlePrevButtonClick={handlePrevButtonClick}/>
                    </SwiperSlide>
                     </>)
                })
              }
        </Swiper>
        
      </div>
      </div>

      <div className="d-flex justify-content-center mt-2">
        <a 
          href="https://www.google.com/maps/place/VCIC+-+Vajra+Canadian+Immigration+Consultancy+Inc./@43.4999033,-79.8359551,15z/data=!3m1!5s0x882b6f51f00e0fb3:0x4fd27565536d9200!4m8!3m7!1s0x23fa858b640b748f:0x9a291866969a391!8m2!3d43.4999033!4d-79.8359551!9m1!1b1!16s%2Fg%2F11sw_mgx11?entry=ttu"
          target="blank"
        >
          <button 
            className="genral-btn mx-auto">
              Read More
          </button>
        </a>
      </div>
  </div>
</div>
  )
}

export default Testimonials