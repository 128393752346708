import React from 'react'
import "./ECardSection.css"
import { Navigate, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function ECardSection() {
    const navigate = useNavigate()
  return (
    <>
    <Helmet>
        <meta property="og:title" content="Rajiv Sharma, RCIC" />
        <meta property="og:description" content="Licensed Canadian Immigration Consultant" />
        
    </Helmet>
    <div className='ECardSection-container py-lg-4 py-0'>
        <div className='bg-yellow-container container'>
            <div className='overlay'>
    
        <div className='container-fluid main-container d-flex flex-column align-items-center justify-content-center  px-4 px-lg-5 py-lg-5 py-3'>
            <div className='w-100 pt-3 pb-4 text-center'>
                <img src='/images/creatives/logo-light.png' />
            </div>
            <div className='d-flex flex-column-reverse flex-lg-row align-items-center gap-4 '>
                <div className='left-text-section h-100 text-center text-lg-start mb-0 mb-lg-2' >
                    <h3 className='body-heading' style={{color:"white",lineHeight:"1.3"}}>Rajiv Sharma, RCIC</h3>
                    <h3 className='body-heading' style={{color:"white",lineHeight:"1.3"}}>Licensed Canadian Immigration Consultant</h3>
                    <div className='mt-lg-5 mt-2'>
                    
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-between gap-3 '>
                    <img src='./images/creatives/rajiv-without-bg.png' alt='rajivs picture' className='main-img img-fluid' />
                    <h3 className='body-heading d-block d-lg-none ' style={{color:"white",lineHeight:"1.3"}}>Canadian Immigration Services</h3>
                </div>
            </div>

            <div className='text-center pt-3'>
                <div className='d-flex flex-row flex-lg-row align-items-center gap-4 w-100 justify-content-evenly pt-lg-5 pt-3 p-3 media-icons-container'>
                    <a href='tel:416-891-0909' className='card-anchor'>
                        <div className='card-box d-flex flex-column' >
                            <img src='/images/icons/ecard-call-icon.png' className='ecard-icon' alt="call"/>
                            <h3 className='body-heading icon-label' style={{color:"white"}}>Call</h3>
                        </div>
                    </a>
                    <a href='mailto:info@vcicimmigration.com' className='card-anchor'>
                        <div className='card-box d-flex flex-column' >
                            <img src='/images/icons/ecard-mail-icon.png' className='ecard-icon' alt="call"/>
                            <h3 className='body-heading icon-label' style={{color:"white"}}>Mail</h3>
                        </div>
                    </a>
                    <a href='sms:416-891-0909' className='card-anchor'>
                        <div className='card-box d-flex flex-column' >
                            <img src='/images/icons/ecard-sms-icon.png' className='ecard-icon' alt="call"/>
                            <h3 className='body-heading icon-label' style={{color:"white"}}>SMS</h3>
                        </div>
                    </a>
                    
                    <a href='http://wa.me/14168910909' target='blank'  className='card-anchor'>
                    {/* <a href='https://wa.me/14374220422' target='blank' className='card-anchor'> */}
                    {/* <a href='whatsapp://send?phone=+14374220422' target='blank' className='card-anchor'> */}
                        <div className='card-box d-flex flex-column' >
                            <img src='/images/icons/ecard-wa-icon.png' className='ecard-icon' alt="call"/>
                            <h3 className='body-heading icon-label' style={{color:"white"}}>WhatsApp</h3>
                        </div>
                    </a>
                </div>

                <button className='genral-btn mt-4 px-4' style={{width:"auto"}} onClick={()=>{navigate("/book-a-free-call")}}>Schedule Your Appointment</button>

            </div>


        </div>
        <ul className="d-flex flex-wrap social-icons-container align-items-center justify-content-center justify-content-lg-center align-items-lg-start gap-4 p-0 py-4 py-lg-4 mt-5 mt-lg-2 main">
           <li><a target="blank" href="https://www.instagram.com/vcic.immigration">
           <img src="images/icons/insta-icon-w.png" alt="instagram logo" />
           </a>
           </li>
           <li><a target="blank" href="https://twitter.com/VcicImmigration">
           <img src="images/icons/twitter-icon-w.png" alt="twitter logo" /></a></li>
           <li><a target="blank" href="https://www.facebook.com/VCICimmigration/">
           <img src="images/icons/facebook-icon-w.png" alt="facebook logo" /></a></li>
           <li><a target="blank" href="https://www.linkedin.com/company/vcic-immigration/?viewAsMember=true">
           <img src="images/icons/linked-icon-w.png" alt="linked in logo" /></a></li>
           <li><a target="blank" href="https://www.google.com/maps/place/VCIC+-+Vajra+Canadian+Immigration+Consultancy+Inc./@43.4999072,-79.83853,17z/data=!3m1!5s0x882b6f51f00e0fb3:0x4fd27565536d9200!4m8!3m7!1s0x23fa858b640b748f:0x9a291866969a391!8m2!3d43.4999033!4d-79.8359551!9m1!1b1!16s%2Fg%2F11sw_mgx11?entry=ttu">
           <img src="images/icons/google-icon-w.png" alt="google logo" /></a></li>
           <li><a target="blank" href="https://www.tiktok.com/@vcicimmigration">
           <img src="images/icons/tiktok-icon-w.png" alt="google logo" /></a></li>
        </ul>
        </div>
        </div>
    </div>
    </>
  )
}

export default ECardSection