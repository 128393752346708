import React from 'react'
import "./WorkPermitCenteredText.css"
import { useNavigate } from 'react-router-dom'

function WorkPermitCenteredText({
    heading1="",
    spanHeading1="",
    afterSpanHeading1="",
    content1="",
    btn1=false,
    heading2="",
    spanHeading2="",
    afterSpanHeading2="",
    content2="",
    btn2=false,
    alignText="center"
}) {
  return (
    <div className='standard-padding-space'>
        <CustomCenteredText
            heading={heading1}
            spanHeading={spanHeading1}
            afterSpanHeading={afterSpanHeading1}
            content={content1}
            btn={btn1}
            alignText={alignText}
        />
        <CustomCenteredText
            heading={heading2}
            spanHeading={spanHeading2}
            afterSpanHeading={afterSpanHeading2}
            content={content2}
            btn={btn2}
            alignText={alignText}
        />
    </div>
  )
}

function CustomCenteredText({
    heading="",
    spanHeading="",
    afterSpanHeading="",
    content="",
    btn=false,
    btnText="",
    btnRef="",
    alignText="center",
}) {
    const navigate = useNavigate()
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-2 mb-4'>{paragraph}</p>);
  return (
    <div className={`cust-centered-text-container `}>
        <div className={`text-content container text-${alignText} mx-auto py-3 ${alignText=="start" && "px-3"}`} >
            <h3 className='body-heading mx-auto' style={alignText=="start"?{width:"100%"}:{width:"90%"}}>
                {heading}
                <span className='color-yellow'>
                    {spanHeading}
                </span>
                {afterSpanHeading}
            </h3>
            <div className=' mt-3 mx-auto' style={alignText=="start"?{width:"100%"}:{width:"95%"}}>
                {paragraphs}
            </div>
        { btn && <button className="mt-4 genral-btn mx-auto" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
        </div>
    </div>
  )
}

export default WorkPermitCenteredText