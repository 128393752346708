import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TextContent from '../components/TextContent/TextContent'
import CardBlog from "../components/CardBlog/CardBlog"
import { Helmet } from 'react-helmet'

const list3 = [
    "Your spouse or common-law partner or conjugal partner", "Dependent children under the age of 21", "Dependent parents or grandparents", "A child you adopted outside Canada while you held a Canadian citizenship or PR", "Your brother, sister, niece, nephew, uncle, aunt or other close relatives"
]

const listPoint4 =[
    "18 years of age or older.",
"A person who is either a Canadian citizen, a permanent resident of Canada, or someone who has been recognized as an Indian by the Canadian Indian Act."
,"Capable of meeting the fundamental requirements of any person you wish to sponsor for a specific period of time."
]

const listPoint5 =[
    "The necessities of life include food, clothing, shelter, and other things.",
    "Other healthcare requirements not covered by Canada’s public health services include dental, vision, and other care.",
    "To be eligible for spousal and family sponsorship, you must sign an undertaking, an agreement in which you pledge to take financial responsibility for the people you are supporting and to meet their basic needs."
]

const listPoint6 = [
    "Your spouse or common-law partner or conjugal partner", "Dependent children under the age of 21", "Dependent parents or grandparents", "A child you adopted outside Canada while you held a Canadian citizenship or PR", "Your brother, sister, niece, nephew, uncle, aunt or other close relatives"
]

const listPoint7 =[
    "You can sponsor your own parents and grandparents, if you’re invited to apply", "you’re at least 18 years old; you live in Canada; you’re a Canadian citizen, a PR of Canada", "you have enough money to support the people you want to sponsor", "to show that you have enough, you’ll have to provide your proof of income", "you may have a spouse or common-law partner co-sign to combine your incomes", "you meet all other requirements under IRPA and IRPR"
]

const listPoint8 = [
    "They are related to you by blood or adoption.", "Both their mother and father passed away.", "They are under 18 years of age.", "They are single (not married or in a common-law or conjugal relationship)."
]

const listPoint9 =[
    "Canadian citizen or", "person registered in Canada as an Indian under the Canadian Indian Act or", "permanent resident of Canada"
]

function FamilySponsor() {
  return (
    <>
    <Helmet>
      <title>Spousal Sponsorship Canada - VCIC Immigration</title>
      <meta name="description" content='Thinking of sponsoring your spouse to move to Canada? Learn about the process and requirements involved with spousal sponsorship to Canada.'/>
      
      <link rel="canonical" href="https://vcicimmigration.com/spousal-sponsorship-canada/"></link>
    </Helmet>
        <HeroBanner
            Heading="Spousal Sponsorship in  "
            subHeading=""
            spanHeading="Canada"
            Paragraph="Helping you reunite with your family"
            btnText=""
            bgImg="/images/creatives/fs-hero-banner.jpg"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            isChevron={true}
        />
        <div style={{marginTop:"80px"}}></div>
        <TextContent
            shiftRow={false}
            img="/images/creatives/fs-text-1.png"
            heading=""
            spanHeading=""
            subHeading=""
            postHeading=""
            postParagraph=''
            content={"The objective of facilitating family reunions in Canada has long been upheld by the country’s immigration policy makers, which is why Canadian citizens and permanent residents are permitted to use spousal sponsorship to sponsor their spouses (or common-law partners) for immigration. Aiming to bring together family members already living in Canada, Spousal Sponsorship Canada was created. Your spouse or common-law partner may be able to immigrate to Canada permanently or acquire Canadian citizenship through this family sponsorship process.\nThe Canadian government works to keep families together as much as possible, provided that all parties (the principal applicant and their spouses, common-law partners, children, or other family members) fill out the appropriate immigration applications and adhere to the spousal sponsorship Canada requirements. In keeping with this idea, family sponsorship can assist in your parents’, grandparents’, and other immediate family members’ immigration to a country or citizenship.\nFor spousal and family sponsorship, Immigration, Refugees and Citizenship Canada (IRCC), the government agency in charge of the Family Sponsorship program, must grant approval to both the sponsor and the sponsored person. Processing times differ depending on a variety of variables,but this strategy has helped countless families, and it might be able to help your family build the foundation for a new life in Canada."}
            btn={true}
            btnText='BOOK A 30 MIN CONSULATATION'
            btnRef='/book-a-free-call'
            statSection={false}
        />

        <div className='standard-padding-space container my-3'>
            <div className='text-center mx-3 '>
                <h2 className='body-heading'>Meeting The Requirements For Spousal Sponsorship In Canada</h2>
                <p className='body-paragraph mt-4'>A sponsored person can either be spouse or dependent child</p>
            </div>

        </div>

        <TextContent
            shiftRow={true}
            img="/images/creatives/fs-text-2.png"
            heading="Spouse,"
            spanHeading=" Common Law Partner And Conjugal Partner"
            subHeading=""
            postHeading=""
            postParagraph=''
            content={"A spouse is a partner to whom you have a valid marriage license.\n A common-law partner is someone with whom you have lived together continuously for at least a year (12 consecutive months) but are not legally wed. For family or work-related reasons, briefseparations are acceptable throughout the year.\n An individual with whom you have a mutually dependent relationship for at least a year and share the same level of commitment as a marriage or common-law union is considered your conjugal partner.\n Remarkably, same-sex unions are recognized in Canada. Because of this, same-sex partners may apply for the spousal sponsorship program under any of the three categories mentioned above, provided they satisfy the requirements for eligibility as a spouse or common-law partner.\n If you have any questions, you can contact us and we will help you out!"}
            btn={true}
            btnText='BOOK A 30 MIN CONSULATATION'
            btnRef='/book-a-free-call'
            statSection={false}
        />

        <TextContent
            shiftRow={false}
            img="/images/creatives/fs-text-3.png"
            preSpanHeading=""
            heading="Dependent "
            spanHeading="Child"
            subHeading=""
            content={"The Canada Dependent Visa allows you to bring your dependents to Canada and also allows them to work or study full time once they have the relevant permits. Under the Canada Dependent Visa, you can sponsor the following relations for a dependent visa:"}
            locationList={false}
            listPoint={list3}
            listPointList={true}
            btn={true}
            postListContent={"The relations you sponsor can live with you in Canada. Your spouse or conjugal partner can also apply for a Work Permit in order to work in Canada.\nYou can use IRCCs online tool to find out if your child is a dependent"}
            btnText={"BOOK A FREE 30 MINUTE CONSULTATION"}
            btnRef='/book-a-free-call'
            statSection={false}
            advantagesList={false}
        />

        <div className='standard-padding-space mx-2'>
            <div className='text-content text-start container'>
                <h2 className='body-heading'>The <span className='color-yellow'>Requirements</span></h2>
                <p className='body-paragraph mt-4'>The IRCC demands that all sponsors for family or spousal sponsorship be.</p>
                {listPoint4.map(ele =>{
                    return(
                        <div className='d-flex align-items-start my-0 mt-2 icon-list'>
                            <img src='/images/icons/hero-bullets.png' className='list-bullet mt-2'/>
                            <p className='list-para body-paragraph my-0 mx-3'>{ele}</p>
                        </div>)
                })}

                <p className='body-paragraph mt-3'>The following is a list of basic requirements for spousal sponsorship:</p>
                {listPoint5.map(ele =>{
                    return(
                        <div className='d-flex align-items-start my-0 mt-2 icon-list'>
                            <img src='/images/icons/hero-bullets.png' className='list-bullet mt-2'/>
                            <p className='list-para body-paragraph my-0 mx-3'>{ele}</p>
                        </div>)
                })}
                <p className='body-paragraph mt-3'>The length of the undertaking varies depending on the type of person you are sponsoring and begins the day they are granted permanent residency. This period is 3 years for a spouse, common-law spouse, or a conjugal partner, 10 years for dependent children under the age of 22 (or until they turn 25, whichever occurs first), and 3 years for dependent children over the age of 22.</p>
            </div>

        </div>

        <TextContent
            shiftRow={true}
            img="/images/creatives/fs-text-4.png"
            heading="Parent "
            spanHeading="and Grandparent"
            subHeading=""
            postHeading=""
            postParagraph=''
            content={"The Canada Dependent Visa allows you to bring your dependents to Canada and also allows them to work or study full time once they have the relevant permits. Under the Canada Dependent Visa, you can sponsor the following relations for a dependent visa:"}
            listPoint={listPoint6}
            listPointList={true}
            btn={true}
            btnText='BOOK A 30 MIN CONSULATATION'
            btnRef='/book-a-free-call'
            statSection={false}
        />

      

        <div className='standard-padding-space mx-2'>
            <div className='text-content text-start container'>
                <p className='body-paragraph '>The relations you sponsor can live with you in Canada. Your spouse or conjugal partner can also apply for a Work Permit in order to work in Canada.</p>
                {listPoint7.map(ele =>{
                    return(
                        <div className='d-flex align-items-start my-0 mt-2 icon-list'>
                            <img src='/images/icons/hero-bullets.png' className='list-bullet mt-2'/>
                            <p className='list-para body-paragraph my-0 mx-3'>{ele}</p>
                        </div>)
                })}
                
                <p className='my-3 mt-4 ' ><strong style={{color:"black"}}>MNI Table (Minimum Necessary Income= LICO +30%)</strong></p>
                <p className='body-paragraph mt-3'>To prove you meet the income requirements, you must include your Notice of Assessment from the Canada Revenue Agency (CRA) for the last 3 tax years before you apply . The easiest way to prove your income is to give us permission to get your Notices of Assessment directly from the CRA.</p>
                
                <p className='body-paragraph mt-3'><strong style={{color:"black"}}>Other relatives (under exceptional circumstances as a “Lonely Canadian”- the sponsor has no spouse, common-law partner, child, parent, or grandparent)</strong> This is to sponsor a relative that falls outside of Canada’s current Family Class definition. </p>
                <p className='body-paragraph mt-1'>(Orphaned brother, sister, nephew, niece, or grandchild, Other relative)</p>
            </div>

        </div>

        <TextContent
            shiftRow={false}
            img="/images/creatives/fs-text-5.png"
            preSpanHeading='Orphaned'
            heading=" relative "
            spanHeading=""
            subHeading=""
            postSubHeading='Sponsoring orphaned relative (brother, sister, nephew, niece, or grandchild)'
            postHeading=""
            postParagraph=''
            content={"Canadian citizens and/or permanent residents who meet general eligibility requirements can sponsor certain orphaned relatives or family members (e.g. brother, sister, nephew, niece, grandchild). It is possible only if the relatives meet following requirements:"}
            listPoint={listPoint8}
            listPointList={true}
            postListContent={"Family sponsorship would not be an option if one of the parents is still alive, unknown, abandoned a child, in jail or detained, or if someone else is taking care of them while one or both parents are alive."}
            btn={true}
            btnText='BOOK A 30 MIN CONSULATATION'
            btnRef='/book-a-free-call'
            statSection={false}
        />

        <div className='standard-padding-space mx-2 mb-4'>
            <div className='text-content text-start container'>
                <p className='body-paragraph mt-4'>You may be able to sponsor certain relatives to immigrate to Canada under the Family Class if you’re at least 18 years old and a:</p>
                {listPoint9.map(ele =>{
                    return(
                        <div className='d-flex align-items-start my-0 mt-2 icon-list'>
                            <img src='/images/icons/hero-bullets.png' className='list-bullet mt-2'/>
                            <p className='list-para body-paragraph my-0 mx-3'>{ele}</p>
                        </div>)
                })}
                
                <p className='my-3 mt-4 body-paragraph' >If you sponsor a relative to come to Canada as a permanent resident, you must:</p>
                <div className='d-flex align-items-start my-0 mt-2 icon-list'>
                    <img src='/images/icons/hero-bullets.png' className='list-bullet mt-2'/>
                    <p className='list-para body-paragraph my-0 mx-3'>Support your relative financially when they arrive</p>
                </div>
                <div className='d-flex align-items-start my-0 mt-2 icon-list'>
                    <img src='/images/icons/hero-bullets.png' className='list-bullet mt-2'/>
                    <p className='list-para body-paragraph my-0 mx-3'>be able to meet basic needs for yourself and your relative, such as:</p>
                </div>

                <div className='d-flex flex-column gap-2 align-items-start my-3 icon-list'>
                    <div className='d-flex flex-row gap-4'> 
                    <div  className='d-flex align-items-center'>
                    <img src='/images/icons/dot-bullet.png' className='list-bullet mt-0'/>
                    <p className='list-para body-paragraph my-0 mx-2'>food</p>
                    </div>
                    <div className='d-flex align-items-center'>
                    <img src='/images/icons/dot-bullet.png' className='list-bullet mt-0'/>
                    <p className='list-para body-paragraph my-0 mx-2'>shelter</p>
                    </div>
                    </div>
                    <div  className='d-flex align-items-center'>
                    <img src='/images/icons/dot-bullet.png' className='list-bullet mt-0'/>
                    <p className='list-para body-paragraph my-0 mx-2'>clothing</p>
                    </div>
                </div>

                <div className='d-flex align-items-start my-0 mt-2 icon-list'>
                    <img src='/images/icons/hero-bullets.png' className='list-bullet mt-2'/>
                    <p className='list-para body-paragraph my-0 mx-3'>make sure your relative doesn’t need social assistance</p>
                </div>
                        
            </div>

        </div>

    </>
  )
}

export default FamilySponsor