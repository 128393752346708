import "./CardBoxAboutSection.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import CardBox from "./CardBox";

const handlePrevButtonClick = () => {
  const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
  swiperInstance.slidePrev();
};

const handleNextButtonClick = () => {
  const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
  swiperInstance.slideNext();
};

function CardTestimonial({
  subHeading,
  heading,
  content,
  spanHeading
}) {

  const blogData = [
    {
      cardImg: "./images/creatives/rajiv.png",
      title: "Rajiv Sharma, RCIC ",
      nextLineTitle:"Founder & Director - VCIC",
      content:
        "My goal is to make sure all my clients have all the resources to settle in Canada, Vajra Canadian Immigration Consultancy Inc. was created to fulfil a need for high quality professional services. It is led by Rajiv Sharma who is a Regulated Canadian Immigration Consultant #R709167. Our holistic, realistic, systematic and straighforward approach helps our clients to take informed decisions about their future in Canada. We are transparent and purposeful in our guidance and provide tailored solutions based on client needs and aspirations. \n Mr. Rajiv Sharma is authorized by the Government of Canada to represent clients in their immigration applications with IRCC. He has over 15 years of experience as an Education Counsellor. He is a member in good standing of the College of Immigration and Citizenship Consultants(CICC). He has everything covered when it comes to Canadian Immigration Consultation including Express Entry as Permanent Residency applications, Student Permit, Work permit, Provincial Nominee Programs, LMIA, Visitor Visa, H&C applications, Inadmissibility, Spousal and Family Sponsorship, Refugee claims, Business Immigration, Intra Company transfers, Working holidays, Citizenship, Caregiver applications and much more",
        
    },
    {
      cardImg: "./images/creatives/colonel.png",
      title: "Colonel Rocky Mehta, RCIC",
      anchor:"http://excelworldimmigration.com",
      content:
        "Colonel Rocky Mehta, a military veteran, is the Founder & CEO of the company. He has about 30 years of experience in management at National and International level. Hehas served in South Asia, Middle East and United States as part of United Nations HR Staff. He holds a master's degree in Management and actively involved in settlement of new comers to Canada. He is also graduate in Immigration Consultants Course from Humber College, Brampton, Canada and member in good standing of ICCRC.",
    },
    
  ];

  return (
    <div className='blog-container standard-padding-space container my-4'>
      <div className="blog-upper-content mb-4">
        <div className="container">
          {/* <div className="row d-flex justify-content-between align-items-end">
            <div className="col-md-6">
              <div className="blog-text-content">
                <p className="sub-heading">{subHeading}</p>
                <h3 className="body-heading mb-0">{heading}<span className="color-yellow">{spanHeading}</span></h3>   
                {content && <p className="body-paragraph">{content}</p>}
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="blog-btn my-md-auto mt-4">
                <button className="swiper-button-prev" onClick={handlePrevButtonClick}><img src="/images/icons/btn-icon-left.svg" className="btn-arrow-icon mb-1" alt="arro-icon"/></button>
                <button className="swiper-button-next" onClick={handleNextButtonClick}><img src="/images/icons/btn-icon-right.svg" className="btn-arrow-icon mb-1" alt="arro-icon"/></button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 d-flex align-items-center">
          <div className="blog-text-content">
            <p className="sub-heading">{subHeading}</p>
            <h3 className="body-heading mb-3">{heading}<span className="color-yellow">{spanHeading}</span></h3>   
            {content && <p className="body-paragraph">{content}</p>}
          </div>
        </div>
        <div className="col-lg-8">
        <Swiper
            spaceBetween={50}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            loop={true}
            autoplay={
              {
                delay:1500,
                disableOnInteraction:false
              }
            }
            modules={[Autoplay]}
            pagination={{ clickable: true }}
            observer ={true}
            observeParents={true}
            parallax={true}
            breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                992: {
                  slidesPerView: 2,
                }
              }}
            className="card-list py-4 px-3"
            id="articles-cards-slider"
            >
            {blogData.map((blogData,ind)=>{
              return (
                <>
                  <SwiperSlide key={ind}>
                    <CardBox
                      cardImg={blogData.cardImg}
                      title={blogData.title}
                      nextLineTitle={blogData.nextLineTitle}
                      content={blogData.content}
                      anchor={blogData.anchor}
                    />
                  </SwiperSlide>
                </>
              );
            })}
            
        </Swiper>
        </div>

      </div>

        
        
    </div>
  )
}

export default CardTestimonial