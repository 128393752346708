import React,{useState} from 'react'

function DropDownBox(props) {
    const [dropOpen , setDropOpen] = useState(false)
  return (
    <div
      className={`dropdown-box container my-3`}
      onClick={() => {
        setDropOpen((prev) => {
          return !prev;
        });
      }}
      style={dropOpen ? {backgroundColor:"#E3E3E3"}:{backgroundColor:"#F6F6F6"}}
    >
      <div className="box-head d-flex align-items-center justify-content-between">
        <h2 className="title mb-0" style={dropOpen ? {color:"#DB831C"}:{}}>{props.title}</h2>
        <img src={dropOpen ? '/images/icons/dropdown-eye-open.svg':'/images/icons/dropdown-eye-close.svg'} className='eye-icon' alt='eye-icon'/>
      </div>
      <div className="box-content my-4" style={dropOpen?{animation:"bottomBoxOpen .6s ease-in-out",display:"block"}:{display:"none"}}>
          <p className='mb-0'>{props.content}</p>
      </div>
    </div>
  );
}

export default DropDownBox