import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import { Helmet } from 'react-helmet'

function NotFound() {
  return (
    <>
    <Helmet>
      <title>Not Found</title>
      <meta name="description" content="VCIC serves everybody in the world looking for immigration guidance to Canada but here are some areas that we help residents of."/>
    </Helmet>
        <HeroBanner
            subHeading=""
            Heading="404 Not Found"
            spanHeading=""
            Paragraph=""
            btnText=""
            bgImg="/images/creatives/notFound-bg.webp"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            isChevron={false}
        />
        
    </>
  )
}

export default NotFound