import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TextContent from '../components/TextContent/TextContent'
import CardBlog from "../components/CardBlog/CardBlog"
import TestimonialForm from '../components/TestimonialForm/TestimonialForm'
import ExpressEntryTextContent from '../components/ExpressEntryTextContent/ExpressEntryTextContent'
import BgImageContent from '../components/BgImageContent/BgImageContent'
import FaqSection from "../components/FaqSection/FaqSection"
import { Helmet } from 'react-helmet'

const faqData = [
    {
        title:"What Is Express Entry To Canada?",
        content:"Express Entry to Canada is a system for managing applications for permanent residency in Canada. It has grown to be the most common way for skilled workers to immigrate to Canada since its introduction in 2015."
    },
    {
        title:"Who Is Eligible For Express Entry To Canada?",
        content:"Express Entry is open to skilled workers who meet the eligibility requirements for one of Canada’s federal economic immigration programs, including the Federal Skilled Worker Program, Federal Skilled Trades Program, and Canadian Experience Class."
    },
    {
        title:"How Does The Express Entry System Work?",
        content:"The Express Entry system allows you to create a profile where you are ranked on several factors like age, education, language proficiency (english and french), work experience, job offers from Canada and many more. Once you reach the cut off score you are selected to apply for a permanent residence in Canada."
    },
    {
        title:"How Long Does It Take To Get An Invitation To Apply Through Express Entry To Canada?",
        content:"The Express Entry system is the one of the fastest system to get a permanent residence in Canada. Once you make the cut off, it takes 30- 60 days to receive your permanent residence."
    },
    {
        title:"Is It Necessary To Have A Job Offer In Order To Apply Through Express Entry To Canada?",
        content:"Having a job offer is not a requirement for applying through Express Entry, but it can improve your chances of being selected and increase your score in the ranking system."
    },
    {
        title:"Can I Include My Spouse Or Common-Law Partner In My Express Entry Application?",
        content:"Yes, you can include your spouse or common-law partner in your Express Entry application. Their skills, education, and language proficiency will also be taken into consideration when ranking your profile in the Express Entry pool."
    },
    {
        title:"How Can I Improve My Chances Of Being Selected Through Express Entry To Canada?",
        content:"Improving your english language score, getting a job offer from a Canadian employer, learning french, getting a masters degree or a PhD, studying in Canada will boost your chances to get a permanent residence through Express Entry."
    },
]

function expressEntry() {
  return (
    <>
    <Helmet>
        <title>Express Entry to Canada - VCIC Immigration</title>
        <meta name="description" content='Ready to apply for your Canadian Permanent Residency? Discover the requirements of Express Entry to Canada and find out if you’re eligible.'/>
        <link rel="canonical" href="https://vcicimmigration.com/express-entry-canada/"></link>
    </Helmet>
    <HeroBanner
        Heading="Express Entry "
        spanHeading="Canada"
        subHeading=""
        postParagraph=''
        Paragraph="Swift and sure immigration through the Express Entry system. VCIC's expertise guides you through the process, optimizing your chances for a successful and expedited immigration journey."
        btnText=""
        bgImg="/images/creatives/express-entry-hero-bg.jpg"
        darkBg={true}
        headingCenter={true}
        btn={false}
        socialLinks={false}
        serviceForm={false}
        isChevron={true}
    />

    <div style={{marginTop:"80px"}}></div>

    <ExpressEntryTextContent
        shiftRow={true}
        img="/images/creatives/express-entry-text-1.png"
        heading="What Is "
        spanHeading="Express Entry Canada?"
        content={"Express Entry favours candidates with a strong profile. Eligible candidates can apply immediately and they get invited to apply for a Permanent Residence. Hence called ‘Express’ Entry. In January 2015, the Immigration, Refugee and Citizenship Canada (IRCC) launched this electronic system (Express Entry) to manage applications for permanent residency under the following federal immigration programs:"}
        mobileTextCenter={true}
        pointList={true}
        point={["Federal Skilled Worker (FSW)","Canadian Experience Class (CEC)" ,"Federal Skilled Trades (FST)"]}
        content2={"You have to meet the minimum requirements for any of these 3 classes in order to apply. \n A Provincial Nominee Program(PNP) is specific to provinces who hire applicants from the express entry systems in order to meet their demands. So you have to apply from a specific province to get your nomination. They nominate you as a good candidate for the Express Entry system to get you a Permanent Residence."}
        pointList2={true}
        point2={["Age","Any Provincial Nominee Sponsors (to apply through PNP)","Education","Job offers from Canada" ,"Language (English or French)", "and more"  ,"Work Experience"]}
        pointList3={true}
        point3={["Experience in the workplace", "Education,", "linguistic proficiency," ,"and any other necessary information."]}
        btn={false}
    />

    <TextContent
        shiftRow={false}
        img="/images/creatives/express-entry-text-2.png"
        heading="What Is"
        spanHeading=" Express Entry Draw?"
        content={"A draw for Express Entry is a regular invitation round in which the most highly ranked individuals in the Express Entry pool are asked to apply for permanent residency in Canada. The Canadian government establishes a minimum CRS score during the draw, and candidates with a score over this minimum are offered an Invitation to Apply (ITA).\n Draws for Express Entry normally occur every two weeks, although the frequency might vary based on Canada’s immigration needs. The Canadian government determines the minimum CRS score for each draw depending on a variety of factors, including the number of candidates in the pool and the number of permanent residency slots available.\n You will have 90 days to file a comprehensive application for permanent residency after receiving an ITA. You will be required to provide the documents mentioned above."}
        btn={false}
        statSection={false}
        listPointList={false}
        mobileTextCenter={true}
    />
    <TextContent
        shiftRow={true}
        img="/images/creatives/express-entry-text-3.png"
        heading="How To"
        spanHeading=" Apply For Express Entry To Canada?"
        content={"If you are interested in immigrating to Canada through the Express Entry system, the first step is to create a profile. To do this, you will need to provide information about your work experience, education, language proficiency, and other factors. Once you have created your profile, it will be entered into the pool of candidates, and you will be ranked based on the information you provided. If your profile is selected, you will be invited to apply for permanent residency."}
        btn={false}
        statSection={false}
        listPointList={false}
        postContentHeading='Entry Requirements'
        postContentParagraph={'To be eligible to apply for the Express Entry to Canada you need to meet basic requirements for one of these three programs:\n Federal Skilled Worker (FSW) – Federal Skilled Trades (FSTC) – Canadian Experience Class (CEC)'}
        mobileTextCenter={true}
    />

    <BgImageContent
         heading="Need Help Applying For Express Entry To Canada?"
         img="/images/creatives/express-entry-center-bg.jpg"
         content={"Our Immigration Consultant Can Help You! \n We will assess your case for free!"}
         btn={true}
         btnText ="START A FREE INITIAL ASSESSMENT"
         btnRef="/start-your-free-initial-assessment"
    />

    <FaqSection
        heading="Frequently Asked Questions"
        subHeading="Still have questions?"
        showImg={true}
        faqData={faqData}
    />

    <TestimonialForm/>
    <CardBlog/>
    
</>
  )
}

export default expressEntry