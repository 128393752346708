import React,{useState,useEffect,useRef} from 'react'
import "./AssessmentForm.css"
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'


function AssessmentForm() {
    const [name,setName]= useState()
    const [email,setEmail]= useState()
    const [phone,setPhone]= useState()
    const [dob , setDob] = useState({day: '', month: '',year: ''})
    const [residence,setResidence]= useState()
    const [education,setEducation]= useState()
    const [langScore,setLangScore]= useState(0)
    const [reading,setReading]= useState()
    const [speaking,setSpeaking]= useState()
    const [listening,setListening]= useState()
    const [writing,setWriting]= useState()
    const [experience,setExperience]= useState()
    const [canadaExperience,setCanadaExperience]= useState()
    const [candaStudy,setCandaStudy]= useState("yes")
    const [studyOrg,setStudyOrg]= useState()
    const [ECA,setECA]= useState()
    const [candaJobOffer,setCandaJobOffer]= useState("yes")
    const [marital,setMarital]= useState("single")
    const [cv,setCv] = useState(null)
    const [uploading,setUploading] = useState(false)

    
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()


    const numericInputsRef = useRef([]);

    useEffect(() => {
        const handleWheel = (e) => {
          // Prevent the default behavior of the wheel event
          e.preventDefault();
        };
      
        // Ensure numericInputsRef is defined and not null
        if (numericInputsRef && numericInputsRef.current) {
          const numericInputs = numericInputsRef.current;
      
          // Attach the wheel event listener with { passive: false }
          numericInputs.forEach((numericInput) => {
            numericInput.addEventListener('wheel', handleWheel, { passive: false });
          });
      
          // Cleanup the event listeners when the component unmounts
          return () => {
            numericInputs.forEach((numericInput) => {
              // Check if the element is still in the DOM before removing the event listener
              if (numericInput && numericInput.removeEventListener) {
                numericInput.removeEventListener('wheel', handleWheel);
              }
            });
          };
        }
      
        // Return an empty cleanup function if numericInputsRef is not available
        return () => {};
      }, [numericInputsRef]);
      
      
  

    const cvFileHandler = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("cv", file);
        setUploading(true);
        try {
          const config = { Headers: { "Content-Type": "multipart/form-data" } };
          const { data } = await axios.post("/api/upload/assesment", formData, config);
          setCv(data)
          setUploading(false);
        } catch (error) {
          console.error(error);
          setCv(null)
          e.target.value=""
          alert("error occured while uploading your file please make sure it's type is (.pdf or .docx) and is less then 1MB")
          setUploading(false);
        }
      };

    const formSubmitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        if(cv === null)
        {
            setLoading(false)
            alert("Please upload your CV file")
            return
        }
        axios
          .post("/send-assesment", {
            email: email,
            formType: "Assesment",
            assementData:{
                name:name,
                email:email,
                phone:phone,
                dateOfBirth:dob,
                residence:residence,
                education:education,
                langScore:langScore,
                readingScore:reading,
                speakingScore:speaking,
                listeningScore:listening,
                writingScore:writing,
                experience:experience,
                canadaExperience:canadaExperience,
                candaStudy:candaStudy,
                studyOrg:studyOrg,
                ECA:ECA,
                candaJobOffer:candaJobOffer,
                maritalStatus:marital,
                cv:cv
            }
          })
          .then((response) => {
            if (response.data.msg === "success") {
              setError(false);
              setSuccess("Assesment Submited Successfully!");
              setLoading(false);
              setEmail("");
              window.open("/thank-you", '_blank');
            } else if (response.data.msg === "fail") {
              setSuccess(false);
              setError("Failed to send Assesment, try again by reloading the page.");
              setLoading(false);
              setEmail("");
            }
          });
      };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDob(prevDate => ({
          ...prevDate,
          [name]: value
        }));
      };

      

      const handlePhoneChange = (e) => {
        // Allow only numeric values and limit the length
        const numericValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        const maxLength = 20; // Adjust as needed
    
        // Update state only if the value is within the specified range
        if (numericValue.length <= maxLength) {
          setPhone(numericValue);
        }
      };
  return (
    <div className='assessment-form-container standard-padding-space'>
        <div className='container'>
            <h3 className='heading pb-3'>Please fill out the spouse section only if necessary</h3>

            <form className='form-container py-3' onSubmit={formSubmitHandler}>
                <div className='row'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-4 col-md-6'>
                        <p className='inp-label py-3'>Your Full Name</p>
                        <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            maxLength="36"
                            required
                            type="text"
                            placeholder='Full Name'
                        />
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-4 col-md-6'>
                        <p className='inp-label py-3'>Your Email Address</p>
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            maxLength="100"
                            required
                            type='email' 
                            placeholder='Email' 
                        />
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-4 col-md-6'>
                        <p className='inp-label py-3'>Your Phone number</p>
                        <input
                            value={phone}
                            onChange={handlePhoneChange}
                            min="15" max="20"
                            required
                            type='tel' 
                            placeholder='Phone Number'

                        />
                    </div>
                </div>    
                <div className='row'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-4 col-md-6'>
                        <p className='inp-label py-3'>Date of Birth</p>
                        <div className='row gy-3'>
                            <div className='col-md-4 '>
                                <input
                                    value={dob.day}
                                    name="day"
                                    onChange={handleInputChange}
                                    min="1" max="31"
                                    required
                                    type='number' 
                                    placeholder='Day'
                                    className='w-100 numeric-input'
                                    ref={(el) => numericInputsRef.current.push(el)}
                                />
                            </div>
                            <div className='col-md-4 '>
                                <input
                                   value={dob.month}
                                   name="month"
                                   onChange={handleInputChange}
                                    min="1" max="12"
                                    required
                                    type='number' 
                                    placeholder='Month'
                                    className='w-100 numeric-input'
                                    ref={(el) => numericInputsRef.current.push(el)}
                                />
                            </div>
                            <div className='col-md-4'>
                                <input
                                   value={dob.year}
                                   name="year"
                                   onChange={handleInputChange}
                                    min="1900" max={new Date().getFullYear()}
                                    required
                                    type='number' 
                                    placeholder='Year'
                                    className='w-100 numeric-input'
                                    ref={(el) => numericInputsRef.current.push(el)}
                                />
                            </div>

                        </div>
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-4 col-md-6'>
                        <p className='inp-label py-3'>Country of Residence</p>
                        <input
                            value={residence}
                            onChange={(e) => setResidence(e.target.value)}
                            maxLength="50"
                            required
                            type="text"
                            placeholder='Which Country do you live in?'
                        />
                    </div>
                </div>    
                <div className='row'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-12'>
                        <p className='inp-label py-3'>Level of Education</p>
                        <input
                            value={education}
                            onChange={(e) => setEducation(e.target.value)}
                            maxLength="36"
                            required
                            type="text"
                            placeholder='One year program at a university, college, or an institute'
                        />
                    </div>
                </div>    
                <div className='row'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-12'>
                        <p className='inp-label py-3'>English Language Score: Overall</p>
                        <input
                            value={langScore}
                            onChange={(e) => setLangScore(e.target.value)}
                            min="0" max="10"
                            required
                            type="number"
                            className='numeric-input'
                            ref={(el) => numericInputsRef.current.push(el)}
                        />
                    </div>
                </div>    
                <div className='row'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-3 col-md-6'>
                        <p className='inp-label py-3'>Reading</p>
                        <input
                            value={reading}
                            onChange={(e) => setReading(e.target.value)}
                            min="0" max="10"
                            required
                            type="number"
                            placeholder='Reading'
                            className='numeric-input'
                            ref={(el) => numericInputsRef.current.push(el)}
                        />
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-3 col-md-6'>
                        <p className='inp-label py-3'>Speaking</p>
                        <input
                            value={speaking}
                            onChange={(e) => setSpeaking(e.target.value)}
                            min="0" max="10"
                            required
                            type="number"
                            placeholder='Speaking'
                            className='numeric-input'
                            ref={(el) => numericInputsRef.current.push(el)}
                        />
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-3 col-md-6'>
                        <p className='inp-label py-3'>Writing</p>
                        <input
                            value={writing}
                            onChange={(e) => setWriting(e.target.value)}
                            min="0" max="10"
                            required
                            type="number"
                            placeholder='Writing'
                            className='numeric-input'
                            ref={(el) => numericInputsRef.current.push(el)}
                        />
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-3 col-md-6'>
                        <p className='inp-label py-3'>Listening</p>
                        <input
                            value={listening}
                            onChange={(e) => setListening(e.target.value)}
                            min="0" max="10"
                            required
                            type="number"
                            placeholder='Listening'
                            className='numeric-input'
                            ref={(el) => numericInputsRef.current.push(el)}
                        />
                    </div>
                </div>   
                <div className='row'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-6 col-md-6'>
                        <p className='inp-label py-3'>Work Experience</p>
                        <input
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                            min="0" max="99"
                            required
                            type="number"
                            placeholder='Years Of Experience'
                            className='numeric-input'
                            ref={(el) => numericInputsRef.current.push(el)}
                        />
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-6 col-md-6'>
                        <p className='inp-label py-3'>Work Exp Canadian Work Experience(if applicable)</p>
                        <input
                            value={canadaExperience}
                            onChange={(e) => setCanadaExperience(e.target.value)}
                            min="0" max="99"
                            type="number"
                            placeholder='years of Canadian work Experience'
                            className='numeric-input'
                            ref={(el) => numericInputsRef.current.push(el)}
                        />
                    </div>
                </div>   
                <div className='row'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-12'>
                        <p className='inp-label py-3'>Did you study in Canada?</p>
                        <select 
                            value={candaStudy} 
                            onChange={(e)=>{setCandaStudy(e.target.value)}}
                            className='inp-label py-2'
                        >
                            <option value="yes">yes</option>
                            <option value="no">no</option>
                        </select>
                    </div>
                </div>   
                <div className='row'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-12'>
                        <p className='inp-label py-3'>Name of the organization where you got your Education Credential Assessment(ECA) done? if applicable</p>
                        <input
                            value={studyOrg}
                            onChange={(e) => setStudyOrg(e.target.value)}
                            maxLength="60"
                            type="text"
                            placeholder='Name of the organization'
                        />
                    </div>
                </div>   
                <div className='row'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-12'>
                        <p className='inp-label py-3'>Date of the Education Credential Assessment (ECA) report (if applicable)</p>
                        <input
                            value={ECA}
                            onChange={(e) => setECA(e.target.value)}
                            maxLength="36"
                            type="date"
                            placeholder='Date of the report'
                        />
                    </div>
                </div>   

                <div className='row'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-4 col-md-6'>
                        <p className='inp-label py-3'>Do you have an employment offer from a Canadian employer?</p>
                        <select 
                            value={candaJobOffer} 
                            onChange={(e)=>{setCandaJobOffer(e.target.value)}}
                            className='inp-label py-2'
                        >
                            <option value="yes">yes</option>
                            <option value="no">no</option>
                        </select>
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-4 col-md-6'>
                        <p className='inp-label py-3'>Marital Status</p>
                        <select 
                            value={marital} 
                            onChange={(e)=>{setMarital(e.target.value)}}
                            className='inp-label py-2'
                        >
                            <option value="single">single</option>
                            <option value="married">married</option>
                        </select>
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-4 col-md-6'>
                        <p className='inp-label py-2'>Upload your CV</p>
                        {/* <div style={{border:"1px solid #707070",borderRadius:"9px"}}>
                            <label for="fileInput" class="custom-file-input-label">Choose File</label>
                        </div> */}
                        <input type="file" id="fileInput" class="custom-file-input p-2" onChange={cvFileHandler}/>
                    </div>
                </div>  

                <div className='row pt-5'>
                    <div className='col-lg-12'>
                        <button className='genral-btn w-100 d-flex justify-content-center align-items-center' type='submit'>
                            Submit Now
                            {loading ? (
                                <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ color: "white",marginLeft:"10px" }}
                                />
                            ) : (
                            success ? (
                                <div>
                                {setLoading(false)}
                                {setSuccess(false)}
                                </div>
                            ) : (
                                error && (
                                <div>
                                    {setLoading(false)}
                                    {alert(error)}
                                    {setError(false)}
                                </div>
                                )
                            )
                            )}
                        </button>
                    </div>
                </div>  
            </form>
        </div>
    </div>
  )
}

export default AssessmentForm