import React, { useState,useRef,useEffect } from "react";
import { Link,useNavigate,NavLink } from "react-router-dom";
import "./Header.css";

const Header = ({
  darkBg=false,
  disableOnMobile=false
}) => {
  const [menuState, setMenuState] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenWork, setIsDropdownOpenWork] = useState(false);
  const [isDropdownOpenExpress, setIsDropdownOpenExpress] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const [isDropdownOpenVisit, setIsDropdownOpenVisit] = useState(false);
  const [isDropdownOpenDeskVisit, setIsDropdownOpenDeskVisit] = useState(false);
  const [isDropdownOpenDeskExpress, setIsDropdownOpenDeskExpress] = useState(false);
  const [isDropdownOpenDeskServices, setIsDropdownOpenDeskServices] = useState(false);
  const navigate = useNavigate()
  const clickCountRef = useRef(0);
  const dropdownRefV = useRef(null);
  const dropdownRefE = useRef(null);
  const dropdownRef = useRef(null);
  const dropdownRefServices = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDesk(false);
        }
      }
    };
  
    // Check if document exists before adding the event listener
    if (document) {
      document.addEventListener('click', handleClickOutside);
    }
  
    return () => {
      // Check if document exists before removing the event listener
      if (document) {
        document.removeEventListener('click', handleClickOutside);
      }
    };
  }, [isDropdownOpenDesk]);
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRefV.current && !dropdownRefV.current.contains(event.target) && isDropdownOpenDeskVisit) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDeskVisit(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDeskVisit]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRefE.current && !dropdownRefE.current.contains(event.target) && isDropdownOpenDeskExpress) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDeskExpress(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDeskExpress]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRefServices.current && !dropdownRefServices.current.contains(event.target) && isDropdownOpenDeskServices) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDeskServices(false);
          setIsDropdownOpenDesk(false);
          setIsDropdownOpenDeskExpress(false);
          setIsDropdownOpenDeskVisit(false)
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDeskServices]);

const onClickWork = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpenWork(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpenWork(false)
        navigate("/work-permit-in-canada");
        clickCountRef.current = 0;
      }
  };
const onClickImmigrate = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpenVisit(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpenVisit(false)
        navigate("/immigrate");
        clickCountRef.current = 0;
      }
  };
const onClickExpress = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpenExpress(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpenExpress(false)
        navigate("/express-entry-canada");
        clickCountRef.current = 0;
      }
  };

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };

  return (
    <div
      className={`header ${disableOnMobile && "d-lg-block d-none"}`}
      style={darkBg ? { backgroundColor: "#6a6a6a" } : {}}
    >
      <div className="header-wrapper container">
        <div className="d-flex align-items-center gap-3 justify-content-lg-end justify-content-center pt-4 pb-1">
          <li className="header-social-link">
            <a target="blank" href="https://www.instagram.com/vcic.immigration">
              <img src="/images/icons/insta-icon.svg" alt="instagram logo" />
            </a>
          </li>
          <li className="header-social-link">
            <a target="blank" href="https://twitter.com/VcicImmigration">
              <img src="/images/icons/twitter-icon.svg" alt="twitter logo" />
            </a>
          </li>
          <li className="header-social-link">
            <a target="blank" href="https://www.facebook.com/VCICimmigration/">
              <img src="/images/icons/facebook-icon.svg" alt="facebook logo" />
            </a>
          </li>
          <li className="header-social-link">
            <a
              target="blank"
              href="https://www.linkedin.com/company/vcic-immigration/?viewAsMember=true"
            >
              <img src="/images/icons/linked-icon.svg" alt="linked in logo" />
            </a>
          </li>
          <li className="header-social-link">
            <a target="blank" href="https://www.tiktok.com/@vcicimmigration">
              <img src="/images/icons/tiktok-icon.png" alt="tiktok in logo" />
            </a>
          </li>
        </div>
        <nav className="navbar d-md-flex flex-md-row d-block">
          <nav className="navbar-container pt-1">
            <div className="nav-elements d-flex justify-content-between align-items-center">
              <input className="d-none" type="checkbox" id="menu-toggle" />
              <div className="d-flex gap-4 align-items-center">
                <img
                  src="/images/creatives/logo-light.png"
                  className="nav-logo"
                  alt="logo"
                  onClick={() => {
                    navigate("/home");
                  }}
                />
                <nav>
                  <ul
                    className="nav-list d-lg-flex gap-lg-4 align-items-center d-none main-nav"
                    id="js-menu"
                  >
                    <li>
                      <NavLink
                        to={"/"}
                        className="nav-links"
                        exact
                        activeClassName="active"
                      >
                        HOME
                      </NavLink>
                    </li>

                    <div className="navigation-dropdown" ref={dropdownRefV}>
                      <li
                        onMouseOver={() => {
                          setIsDropdownOpenDeskVisit(true);
                          setIsDropdownOpenDesk(false);
                          setIsDropdownOpenDeskServices(false);
                        }}
                      >
                        <div
                          className="dropdown-trigger d-flex align-items-center"
                          style={{ height: "65px" }}
                        >
                          <NavLink
                            to={"/immigrate"}
                            className="nav-links"
                            onClick={() => {
                              setIsDropdownOpenDeskVisit(false);
                            }}
                          >
                            IMMIGRATE
                          </NavLink>
                          <img
                            src="/images/icons/header-drop-down-arrow.svg"
                            alt="arrow"
                            className={
                              isDropdownOpenDeskVisit
                                ? "rotate-icon arrow-icon"
                                : "rotate-back arrow-icon"
                            }
                          />
                        </div>
                        <div
                          className={`dropdown-content ${
                            isDropdownOpenDeskVisit ? "open" : "none-delay"
                          }`}
                          onMouseOver={() => {
                            setIsDropdownOpenDeskVisit(true);
                          }}
                          onMouseOut={() => {
                            setIsDropdownOpenDeskVisit(false);
                          }}
                        >
                          <div
                            className="navigation-dropdown"
                            ref={dropdownRefE}
                          >
                            <li
                              onMouseOver={() => {
                                setIsDropdownOpenDesk(false);
                                setIsDropdownOpenDeskVisit(true);
                              }}
                            >
                              <div
                                className="dropdown-trigger d-flex align-items-center"
                                onMouseOver={() => {
                                  setIsDropdownOpenDeskExpress(true);
                                }}
                              >
                                <Link
                                  to={"/express-entry-canada"}
                                  className="nav-links"
                                  onClick={() => {
                                    setIsDropdownOpenDeskExpress(false);
                                  }}
                                >
                                  EXPRESS ENTRY
                                </Link>
                                <img
                                  src="/images/icons/dropDownBlack.png"
                                  alt="arrow"
                                  style={{
                                    transform: "rotate(-90deg)",
                                    marginLeft: "8px",
                                    marginBottom: "0px",
                                    height: "15px",
                                    width: "15px",
                                  }}
                                />
                              </div>
                              <div
                                className={`dropdown-content-left ${
                                  isDropdownOpenDeskExpress
                                    ? "open"
                                    : "none-delay"
                                }`}
                                onMouseOver={() => {
                                  setIsDropdownOpenDeskExpress(true);
                                }}
                                onMouseOut={() => {
                                  setIsDropdownOpenDeskExpress(false);
                                }}
                              >
                                <Link
                                  to={"/federal-skilled-trades-class"}
                                  className="nav-links"
                                  onClick={() => {
                                    setIsDropdownOpenDeskExpress(false);
                                  }}
                                >
                                  <p
                                    className="mb-0"
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    federal skilled trades class
                                  </p>
                                </Link>
                                <Link
                                  to={"/canadian-experience-class"}
                                  className="nav-links"
                                  onClick={() => {
                                    setIsDropdownOpenDeskExpress(false);
                                  }}
                                >
                                  <p
                                    className="mb-0"
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    canadian experience class
                                  </p>
                                </Link>
                                <Link
                                  to={"/federal-skilled-worker-program"}
                                  className="nav-links"
                                  onClick={() => {
                                    setIsDropdownOpenDeskExpress(false);
                                  }}
                                >
                                  <p
                                    className="mb-0"
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    fedral skilled worker
                                  </p>
                                </Link>
                              </div>
                            </li>
                          </div>
                          <Link
                            to={"/citizenship-in-canada"}
                            className="nav-links"
                            onClick={() => {
                              setIsDropdownOpenDeskVisit(false);
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              citizenship
                            </p>
                          </Link>
                          <Link
                            to={"/spousal-sponsorship-canada"}
                            className="nav-links"
                            onClick={() => {
                              setIsDropdownOpenDeskVisit(false);
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              family sponsorship
                            </p>
                          </Link>
                          <Link
                            to={"/business-immigration-in-canada"}
                            className="nav-links"
                            onClick={() => {
                              setIsDropdownOpenDeskVisit(false);
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              bussiness
                            </p>
                          </Link>
                          <Link
                            to={"/provincial-nominee-program"}
                            className="nav-links"
                            onClick={() => {
                              setIsDropdownOpenDeskVisit(false);
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              Provincial Nominee
                            </p>
                          </Link>
                          <Link
                            to={"/inadmissibility"}
                            className="nav-links"
                            onClick={() => {
                              setIsDropdownOpenDeskVisit(false);
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              inadmissibility
                            </p>
                          </Link>
                          <Link
                            to={"/caregiver-visa-canada"}
                            className="nav-links"
                            onClick={() => {
                              setIsDropdownOpenDeskVisit(false);
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              CARE GIVER
                            </p>
                          </Link>
                          <Link
                            to={"/private-refugee"}
                            className="nav-links"
                            onClick={() => {
                              setIsDropdownOpenDeskVisit(false);
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              private refugee
                            </p>
                          </Link>
                        </div>
                      </li>
                    </div>

                    <li>
                      <NavLink
                        to={"/visa-to-visit-canada"}
                        className="nav-links"
                      >
                        VISIT
                      </NavLink>
                    </li>

                    <div className="navigation-dropdown" ref={dropdownRef}>
                      <li
                        onMouseOver={() => {
                          setIsDropdownOpenDesk(true);
                          setIsDropdownOpenDeskExpress(false);
                          setIsDropdownOpenDeskVisit(false);
                          setIsDropdownOpenDeskServices(false);
                        }}
                      >
                        <div
                          className="dropdown-trigger d-flex align-items-center"
                          style={{ height: "65px" }}
                        >
                          <NavLink
                            to={"/work-permit-in-canada"}
                            className="nav-links"
                            onClick={() => {
                              setIsDropdownOpenDesk(false);
                            }}
                          >
                            WORK
                          </NavLink>
                          <img
                            src="/images/icons/header-drop-down-arrow.svg"
                            alt="arrow"
                            className={
                              isDropdownOpenDesk
                                ? "rotate-icon arrow-icon"
                                : "rotate-back arrow-icon"
                            }
                          />
                        </div>
                        <div
                          className={`dropdown-content ${
                            isDropdownOpenDesk ? "open" : "none-delay"
                          }`}
                          onMouseOver={() => {
                            setIsDropdownOpenDesk(true);
                          }}
                          onMouseOut={() => {
                            setIsDropdownOpenDesk(false);
                          }}
                        >
                          <Link
                            to={
                              "/work-permit-in-canada/working-holiday-visa-in-canada"
                            }
                            className="nav-links"
                            onClick={() => {
                              setIsDropdownOpenDeskVisit(false);
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              working holiday visa (IEC)
                            </p>
                          </Link>
                          <Link
                            to={"/intra-company-transfers"}
                            className="nav-links"
                            onClick={() => {
                              setIsDropdownOpenDeskVisit(false);
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              intra company transfers
                            </p>
                          </Link>
                        </div>
                      </li>
                    </div>
                    <li>
                      <NavLink
                        to={"/study-permit-for-canada"}
                        className="nav-links"
                      >
                        STUDY
                      </NavLink>
                    </li>

                    <div
                      className="navigation-dropdown"
                      ref={dropdownRefServices}
                    >
                      <li
                        onMouseOver={() => {
                          setIsDropdownOpenDeskServices(true);
                          setIsDropdownOpenDesk(false);
                          setIsDropdownOpenDeskVisit(false);
                        }}
                      >
                        <div
                          className="dropdown-trigger d-flex align-items-center"
                          style={{ height: "65px" }}
                        >
                          <NavLink
                            to={"/about-us"}
                            className="nav-links"
                            activeClassName="active"
                            onClick={() => {
                              setIsDropdownOpenDeskServices(false);
                            }}
                          >
                            ABOUT
                          </NavLink>
                          <img
                            src="/images/icons/header-drop-down-arrow.svg"
                            alt="arrow"
                            className={
                              isDropdownOpenDeskServices
                                ? "rotate-icon arrow-icon"
                                : "rotate-back arrow-icon"
                            }
                          />
                        </div>
                        <div
                          className={`dropdown-content ${
                            isDropdownOpenDeskServices ? "open" : "none-delay"
                          }`}
                          onMouseOver={() => {
                            setIsDropdownOpenDeskServices(true);
                          }}
                          onMouseOut={() => {
                            setIsDropdownOpenDeskServices(false);
                          }}
                        >
                          <li>
                            <Link
                              to={"/blog"}
                              className="nav-links"
                              activeClassName="active"
                            >
                              BLOGS
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/book-a-free-call"}
                              className="nav-links"
                              activeClassName="active"
                            >
                              BOOK A FREE CALL
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/start-your-free-initial-assessment"}
                              className="nav-links"
                              activeClassName="active"
                            >
                              ASSESSMENT
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/service-areas"}
                              className="nav-links"
                              activeClassName="active"
                            >
                              SERVICES
                            </Link>
                          </li>
                        </div>
                      </li>
                    </div>
                  </ul>
                </nav>
              </div>
              <label
                value="menu-toggle"
                onClick={menuClick}
                className="responsive-menu-btn d-lg-none d-inline"
              >
                <img
                  src="/images/icons/hamburger-icon.png"
                  className="img-fluid menu-btn"
                  alt="menu"
                />
              </label>

              <div className="nav-buttton mx-2 d-lg-flex d-none gap-4 extras-btns">
                <a href="tel:+14168910909">
                  <button className="genral-btn">
                    <img
                      src="/images/icons/phone-icon-btn.svg"
                      alt="icon"
                      style={{ marginRight: "8px", marginBottom: "2px" }}
                    />
                    +14168910909
                  </button>
                </a>
              </div>
            </div>
          </nav>

          {/* Dropdown in responsive mode */}
          <nav className="responsive-popup-menu w-100">
            <div
              className="py-3 d-lg-none"
              style={{ display: menuState ? "block" : "none" }}
            >
              <ul
                className="resp-nav-list d-lg-none w-100 gap-4 hs-dropdown [--trigger:hover] pl-4"
                style={{ display: menuState ? "block" : "none" }}
              >
                <li>
                  <Link to={"/home"} className="nav-links">
                    HOME
                  </Link>
                </li>
                <li>
                  <Link to={"/service-areas"} className="nav-links">
                    SERVICES
                  </Link>
                </li>
                <li>
                  <div className="navigation-dropdown">
                    <div
                      className="dropdown-trigger d-flex align-items-center"
                      style={{ color: "black" }}
                      onClick={onClickImmigrate}
                    >
                      <a className="nav-links">IMMIGRATE</a>
                      <img
                        src="/images/icons/dropDownBlack.png"
                        alt="arrow"
                        className={
                          isDropdownOpenVisit
                            ? "rotate-icon arrow-icon"
                            : "rotate-back arrow-icon"
                        }
                      />
                    </div>
                    <div
                      className={`dropdown-content ${
                        isDropdownOpenVisit ? "open" : "d-none"
                      }`}
                      style={{ top: "85%", left: "0%" }}
                    >
                      <Link
                        to={"/citizenship-in-canada"}
                        className="nav-links"
                        onClick={() => {
                          setIsDropdownOpenVisit(false);
                          setMenuState(false);
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ textTransform: "uppercase" }}
                        >
                          citizenship
                        </p>
                      </Link>
                      <Link
                        to={"/spousal-sponsorship-canada"}
                        className="nav-links"
                        onClick={() => {
                          setIsDropdownOpenVisit(false);
                          setMenuState(false);
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ textTransform: "uppercase" }}
                        >
                          family sponsorship
                        </p>
                      </Link>
                      <Link
                        to={"/business-immigration-in-canada"}
                        className="nav-links"
                        onClick={() => {
                          setIsDropdownOpenVisit(false);
                          setMenuState(false);
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ textTransform: "uppercase" }}
                        >
                          bussiness
                        </p>
                      </Link>
                      <Link
                        to={"/provincial-nominee-program"}
                        className="nav-links"
                        onClick={() => {
                          setIsDropdownOpenVisit(false);
                          setMenuState(false);
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ textTransform: "uppercase" }}
                        >
                          Provincial Nominee
                        </p>
                      </Link>
                      <Link
                        to={"/inadmissibility"}
                        className="nav-links"
                        onClick={() => {
                          setIsDropdownOpenVisit(false);
                          setMenuState(false);
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ textTransform: "uppercase" }}
                        >
                          inadmissibility
                        </p>
                      </Link>
                      <Link
                        to={"/caregiver-visa-canada"}
                        className="nav-links"
                        onClick={() => {
                          setIsDropdownOpenVisit(false);
                          setMenuState(false);
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ textTransform: "uppercase" }}
                        >
                          care giver
                        </p>
                      </Link>
                      <Link
                        to={"/private-refugee"}
                        className="nav-links"
                        onClick={() => {
                          setIsDropdownOpenVisit(false);
                          setMenuState(false);
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ textTransform: "uppercase" }}
                        >
                          private refugee
                        </p>
                      </Link>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to={"/visa-to-visit-canada"} className="nav-links">
                    VISIT
                  </Link>
                </li>

                <li>
                  <div className="navigation-dropdown">
                    <div
                      className="dropdown-trigger d-flex align-items-center"
                      style={{ color: "black" }}
                      onClick={onClickWork}
                    >
                      <a className="nav-links">WORK</a>
                      <img
                        src="/images/icons/dropDownBlack.png"
                        alt="arrow"
                        className={
                          isDropdownOpenWork
                            ? "rotate-icon arrow-icon"
                            : "rotate-back arrow-icon"
                        }
                      />
                    </div>
                    <div
                      className={`dropdown-content ${
                        isDropdownOpenWork ? "open" : "d-none"
                      }`}
                      style={{ top: "85%", left: "0%" }}
                    >
                      <Link
                        to={"/intra-company-transfers"}
                        className="nav-links"
                        onClick={() => {
                          setIsDropdownOpenWork(false);
                          setMenuState(false);
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ textTransform: "uppercase" }}
                        >
                          intra company transfers
                        </p>
                      </Link>

                      <Link
                        to={
                          "/work-permit-in-canada/working-holiday-visa-in-canada"
                        }
                        className="nav-links"
                        onClick={() => {
                          setIsDropdownOpenWork(false);
                          setMenuState(false);
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ textTransform: "uppercase" }}
                        >
                          working holiday visa (IEC)
                        </p>
                      </Link>
                    </div>
                  </div>
                </li>

                <li>
                  <Link to={"/study-permit-for-canada"} className="nav-links">
                    STUDY
                  </Link>
                </li>
                <li>
                  <div className="navigation-dropdown">
                    <div
                      className="dropdown-trigger d-flex align-items-center"
                      style={{ color: "black" }}
                      onClick={onClickExpress}
                    >
                      <a className="nav-links">EXPRESS ENTRY</a>
                      <img
                        src="/images/icons/dropDownBlack.png"
                        alt="arrow"
                        className={
                          isDropdownOpenExpress
                            ? "rotate-icon arrow-icon"
                            : "rotate-back arrow-icon"
                        }
                      />
                    </div>
                    <div
                      className={`dropdown-content ${
                        isDropdownOpenExpress ? "open" : "d-none"
                      }`}
                      style={{ top: "85%", left: "0%" }}
                    >
                      <Link
                        to={"/federal-skilled-trades-class"}
                        className="nav-links"
                        onClick={() => {
                          setIsDropdownOpenExpress(false);
                          setMenuState(false);
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ textTransform: "uppercase" }}
                        >
                          federal skilled trades class
                        </p>
                      </Link>
                      <Link
                        to={"/canadian-experience-class"}
                        className="nav-links"
                        onClick={() => {
                          setIsDropdownOpenExpress(false);
                          setMenuState(false);
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ textTransform: "uppercase" }}
                        >
                          canadian experience class
                        </p>
                      </Link>
                      <Link
                        to={"/federal-skilled-worker-program"}
                        className="nav-links"
                        onClick={() => {
                          setIsDropdownOpenExpress(false);
                          setMenuState(false);
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ textTransform: "uppercase" }}
                        >
                          fedral skilled worker
                        </p>
                      </Link>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to={"/blog"} className="nav-links">
                    BLOGS
                  </Link>
                </li>
                <li>
                  <Link to={"/about-us"} className="nav-links">
                    ABOUT
                  </Link>
                </li>
                <li>
                  <Link to={"/book-a-free-call"} className="nav-links">
                    BOOK A FREE CALL
                  </Link>
                </li>
              </ul>
              <div className="nav-buttton flex-wrap d-flex gap-4 extras-btns">
                <a href="tel:+14168910909">
                  <button className="genral-btn">
                    <img
                      src="/images/icons/phone-icon-btn.svg"
                      alt="icon"
                      style={{ marginRight: "10px", marginBottom: "2px" }}
                    />
                    +14168910909
                  </button>
                </a>
              </div>
            </div>
          </nav>
        </nav>
      </div>
    </div>
  );
};

export default Header; 