import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TextContent from '../components/TextContent/TextContent'
import TestimonialForm from "../components/TestimonialForm/TestimonialForm"
import CardBlog from "../components/CardBlog/CardBlog"
import OffersCitizenship from '../components/OffersCitizenship/OffersCitizenship'
import CitizenApplication from '../components/CitizenApplication/CitizenApplication'
import { Helmet } from 'react-helmet'

function citizenship() {
    return (
        <>
        <Helmet>
            <title>Citizenship in Canada - VCIC Immigration - All You Need To Know</title>
            <meta name="description" content="Are you looking to gain citizenship in Canada? Discover what you need to know about becoming a citizen, from applications to requirements."/>
            <link rel="canonical" href="https://vcicimmigration.com/citizenship-in-canada/"></link>
        </Helmet>
        <HeroBanner
            Heading="Citizenship in "
            spanHeading="Canada"
            subHeading=""
            postParagraph=''
            Paragraph="Our team supports and guides you through the journey to Canadian citizenship, making your dream of becoming a Canadian citizen a reality."
            btnText=""
            bgImg="/images/creatives/citizenship-bg.jpg"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            isChevron={true}
        />
    
        <div style={{marginTop:"80px"}}></div>
        
        <OffersCitizenship/>

        <CitizenApplication/>
    
        <TextContent
            shiftRow={true}
            img="/images/creatives/citizenship-text-1.png"
            heading="Did You Study In Canada And"
            spanHeading=" Now Applying For Citizenship In Canada?"
            subHeading=""
            postHeading=""
            postParagraph=''
            content={"If you studied in Canada, got a work permit and then your permanent residence. Now, you’re looking to apply for your citizenship in Canada, your physical presence requirements are not 3 years (1095 days), your requirements are 730 days which is 2 years. You can book a free call and ask us anything!"}
            btn={true}
            btnText='CONTACT US'
            btnRef='/book-a-free-call'
            statSection={false}
            listPointList={false}
            mobileTextCenter={true}
        />
    
        <TestimonialForm/>
        <CardBlog/>
    
        
    </>
      )
}

export default citizenship