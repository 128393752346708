import React from 'react'
import DropDownBox from "./DropDownBox"
import "./FaqSection.css"

function FaqSection({  
    heading,
    subHeading,
    showImg=false,
    faqData=[]
}) 
{
    

  return (
    <div className='faq-container standard-padding-space mt-5'>
        <div className='text-content d-flex flex-column align-items-center w-75 mx-auto'>
            <h3 className='body-heading'>{heading}</h3>
            <h6 className='sub-heading' style={{color : "#F2CC52"}}>{subHeading}</h6>
            {showImg && <img src='/images/creatives/faq-user-img.png' alt="people icon" className='user-img mt-4' />}

        </div>
        <div className='dropdown-container mt-3'>
            {
                faqData.map((ele)=>{
                    return(
                        <>
                            <DropDownBox title={ele.title} content={ele.content} />
                        </>
                    )
                })
            }
        </div>
    </div>
  )
}

export default FaqSection