import React from 'react'
import "./InadmissibilityCall.css"
import { useNavigate } from 'react-router-dom'

function InadmissibilityCall() {
    const navigate = useNavigate()
  return (
    <div className='inadmissibility-call-container standard-padding-space'>
        <div className='container'>
            <div className='text-container my-4 d-flex flex-column align-items-center justify-content-center'>
                <div className='text-center m-auto' style={{width:"95%"}}>
                    <h3 className='body-heading'>Can a temporary resident permit be <span className='color-yellow'>issued to individuals who are inadmissible to Canada?</span></h3>
                    <p className='body-paragraph'>A Temporary Resident Visa (TRV) is granted if it has been less than five years since you served the sentence and you have a valid reason to enter Canada. If you meet these requirements you might be granted a TRV despite being inadmissible. Canadian Immigration authorities will evaluate the risks of allowing you to enter Canada and will make the decision accordingly.</p>
                </div>
                <button className='call-btn mt-3' onClick={()=>{navigate("/book-a-free-call")}}>BOOK A FREE CALL</button>
            </div>
        </div>
    </div>
  )
}

export default InadmissibilityCall