import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TextContent from '../components/TextContent/TextContent'
import CardBlog from "../components/CardBlog/CardBlog"
import ProvincialText from '../components/ProvincialText/ProvincialText'
import FaqSection from '../components/FaqSection/FaqSection'
import TestimonialForm from '../components/TestimonialForm/TestimonialForm'
import { Helmet } from 'react-helmet'

const workerList = [
    "Have the skills, education and work experience to contribute to the economy of a specific province or territory.",
    "Want to live in that province.",
    "Want to become permanent residents of Canada."
]

const faqData = [
    {
        title:"What Are The Eligibility Requirements For The PNP?",
        content:"The applicant must have a connection to the province either through education or work experience in order to apply for a Provincial nominee program. Different provinces have different requirements for their Provincial nominee program."
    },
    {
        title:"How Do I Apply For The PNP?",
        content:"To apply for the PNP, you must first build an online profile in which you will include information about your employment experience, education, language abilities, and other pertinent characteristics. After you have made a profile, you will be evaluated based on the requirements established by the province or territory in which you are interested. If you are chosen, you will be given a province nomination, which you will use to apply for permanent residence in Canada."
    },
    {
        title:"How Long Does It Take To Process A PNP Application?",
        content:"The processing time for a PNP application varies according on the province or territory to which you apply, as well as your personal circumstances. Some provinces have more simplified application processes than others, and certain jobs may be in higher demand. In general, expect the processing time to be several months."
    },
    {
        title:"What Are The Benefits Of The PNP?",
        content:"Provincial Nominee program adds an extra 600 points to your CRS which will streamline the process of immigrating to Canada."
    },
    {
        title:"Can I Apply For The PNP If I Am Already In Canada?",
        content:" Yes, you can apply for the PNP if you are already in Canada on a valid temporary resident visa, such as a work or study permit. Some jurisdictions have particular streams for people who are currently in Canada, while others may need you to have a work offer in the province or territory."
    },
]

function provincialNominee() {
    return (
        <>
        <Helmet>
            <title>Provincial Nominee Program - VCIC Immigration</title>
            <meta name="description" content='Discover the Provincial Nominee Program, a streamlined immigration pathway to Canada. Learn eligibility requirements and how to apply for PNP' />
            <link rel="canonical" href="https://vcicimmigration.com/provincial-nominee-program/"></link>
        </Helmet>
        <HeroBanner
            Heading="Provincial "
            spanHeading="Nominee Program"
            subHeading=""
            postParagraph=''
            Paragraph="VCIC guides your Provincial Nominee path. Let us help you explore the unique pathways to immigration offered by Canadian provinces."
            btnText=""
            bgImg="/images/creatives/provincal-bg.jpg"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            isChevron={true}
        />
    
        <div style={{marginTop:"80px"}}></div>
    
        <TextContent
            shiftRow={true}
            img="/images/creatives/provincal-text-1.png"
            heading=""
            spanHeading=""
            subHeading=""
            postHeading=""
            postParagraph=''
            content={"The Provincial Nominee Program (PNP) gives Canadian provinces and territories a way to select immigrants with the qualifications and expertise they require to boost their regional economies. If you desire to immigrate to Canada, the PNP may be a quicker and more straightforward option than other immigration schemes. \n This program is for workers who:"}
            btn={false}
            btnText=''
            btnRef=''
            statSection={false}
            listPointList={true}
            listPoint={workerList}
            mobileTextCenter={true}
        />
        
        <ProvincialText
            img={"/images/creatives/provincal-text-2.png"}
        />

        <FaqSection
            heading="Frequently Asked Questions"
            subHeading="Still have questions?"
            showImg={true}
            faqData={faqData}
        />

        <TestimonialForm/>
        <CardBlog/>
        
    </>
      )
}

export default provincialNominee