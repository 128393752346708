import React from 'react'
import "./CenteredText.css"
import { useNavigate } from 'react-router-dom';

function CenteredText({
    heading="",
    spanHeading="",
    afterSpanHeading="",
    content="",
    btn=false,
    btnText="",
    btnRef="",
    
}) {
    const navigate = useNavigate()
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-2 mb-4'>{paragraph}</p>);
  return (
    <div className={`centered-text-container standard-margin-space`}>
        <div className='text-content container text-center mx-auto standard-padding-space' >
            <h3 className='body-heading mx-auto' style={{width:"90%"}}>
                {heading}
                <span className='color-yellow'>
                    {spanHeading}
                </span>
                {afterSpanHeading}
            </h3>
            <div className=' mt-3 mx-auto' style={{width:"95%"}}>
                {paragraphs}
            </div>
        { btn && <button className="mt-4 genral-btn mx-auto" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
        </div>
    </div>
  )
}

export default CenteredText