import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import NewsList from "../components/NewsList/NewsList"
import { Helmet } from 'react-helmet'

function news() {
  return (
    <>
    <Helmet>
      <title>Our Immigration Blog - VCIC Immigration</title>
      <meta name="description" content='We talk about different topics about Canada immigration in our blog such as ways to immigrate, programs, business in Canada etc.' />
      <link rel="canonical" href="https://vcicimmigration.com/blog/"></link>
    </Helmet>
    <HeroBanner
        preSpanHeading=""
        Heading="Our"
        spanHeading=" Blog"
        subHeading=""
        Paragraph="Explore our blog for the latest updates, insights, and expert opinions on immigration trends, policy changes, and destination highlights."
        btnText=""
        bgImg="/images/creatives/blog-bg.jpg"
        darkBg={true}
        headingCenter={true}
        btn={false}
        socialLinks={false}
        serviceForm={false}
        isChevron={true}
    />
    
    <NewsList/>
  
    </>
  )
}

export default news