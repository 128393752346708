import React from 'react'
import "./OffersCitizenship.css"

const eligibilityList = [
    "​To obtrain a Citizenship in Canada, an applicant must be a permanent resident and have lived in Canada for 3 out of the last 5 years (1095 days).",
    "The applicant must have filed taxes (If applicable)",
    "Pass a test on citizens’ rights, responsibilities and their knowledge of Canada.",
    "Prove language ability: If you are between the ages of 18 and 54, you must meet the Canadian Language Benchmark."
]

const ineligibleList = [
    "Are under a removal order",
    "Have a criminal record or are facing changes in Canada",
    "Been refused Canadian citizenship as a result of misrepresentation",
    "Had your Canadian citizenship revoked​​​"
]

function OffersCitizenship() {
  return (
    <div className='offers-citizenship-container standard-margin-space pb-5'>
        <div className='container'>
            <div className='text-container text-center m-auto'>
                <span className='color-yellow body-heading'>Canada offers citizenship</span>
                <h3 className='body-heading'>through naturalization and by birth in Canada.</h3>
            </div>

            <div className='comparison-container my-5'>
                <div className='row gy-3'>
                    <div className='col-md-6'>
                        <div className='list-box p-5'>
                            <h4 className='title mb-2'>Eligibility Requirements</h4>
                            <div className='list-point-container d-flex flex-column gap-3 mt-3'>
                                {
                                    eligibilityList.map((ele)=>{
                                        return(
                                            <div className='list-point d-flex align-items-start my-0'>
                                                <img src='/images/icons/hero-bullets.png' alt="icon" className='list-bullet mt-1'/>
                                                <p className='list-para my-0'>​{ele}</p>
                                            </div>
                                        )
                                    })
                                }
                               
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='list-box p-5'>
                            <h4 className='title mb-2'>Ineligible</h4>
                            <div className='list-point-container d-flex flex-column gap-3 mt-3'>
                                {
                                    ineligibleList.map((ele)=>{
                                        return(
                                            <div className='list-point d-flex align-items-start my-0'>
                                                <img src='/images/icons/hero-bullets.png' alt="icon" className='list-bullet mt-1'/>
                                                <p className='list-para my-0'>​{ele}</p>
                                            </div>
                                        )
                                    })
                                }
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default OffersCitizenship