import React from 'react'
import "./BussinessImmigration.css"
import { useNavigate } from 'react-router-dom'

function CitizenApplication() {
    const navigate = useNavigate()
  return (
    <div className='application-main-container standarad-margin-space'>
        <div className='application-container my-4 d-flex align-items-center justify-content-center'>
            <div className='container text-container text-center d-flex flex-column align-items-center justify-content-center gap-3 w-75'>
                <h3 className='body-heading w-md-75 w-100 m-auto' style={{color:"white"}}>Do you have questions about Business Immigration in Canada?</h3>
                <button className='call-btn mt-3' onClick={()=>{navigate("/book-a-free-call")}}>Get In Touch</button>
            </div>
        </div>
    </div>
  )
}

export default CitizenApplication