import React from 'react'
import "./WorkPermitWorkAutherization.css"

const listLeft =[
    "Emergency repair or repair personnel for out-of-warranty equipment C13", 
    "Television and film production workers C14", 
    "Caregiver (with PR application) C90", 
    "Entrepreneurs: Self-employed or potential provincial nominee as an entrepreneur C11", 
    "Intra Company transferee (GATS) C12*", 
    "Permanent resident facilitation work permit categories A75", 
    "(Bridging Open Work Permits)", 
    "International Experience Canada Program C21", 
    "Performing arts C23", 
    "Post Grad Work Permit C43", 
    "Spouse of workers C41"
]

const listRight = [
    "R206: No other means of support", "R207: Permanent residence applicants in Canada", "Spouse in Canada class: A70", "R207.1: Vulnerable workers", "R208: Humanitarian reasons", "International Mobility Workers Unit (IMWU): port of entry (POE) assistance"
]

const withoutPermitList = [
    "​Athletes and team members","Convention organizers", "Foreign government officers", 
    "Aviation accident or incident inspector","Crew","Foreign representatives and Family members of foreign representatives",
    "Business visitor", "Emergency service providers","Health care students",
    "Civil aviation inspector","Examiners and evaluators","Implied status",
    "Clergy","Expert witnesses or investigators",  "Performing artists",
    "Judges, referees and similar officials","News reporters, media crews","Public speakers.",
    "Military personnel","On-campus employment and some Off-campus work",
]

function WorkPermitWorkAutherization() {
  return (
    <div className='WorkPermitWorkAutherization-container'>
        <div className='container'>
            <div className='text-content  px-2 px-md-0'>
                <h3 className='body-heading my-3 mt-0' style={{textTransform:"uppercase"}}>Work Authorization</h3>
                <p className='body-paragraph my-2'>In general, foreign nationals must have a valid work permit (work permit or work permit waiver) to work in Canada.</p>

                <h4 className='title body-heading my-3' style={{fontSize:"24px",fontWeight:"normal"}}>Types of work authorization:</h4>
                <div className='d-flex align-items-start gap-md-3 gap-2 icon-list mb-3'>
                    <img src='/images/icons/hero-bullets.png' className='list-bullet mt-1' alt='hero-img' style={{width:"18px",height:"15px"}}/>
                    <p className='body-paragraph mb-0'>Work Permit (Closed)- Employer specific based upon approved LMIA supported job offer</p>
                </div>

                <p className='body-paragraph my-3'>[Canada Global Talent Search – Category A, Category-B needs a Labour market benefit plan from the employer (Re: Global Talent Stream Occupation List ] </p>
                <p className='body-paragraph my-3'>[LMIA: Labour Market Impact Assessment, is an approval of the Canadian government to hire a foreign worker]</p>

                <div className='d-flex align-items-start gap-md-3 gap-2 icon-list mb-3'>
                    <img src='/images/icons/hero-bullets.png' className='list-bullet mt-1' alt='hero-img' style={{width:"18px",height:"15px"}}/>
                    <p className='body-paragraph mb-0'>Work Permit (open: work for any employer) [ LMIA Exempted]</p>
                </div>
                <div className='d-flex align-items-start gap-md-3 gap-2 icon-list mb-3'>
                    <img src='/images/icons/dot-bullet.png' className='list-bullet mt-1' style={{width:"12px",height:"12px"}}/>
                    <p className='body-paragraph mb-0'>A25.2 Public policies </p>
                </div>
                <div className='d-flex align-items-start gap-md-3 gap-2 icon-list mb-3'>
                    <img src='/images/icons/dot-bullet.png' className='list-bullet mt-1' alt='hero-img' style={{width:"12px",height:"12px"}}/>
                    <p className='body-paragraph mb-0'>R204: International agreements or arrangements Airline personnel (operational, technical and ground personnel) -Exemption Code T11</p>
                </div>

                <p className='body-paragraph my-4'>Intra-company transferee: T24, T44, T51, C12*</p>

                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 px-lg-0 px-1'>
                            {
                                listLeft.map((ele)=>{
                                    return(<p className='body-paragraph my-3'>{ele}</p>)
                                })
                            }
                        </div>
                        <div className='col-lg-6 px-lg-4 px-1'>
                            <p className='body-paragraph my-3'>Spouse of students C42 </p>
                            <p className='body-paragraph my-3'>Religious work C50</p>

                            <div className='mt-5'>
                                {
                                    listRight.map((ele)=>{
                                        return(
                                        <div className='d-flex align-items-start justify-content-start gap-md-3 gap-2 icon-list mb-3'>
                                            <img src='/images/icons/dot-bullet.png' className='list-bullet mt-1' style={{width:"12px",height:"12px"}}/>
                                            <p className='body-paragraph mb-0'>{ele}</p>
                                        </div>)
                                    })
                                }
                            </div>
                        </div>

                    </div>

                </div>

                <h4 className='title body-heading my-5 mt-5' style={{fontSize:"24px",fontWeight:"normal"}}>Work without a permit</h4>

                <div className='container'>
                    <div className='row'>
                        {withoutPermitList.map((ele)=>{
                            return(
                            <div className='col-xxl-4 col-xl-6'>
                                <div className='d-flex align-items-start gap-md-3 gap-2 icon-list mb-3'>
                                    <img src='/images/icons/hero-bullets.png' className='list-bullet mt-1' style={{width:"18px",height:"15px"}}/>
                                    <p className='body-paragraph mb-0'>{ele}</p>
                                </div>
                            </div>)
                        })}
                        

                    </div>

                </div>
            </div>

        </div>
    </div>
  )
}

export default WorkPermitWorkAutherization