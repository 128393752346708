import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import HeroBanner from '../../components/Hero Banner/HeroBanner';
import LocationListings from './LocationListings';

const LocationScreen = () => {
    return (
        <Fragment>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <HeroBanner
                Heading="Location Pages"
                Paragraph={"VCIC Location Page Portal"}
                headingCenter={true}
                subHeading=""
                btnText=""
                bgImg="/images/creative/home-hero-bg.jpg"
                darkBg={true}
            />
            <LocationListings />
        </Fragment>
    )
};

export default LocationScreen;