import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TextContent from '../components/TextContent/TextContent'
import TestimonialForm from "../components/TestimonialForm/TestimonialForm"
import CardBlog from "../components/CardBlog/CardBlog"

const firstList = [
    "Convention Refugees Abroad: Individuals with a well-founded fear of persecution based on race, religion, nationality, political opinion, or membership in a particular social group, who cannot or are unwilling to seek protection from their home country.",
    "Country of Asylum Class: People who are seriously affected by war, armed conflict, or human rights violations in their home country and cannot return or integrate there.",
]

const secondList = [
    "Sponsorship Agreement Holders (SAHs): Established organizations with formal agreements with immigration authorities, such as religious groups or humanitarian organizations.",
    "Constituent Groups (CGs): Groups authorized by SAHs to sponsor refugees under their agreement.",
    "Groups of Five (G5): Five or more Canadian citizens or permanent residents who live in the expected settlement community and will jointly provide financial and settlement support.",
    "Community Sponsors (CSs): Organizations, associations, or corporations located in the expected settlement community."
]

function RefugeePage() {
  return (
    <>
          <HeroBanner
            Heading="Private Refugee "
            NewLinespanHeading="Sponsorship"
            subHeading=""
            postParagraph=''
            Paragraph="Empowering Refugee Lives Through Private Sponsorship"
            btnText=""
            bgImg="/images/creatives/refuge-bg.jpg"
            darkBg={false}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            isChevron={false}
        />

    <div style={{marginTop:"80px"}}></div>
        
        <TextContent
            shiftRow={true}
            img="/images/creatives/refuge-text-1.png"
            heading="Empowering Refugee Lives Through "
            spanHeading="Private Sponsorship"
            subHeading=""
            postHeading=""
            postParagraph=''
            content={"Canada is a popular destination for skilled workers looking to start a new life and build a better future. With its strong economy, high standard of living, and multicultural society, it is no wonder that people from all over the world are attracted to this beautiful country. If you are a skilled worker looking to immigrate to Canada, the Federal Skilled Worker Class program may be the perfect option for you \n The Federal Skilled Worker Program (FSW) is a Canadian immigration program designed for international skilled workers who want to become permanent residents of Canada. It is one of the three programs handled by the Express Entry system, an online system used to process applications for permanent residence from competent workers."}
            btn={true}
            btnText='BOOK A FREE CALL'
            btnRef='/book-a-free-call'
            statSection={false}
            listPointList={false}
            mobileTextCenter={true}
        />
        <TextContent
            shiftRow={false}
            img="/images/creatives/refuge-text-2.png"
            heading="Who Can Be "
            spanHeading="Sponsored?"
            subHeading=""
            postHeading=""
            postParagraph=''
            content={"The Private Sponsorship of Refugees (PSR) program is designed for individuals who meet specific criteria:"}
            btn={false}
            btnText=''
            btnRef=''
            statSection={false}
            listPointList={true}
            listPoint={firstList}
            mobileTextCenter={true}
        />
        <TextContent
            shiftRow={true}
            img="/images/creatives/refuge-text-3.png"
            heading="Who Can "
            spanHeading="Sponsor Refugees?"
            subHeading=""
            postHeading=""
            postParagraph=''
            content={"Various groups can sponsor refugees under the PSR program:"}
            btn={false}
            btnText=''
            btnRef=''
            statSection={false}
            listPointList={true}
            listPoint={secondList}
            mobileTextCenter={true}
        />

        <div className='text-content container text-center standard-padding-space'>
            <h3 className='body-heading'>How to Apply:</h3>
            <p className='body-paragraph my-3 mx-auto' style={{width:"80%"}}>Sponsoring groups must complete an application package, including forms for both the sponsor and the refugees they intend to sponsor. Applications can be submitted online through the Permanent Residence Portal.</p>

            <h6 className="mt-5 pt-2" style={{fontWeight:"bold"}}>Processing Applications:</h6>
            <p className='body-paragraph my-3 mx-auto' style={{width:"80%"}}>The Resettlement Operations Centre (ROC-O) reviews applications to ensure eligibility and completeness. If approved, the application is forwarded to the overseas IRCC office responsible for the refugee's location. This office will then interview the refugee, conduct security checks, and issue a visa if the application is successful.</p>

            <h6 className="mt-5 pt-2" style={{fontWeight:"bold"}}>Need Further Assistance?</h6>
            <p className='body-paragraph my-3 mx-auto' style={{width:"80%"}}>If you have any questions or require assistance with private refugee sponsorship, please don't hesitate to contact us. We're here to support you throughout the process and help make a difference in the lives of refugees.</p>

        </div>

        
        <TestimonialForm/>
        <CardBlog/>
    </>
  )
}

export default RefugeePage