import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./ImmigrationConsultant.css"

function ImmigrationConsultant() {
  const navigate=useNavigate()
  return (
    <div className='consultant-container d-flex align-items-center justify-content-center standard-margin-space'>
        <div className='container text-container text-center d-flex flex-column align-items-center justify-content-center gap-3 w-75'>
            <h2 className='body-heading '>Immigrate to Canada with the help of our Licensed <span className='color-yellow'>Immigration Consultant.</span></h2>
            <p className='body-paragraph '>We proud ourselves in having a transparent process, we make sure that the process is smooth and straightforward for you.</p>
            <button className='genral-btn' onClick={()=>{navigate("/start-your-free-initial-assessment")}}>Start Assessments</button>
        </div>
    </div>
  )
}

export default ImmigrationConsultant