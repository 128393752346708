import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TextContent from '../components/TextContent/TextContent'
import CardBlog from "../components/CardBlog/CardBlog"
import TestimonialForm from '../components/TestimonialForm/TestimonialForm'
import FaqSection from "../components/FaqSection/FaqSection"
import { Helmet } from 'react-helmet'

const faqData = [
    {
        title:"How Long Can An Employee Stay In Canada Under The Intra-Company Transfer Program?",
        content:"For employees that are senior managers or executives can stay 3 years and can extend their stay for an extra 2 years. However, for employees with ‘specialized knowledge’ can only stay 1 year and can extend their stay for another year."
    },
    {
        title:"Can An Intra Company Transfer Employee Apply For Permanent Residence ?",
        content:"Yes! Intra company transferees can use their work experience to apply for permanent residence through the Express Entry program granted they are eligible to apply for one of the federal economic immigration programs."
    },
]

function intraWorkTransfer() {
  return (
    <>
    <Helmet>
        <title>Intra-Company Transfer - VCIC Immigration</title>
        <meta name="description" content='Are you a company looking to transfer an employee from a foreign location to Canada? Read this guide on Intra-Company transfer!' />
        <link rel="canonical" href="https://vcicimmigration.com/intra-company-transfers/"></link>
    </Helmet>
    <HeroBanner
        Heading="Intra-Company "
        spanHeading="Transfers"
        subHeading=""
        postParagraph=''
        Paragraph="Transition seamlessly to global opportunities with VCIC's Intracompany Transfer services. We simplify the process, ensuring a smooth relocation for you and your career"
        btnText=""
        bgImg="/images/creatives/iwt-hero-bg.jpg"
        darkBg={true}
        headingCenter={true}
        btn={false}
        socialLinks={false}
        serviceForm={false}
        isChevron={true}
    />

    <div style={{marginTop:"80px"}}></div>

    <TextContent
        shiftRow={true}
        img="/images/creatives/iwt-text-1.png"
        heading="What Is "
        spanHeading="Intra-Company Transfer?"
        content={"Intra-company transfers are a valuable tool for multinational companies to expand their business operations in Canada while leveraging the expertise of their employees. Eligibility requirements must be met, and compliance with Canadian laws and regulations is crucial. \n The International Mobility Program permits high- skilled foreign workers to work in Canada as temporary workers under the Intra-Company Transferee (ICT) Program. If an overseas company has a location in Canada, it can ask to have any of its employees moved to that location. This is LMIA exempt work permit. The principal applicant’s spouse and children can also get an open work permit and study permit, respectively. \n Work experience gained as an intra-company transferee in Canada may qualify for permanent residence under Canadian work experience class."}
        btn={true}
        btnText='BOOK A FREE CALL'
        btnRef='/book-a-free-call'
        statSection={false}
        listPointList={false}
        mobileTextCenter={true}
    />
    
    <FaqSection
        heading="Frequently Asked Questions"
        subHeading="Still have questions?"
        showImg={true}
        faqData={faqData}
    />

    <TestimonialForm/>
    <CardBlog/>
    
</>
  )
}

export default intraWorkTransfer