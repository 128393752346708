import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TextContent from '../components/TextContent/TextContent'
import CardBlog from "../components/CardBlog/CardBlog"
import TestimonialForm from '../components/TestimonialForm/TestimonialForm'
import BgImageContent from '../components/BgImageContent/BgImageContent'
import { Helmet } from 'react-helmet'

const list1 = 
    [
        "Major Group 72, industrial, electrical and construction trades",
        "Major Group 73, maintenance and equipment operation trades",
        "Major Group 82, supervisors and technical jobs in natural resources, agriculture and related production",
        "Major Group 92, processing, manufacturing and utilities supervisors and central control operators",
        "Minor Group 632, chefs and cooks",
        "Minor Group 633, butchers and bakers",
    ]

function FSTC() {
  return (
    <>
    <Helmet>
        <title>Federal Skilled Trades Class (FSTC) - VCIC Immigration</title>
        <meta name="description" content='Check out the requirements for the Federal Skilled Trades Class. Do you think you might be eligible? Ask us a question and we will get back.'/>
        <link rel="canonical" href="https://vcicimmigration.com/federal-skilled-trades-class/"></link>
    </Helmet>
    <HeroBanner
        Heading="Federal Skilled Trades Class "
        spanHeading="(FSTC)"
        subHeading=""
        postParagraph=''
        Paragraph="VCIC Immigration is your trusted partner in navigating the Federal Skilled Trades Program. Whether you are an experienced welder, electrician, or carpenter, let us guide you towards a rewarding future in Canada."
        btnText=""
        bgImg="/images/creatives/fstc-hero-bg.jpg"
        darkBg={true}
        headingCenter={true}
        btn={false}
        socialLinks={false}
        serviceForm={false}
        isChevron={true}
    />

    <div style={{marginTop:"80px"}}></div>

    <TextContent
        shiftRow={true}
        img="/images/creatives/fstc-text-1.png"
        heading="Federal Skilled Trades Class "
        spanHeading="(FSTC)"
        content={"The Federal Skilled Trades Program is for skilled workers who want to become permanent residents based on being qualified in a skilled trade. Skilled trades for the Federal Skilled Trades Program are organised under these groups of the National Occupational Classification (NOC):"}
        btn={false}
        statSection={false}
        listPointList={true}
        listPoint={list1}
        postListContent={"The major NOC groups are subdivided into different occupations, and they are all skill type B. \n One must show that you performed the duties set out in the lead statement of the occupational description in the NOC. This includes all the essential duties and most of the main duties listed."}
        mobileTextCenter={true}
    />
    <TextContent
        shiftRow={false}
        img="/images/creatives/fstc-text-2.png"
        heading="Selection Factors And "
        spanHeading="Minimum Requirements"
        content={"Skilled work experience: 2 years full time (minimum 30 hrs/week) within 5 (five) years \n Education: Points are given for Certificate, Diploma, Degree \n Language ability: Minimum CLB (Canadian Language Benchmark) of 5(five) for Speaking and Listening), and 4(four) for Reading and writing \n A valid job offer of full-time employment for a total period of at least 1 year OR \n A Certificate of Qualification (COQ) by in that skilled trade issued by a Canadian provincial, territorial or federal authority"}
        btn={false}
        statSection={false}
        listPointList={false}
        mobileTextCenter={true}
    />

    <BgImageContent
         heading="Does your case have a lot of complexities?"
         img="/images/creatives/fstc-center-bg.jpg"
         content={"Let Our Immigration Consultant Take A Look."}
         btn={true}
         btnText ="ASK A QUESTION"
         btnRef="/book-a-free-call"
    />

    <TestimonialForm/>
    <CardBlog/>
    
</>
  )
}

export default FSTC