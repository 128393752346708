import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TextContent from '../components/TextContent/TextContent'
import TestimonialForm from "../components/TestimonialForm/TestimonialForm"
import CardBlog from "../components/CardBlog/CardBlog"
import FaqSection from '../components/FaqSection/FaqSection'
import HowWeHelp from "../components/HowWeHelp/HowWeHelp"
import { Helmet } from 'react-helmet'

const dli2Data = ["Check for DLI Number and Post Graduate Work Permit eligible programs","Apply, receive an offer letter, pay the tuition, then wait to receive a letter of acceptance."]
const appropriate3Data = ["Admission confirmation with program details", "Guaranteed Investment Certificate for a sum of CAD10,000", "Language test score", "Upfront medical examination"]
const helpList = [
  "Personalized guidance throughout the process along with finding the best designated Learning Institute for you based on your preference. ",
  "Assistance in gathering and organizing all necessary documents, saving you time and ensuring your application is complete.",
  "Comprehensive support with completing and submitting your online application, minimizing errors and streamlining the process.",
  "Timely updates on your application status, providing peace of mind and transparency throughout the process.",
];
const faqData = [
    {title:"Study Permit And Accompanying Family Members:",content:"You can apply to bring your partner and/or any dependant children with you to Canada while you are a student. Children may travel on a Temporary Resident Visa/Study Permit and your spouse may come on a Temporary Resident Visa/Work Permit, but you must show that you have the financial means to sustain your family and pay for your education."},
    {title:"Work Permit After Study Permit",content:"You must submit your PGWP application as soon as possible. In your PGWP application packet, you must also submit the programme completion letter from your school."},
    {title:"Permanent Residence",content:"You may be eligible to become a Permanent Resident in Canada after 1 year of work experience in any O, A, B skilled jobs [NOC-National Occupational Classification]. You may get a chance to become a PR through Express Entry, Canadian Experience Class or provincial nominations"},
]

function work() {
  return (
    <>
   <Helmet>
    <title>Study Permit for Canada - VCIC Immigration</title>
    <meta name="description" content='Looking for a study permit in Canada? Check out our guide on how to apply! Start exploring interesting new educational opportunities today!' />
    <link rel="canonical" href="https://vcicimmigration.com/study-permit-for-canada/"></link>
   </Helmet>
    <HeroBanner
        Heading="Your Academic Journey in Canada "
        spanHeading="Starts Here"
        subHeading=""
        postParagraph='Let Us Be Your Gateway to Canadian Study Permits'
        postParagraphCenter={true}
        Paragraph="Welcome to VCIC Immigration, where we lead the way for your educational dreams in Canada. Explore our streamlined process to secure your study permit and begin a transformative academic journey in one of the world's most renowned educational settings."
        btnText=""
        bgImg="/images/creatives/study-bg.jpg"
        darkBg={true}
        headingCenter={true}
        btn={false}
        socialLinks={false}
        serviceForm={false}
        isChevron={true}
    />

    <div style={{marginTop:"80px"}}></div>

    <TextContent
        shiftRow={false}
        img="/images/creatives/study-text-1.png"
        heading="Step 1: Prepare Your "
        spanHeading="Application"
        subHeading=""
        postHeading=""
        postParagraph=''
        content="Gather all necessary documents, including your letter of acceptance from a designated learning institution (DLI) and a provincial attestation letter (PAL) from the province or territory of study. Ensure your application is complete to avoid delays in processing."
        btn={false}
        statSection={false}
        listPointList={false}
        mobileTextCenter={true}
    />
    <TextContent
        shiftRow={true}
        img="/images/creatives/study-text-2.png"
        heading="Step 2: Complete the "
        spanHeading="Online Application"
        subHeading=""
        postHeading=""
        postParagraph=''
        content="Fill out the online application form accurately and completely. Provide all required information and documentation as specified in the application guidelines."
        btn={false}
        statSection={false}
        listPointList={false}
        // listPoint={dli2Data}
        mobileTextCenter={true}
    />
    <TextContent
        shiftRow={false}
        img="/images/creatives/study-text-3.png"
        heading="Step 3: Pay the "
        spanHeading="Application Fees"
        subHeading=""
        postHeading=""
        postParagraph=''
        content="Pay the required application fees online using a valid payment method. Be sure to keep a record of your payment confirmation for reference."
        btn={false}
        statSection={false}
        listPointList={false}
        // listPoint={appropriate3Data}
        mobileTextCenter={true}
    />
    <TextContent
        shiftRow={true}
        img="/images/creatives/study-text-4.png"
        heading="Step 4: Submit Your "
        spanHeading="Application"
        subHeading=""
        postHeading=""
        postParagraph=''
        content={"Once your application is complete and the fees are paid, submit it online through the designated application portal. Ensure all documents are uploaded correctly to avoid any processing delays."}
        btn={false}
        statSection={false}
        listPointList={false}
        mobileTextCenter={true}
    />

    <HowWeHelp
        heading={"HOW CAN WE "}
        spanHeading={"MAKE A DIFFERENCE?"}
        list={helpList}
    />

    <FaqSection
        heading="What's Next?"
        subHeading=""
        showImg={false}
        faqData={faqData}
       />

    <TestimonialForm/>
    <CardBlog/>

</>
  )
}

export default work