import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import PartnerCompanies from '../components/PartnerCompanies/PartnerCompanies'
import TextContent from '../components/TextContent/TextContent'
import CardCarousel from "../components/CardCarousel/CardBlog"
import TestimonialForm from "../components/TestimonialForm/TestimonialForm"
import CardBlog from "../components/CardBlog/CardBlog"
import WhyChooseUs from '../components/WhyChooseUs/WhyChooseUs'
import ImmigrationConsultant from '../components/ImmigrationConsultant/ImmigrationConsultant'
import OurProcess from '../components/OurProcess/OurProcess'
import FaqSection from '../components/FaqSection/FaqSection'
import { Helmet } from 'react-helmet'

const faqData = [
  {title:"Which Consultant Is Best For Canada Immigration?",content:"Rajiv Sharma is a Regulated Canadian Immigration consultant (R709167) who has his office in Milton, Ontario. His holistic and straight forward approach makes him one of the best immigration consultant in Canada. He is a member in good standing of the College of Immigration and Citizenship Consultants (CICC)."},
  {title:"What Does An Immigration Consultant Do In Canada?",content:"Immigration consultant provides professional guidance to immigrants throughout the immigration process. They assess the applicants eligibility and represents the applicant in dealing with visa paperwork and filing. It is important that Immigration consultants are licensed by CICC to provide immigration advice."},
  {title:"Is It Worth Getting An Immigration Consultant For Canada?",content:"An immigration consultant will assess your application and make the immigration process simple by handling paperwork and making sure there are no mistakes on your file. A minor mistake can prolong your application processing time by providing the required information at various parts of the application."},
  {title:"How Much Does It Cost To Hire An Immigration Consultant In Canada?",content:"It depends upon your case. Canadian immigration consultation might get expensive if your case is too complex and requires a lot of paperwork. it can range from $500- $8000 depending on your case."},
  {title:"Which Immigration Services Do You Provide?",content:"We offer Study visa, Work visa, Express Entry, Refusal applications, spousal sponsorship Canada, refugee applications, H& C applications, inadmissibility applications and many more."},
  {title:"How Do I Choose A Good Immigration Consultant?",content:"The immigration consultant must be a Regulated Canadian Immigration Consultant (RCIC) licensed by CICC. Checking out their google reviews, social media and past records would help you make a good decision."},
]

function home() {
  return (
    <>
    <Helmet>
      <title>Immigration Consultant in Canada - RCIC - VCIC Immigration</title>
      <meta name="description" content='We are an Immigration Consultant in Canada providing a wide range of Immigration services. Book a free 30 minute consultation today!'/>
      <link rel="canonical" href="https://vcicimmigration.com/"></link>
    </Helmet>
        <HeroBanner
            subHeading=""
            headingSmall={true}
            Heading="Making your immigration journey "
            spanHeading="stress-free and successful"
            Paragraph="Ready to take the first step towards a new chapter in your life? Rajiv Sharma, our regulated immigration expert, is here to ensure your experience is seamless and hassle-free."
            btnText=""
            bgImg="/images/creatives/home-hero-bg.jpg"
            btn={false}
            statSection={true}
            socialLinks={false}
            serviceForm={false}
            sideImage={true}
            sideImageRef="/images/creatives/home-hero-side.png"
        />
        <PartnerCompanies pTop={true}/>
        <TextContent
          shiftRow={false}
          img="/images/creatives/text-side-2.webp"
          heading="Licensed "
          spanHeading="Immigration Consultant in Canada"
          subHeading=""
          bio={true}
          bioImg={true}
          content="Rajiv Sharma is the director of Vajra Canadian Immigration Consultancy Inc. He is a member in good standing of the CICC (College of Immigration and Citizenship Consultants). Being an immigrant himself, he recognizes the complexity of the Canadian Immigration system and understands the importance of simplifying the immigration process for his clients."
          btn={true}
          btnText="About Us"
          btnRef={"/about-us"}
          headingH2={true}
        />
        <CardCarousel
          subHeading="OUR SERVICES"
          heading="Your Success "
          spanHeading="Journey"
        />
       <WhyChooseUs/>
       <ImmigrationConsultant/>
       <OurProcess/>
       <FaqSection
        heading="Frequently Asked Questions"
        subHeading="Still have questions?"
        showImg={true}
        faqData={faqData}
       />

        <TestimonialForm/>
        <CardBlog/>

    </>
  )
}

export default home