import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TextContent from '../components/TextContent/TextContent'
import CardBlog from "../components/CardBlog/CardBlog"
import TestimonialForm from '../components/TestimonialForm/TestimonialForm'
import FaqSection from "../components/FaqSection/FaqSection"
import InadmissibleText from '../components/InadmissibleText/InadmissibleText'
import CenteredText from '../components/CenteredText/CenteredText'
import CenteredTextLineDiv from "../components/CenteredTextLineDiv/CenteredTextLineDiv"
import { Helmet } from 'react-helmet'

const faqData = [
    {
        title:"How Long Does It Take To Process A FSW Application?",
        content:"According to the website of the Government of Canada, it is estimated that the processing time for a complete FSW application is roughly 6 to 8 months."
    },
    {
        title:"Am I Required to Show Settlement Funds For FSW?",
        content:"Yes, if you are applying for the Federal Skilled Worker (FSW) program in Canada, you will need to show that you have enough money to support yourself and any dependents that will be coming with you to Canada, even if you have a job offer in the country."
    },
    {
        title:"Do I Need to Provide Language Test Results For The FSW Program?",
        content:"Yes, you are required to provide language test i.e. International English Language Testing System (IELTS) for English language proficiency or the Test d’evaluation de Francis (TEF) for French language proficiency results."
    },
    {
        title:"Is the Federal Skilled Worker Program Open?",
        content:"Yes, the Federal Skilled Worker (FSW) program is currently open and accepting applications for permanent residence in Canada."
    },
    {
        title:"Can a Federal Skilled Worker Candidate Work in Quebec?",
        content:"Yes, a Federal Skilled Worker (FSW) candidate can work in Quebec if they meet the eligibility requirements for the Quebec Skilled Worker program.Quebec has its own immigration program, known as the Quebec Skilled Worker program, which has its own criteria for eligibility and application process separate from the federal programs."
    },
    {
        title:"Is There An Age Limit To Apply Under The FSW Program?",
        content:"Yes, candidates must be between 18 and 44 years of age at the time of application for eligibility in the FSW program."
    },
    {
        title:"Can My Spouse and Children Be Included in My FSW Application?",
        content:"Yes, you can include your spouse or partner and dependent children in your application for permanent residence in Canada under the Federal Skilled Worker (FSW) program.This is referred to as “family class” sponsorship, which enables you to apply for permanent residence for yourself and your family members simultaneously."
    },
    {
        title:"Can I Include My Parents in My Federal Skilled Worker Application?",
        content:"No, parents cannot be included in a Federal Skilled Worker (FSW) application. However, some provincial nominee programs do allow for the family sponsorship of parents in certain situations.As an example, the Ontario Immigrant Nominee Program (OINP) has a “Family Stream” category that allows Ontario residents to sponsor their parents for permanent residence, as long as they meet certain requirements."
    },
    {
        title:"Do I Need A Job Offer To Apply Under The FSW Program?",
        content:"No, you don’t need a job offer to apply for the Federal Skilled Worker (FSW) program.This program is only for those who have the skills necessary to help the Canadian economy and have the potential to become financially stable in Canada."
    },
    {
        title:"I Have A Criminal Background or Serious Medical Condition – Can I Still Apply Under The FSW Program?",
        content:"If you have a criminal record or a serious medical condition, you are still eligible to apply for the Federal Skilled Worker (FSW) program, although it may influence your chances of being accepted."
    },
]

const listData1=[
    {
        title:"Skilled work experience",
        content:"Applicants must have had at least one year of full-time or part-time experience in a National Occupational Classification (NOC) skill type 0, A, or B within the past 10 years."
    },
    {
        title:"Language proficiency",
        content:"The applicant must have sufficient level in the International English Language Testing System (IELTS) for English or the Test d’évaluation de français (TEF) for French."
    },
    {
        title:"Education",
        content:"The applicant must have obtained a foreign education credential evaluation (ECA) from an approved organization, showing that their foreign education is comparable to Canadian education. If you studied in Canada, you need a degree, certificate or diploma from a Canadian high school or post secondary institution."
    },
    {
        title:"Adaptability",
        content:"The applicant must show that they are able to adjust to life in Canada like their educational background, work experience, and whether they have family or relatives living in Canada."
    },
    {
        title:"Other requirements",
        content:"The applicant must fulfill other criteria, including passing a medical examination and undergoing security/background checks."
    },
]
const listData2=[
    {
        title:"Check your eligibility",
        content:"Before submitting your application, ensure that you meet the criteria to become eligible for the Federal Skilled Worker Program."
    },
    {
        title:"Complete an Express Entry profile",
        content:"Create an online profile in the Express Entry system and provide information about your education, work experience, language proficiency, and other personal details."
    },
    {
        title:"Receive an Invitation to Apply (ITA)",
        content:"If you qualify and get enough points on the Comprehensive Ranking System (CRS) that Express Entry uses, you could be given an Invitation to Apply (ITA) for permanent residence in Canada."
    },
    {
        title:"Submit your application",
        content:"You have a period of 60 days to submit a full application for permanent residency in Canada after receiving an invitation, including all necessary paperwork and fees."
    },
]
const listData3=[
    {
        title:"",
        content:"Skill Type 0 (Managerial occupations)"
    },
    {
        title:"",
        content:"Skill Level A (Professional occupations)"
    },
    {
        title:"",
        content:"Skill Level B (Technical occupations and skilled trades)"
    },
]

function fedralSkilledWorker() {
  return (
    <>
    <Helmet>
        <title>Federal Skilled Worker Program - VCIC Immigration</title>
        <meta name="description" content="If you're looking to immigrate to Canada, the Federal Skilled Worker Program has some specific eligibility criteria you must meet. Learn more"/>
        <link rel="canonical" href="https://vcicimmigration.com/federal-skilled-worker-program/"></link>
    </Helmet>
    <HeroBanner
        Heading="Federal Skilled Worker"
        spanHeading=" Class Program"
        subHeading=""
        postParagraph=''
        Paragraph="Skilled, qualified, and invited – VCIC facilitates Federal Skilled Worker immigration. Let us help you navigate the pathway to Canada's skilled workforce."
        btnText=""
        bgImg="/images/creatives/fsw-hero-bg.jpg"
        darkBg={true}
        headingCenter={true}
        btn={false}
        socialLinks={false}
        serviceForm={false}
        isChevron={true}
    />

    <div style={{marginTop:"80px"}}></div>

    <TextContent
        shiftRow={true}
        img="/images/creatives/fsw-text-1.png"
        heading="The Federal Skilled Worker Class Program: "
        spanHeading="A Path To Permanent Residency In Canada"
        content={"Canada is a popular destination for skilled workers looking to start a new life and build a better future. With its strong economy, high standard of living, and multicultural society, it is no wonder that people from all over the world are attracted to this beautiful country. If you are a skilled worker looking to immigrate to Canada, the Federal Skilled Worker Class program may be the perfect option for you \n The Federal Skilled Worker Program (FSW) is a Canadian immigration program designed for international skilled workers who want to become permanent residents of Canada. It is one of the three programs handled by the Express Entry system, an online system used to process applications for permanent residence from competent workers."}
        btn={true}
        btnText='BOOK A FREE CALL'
        btnRef='/book-a-free-call'
        statSection={false}
        listPointList={false}
        mobileTextCenter={true}
    />

    <div style={{marginTop:"100px"}}></div>

    <InadmissibleText
            heading={"Who Is Eligible To "}
            spanHeading={"Apply Under The FSW Program?"}
             img="/images/creatives/fsw-text-2.png"
             shiftRow={false}
             content1="To be eligible to apply for the Federal Skilled Worker (FSW) program in Canada, applicants must meet the following criteria:"
             postHeading=""
             content3={{title:"",content: "A minimum of 67 points out of 100 is required to be eligible to apply for the FSW program."}}
             listPoint={listData1}

        />

    <div style={{marginTop:"120px"}}></div>

    <InadmissibleText
            heading={"How Can I "}
            spanHeading={"Apply For The FSW Program?"}
             img="/images/creatives/fsw-text-3.png"
             shiftRow={true}
             content1="To apply for the Federal Skilled Worker (FSW) program in Canada, you need to follow these steps:"
             postHeading=""
             content3={{title:"Wait for processing : ",content: "The processing time for an application can vary depending on various factors, such as the number of applications and additional documentation."}}
             listPoint={listData2}
        />

    <CenteredTextLineDiv
        heading="What Are The "
        spanHeading="Processing Fees to Apply Under The FSW Program?"
        afterSpanHeading=""
        content={"As of March 2023, the fees for the FSW program are as follows: \n Processing fee: $1,325 CAD per applicant \n Right of Permanent Residence Fee (RPRF): $500 CAD per applicant (for applicants who are approved for permanent residence) \n You are required to pay other fees for services such as medical exams, police certificates, language testing, and educational credential assessments, depending on your individual situation."}
        btn={false}
    />

    <div style={{marginTop:"120px"}}></div>

    <InadmissibleText
            heading={"What is the "}
            spanHeading={"Federal Skilled Worker Program Eligible Occupations?"}
             img="/images/creatives/fsw-text-4.png"
             shiftRow={false}
             content1="Under the FSW program, your occupation must be listed in one of the following National Occupational Classification (NOC) skill levels:"
             postHeading=""
             content3={{title:"",content: "Each occupation in the NOC is assigned a four-digit code that identifies the type of work and the industry or sector. You can search for your occupation by NOC code or job title on the Government of Canada’s website."}}
             listPoint={listData3}
        />
    
    <CenteredText
        heading="Federal Skilled Worker Program "
        spanHeading="VS "
        afterSpanHeading="Express Entry System"
        content={"The FSW program is a federal immigration program that allows skilled workers with work experience in eligible occupations to apply for permanent residence in Canada.\n Applicants under the FSW program must meet specific eligibility criteria, including language proficiency, education, work experience, and settlement funds.\n The Express Entry system is an electronic system that manages applications for three federal economic immigration programs: the Federal Skilled Worker Program, the Federal Skilled Trades Program, and the Canadian Experience Class.\n Applicants create an online profile and are ranked based on the Comprehensive Ranking System (CRS).\n It considers factors such as age, language proficiency, education, work experience, and other factors. Applicants with the highest CRS scores are invited to apply for permanent residence in Canada through regular draws from the Express Entry pool."}
        btn={true}
        btnText="BOOK A 30 MINUTE FREE CONSULTATION"
        btnRef="/book-a-free-call"
    />

    <FaqSection
        heading="Frequently Asked Questions"
        subHeading="Still have questions?"
        showImg={true}
        faqData={faqData}
    />

    <TestimonialForm/>
    <CardBlog/>
    
</>
  )
}

export default fedralSkilledWorker