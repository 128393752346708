import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TestimonialForm from '../components/TestimonialForm/TestimonialForm'
import TextContent from '../components/TextContent/TextContent'
import CardBlog from "../components/CardBlog/CardBlog"
import DiscoverServices from "../components/DiscoverServices/DiscoverServices"
import { Helmet } from 'react-helmet'

function service() {
  return (
    <>
    <Helmet>
      <title>Service Areas - VCIC Immigration</title>
      <meta name="description" content="VCIC serves everybody in the world looking for immigration guidance to Canada but here are some areas that we help residents of."/>
      <link rel="canonical" href="https://vcicimmigration.com/service-areas/"></link>
    </Helmet>
        <HeroBanner
            subHeading=""
            Heading="Service Areas | "
            spanHeading="VCIC Immigration"
            Paragraph="Expert Guidance, Streamlined Process, Secure Future. Our experienced immigration professionals possess in-depth knowledge of Canadian immigration laws and procedures. You're in capable hands."
            btnText=""
            bgImg="/images/creatives/service-hero-bg.jpg"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            isChevron={true}
        />
        
        <TextContent
            shiftRow={true}
            img="/images/creatives/text-4.png"
            heading="Why Do I Need An "
            spanHeading="Immigration Consultant For Canada?"
            subHeading=""
            content="Although, it’s not necessary to hire an immigration consultant for Canada but having one by your side to guide you through the process will definitely take a lot of stress off your shoulders. Most importantly, if you submit the wrong or invalid documents the processing time of your application will definitely be prolonged. To avoid any frustration it’s best to hire one."
            btn={true}
            btnText='Contact Us'
            btnRef='/book-a-free-call'
            statSection={false}
        />

        <DiscoverServices/>

        <TextContent
            shiftRow={false}
            img="/images/creatives/text-5.png"
            heading="Immigrate to Canada through our "
            spanHeading="Licensed Immigration Consultant"
            subHeading=""
            content="Vajra Canadian Immigration Consultancy Inc. - Your Gateway to Canada! Led by Regulated Canadian Immigration Consultant Rajiv Sharma (#R709167), we offer holistic, transparent, and purposeful guidance. With over 15 years of experience, we cover all aspects of Canadian immigration."
            btn={true}
            btnText ="Contact Us"
            btnRef="/book-a-free-call"
            statSection={false}
            advantagesList={false}
        />

        <TestimonialForm/>

        <CardBlog/>
    </>
  )
}

export default service