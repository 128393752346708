import React from 'react'
import "./InadmissibilityPoints.css"

const pointData = [
    {
        title:"If you were convicted of an offence committed outside of Canada that, if committed in Canada, would imprison you for less than 10 years:",
        content1:"You will be deemed rehabilitated: after 10 years of serving the sentence imposed",
        content2:"You will be eligible to apply for rehabilitation: after 5 years of serving the sentence."
    },
    {
        title:"If you committed a crime outside of Canada that, if committed in Canada, would imprison you for less than 10 years:",
        content1:"You will be deemed rehabilitated: after 5 years of committing the crime",
        content2:"You can apply for rehabilitation: after 10 years of committing the crime"
    },
    {
        title:"If you were convicted of an offence committed outside of Canada that, if committed in Canada, would imprison you for 10 years or more:",
        content1:"You can not be deemed rehabilitated",
        content2:"You can apply for rehabilitation: after 5 years of serving the sentence"
    },
    {
        title:"If you were convicted for 2 or more offences outside of Canada, if committed in Canada:",
        content1:"You cannot apply for rehabilitation",
        content2:"you will be deemed rehabilitated: at least 5 years after the penalties were served or are about to be served"
    },
]

function InadmissibilityPoints() {
  return (
    <div className='inadmissibility-points-container standard-margin-space'>
        <div className='container inadmissibility-points-list text-center'>
            {
                pointData.map((ele ,ind)=>{
                    return(<>
                            <div className='inadmissibility-point'>
                                <h4 className='title'>{ele.title}</h4>
                                <p className='body-paragraph my-1'>{ele.content1}</p>
                                <p className='body-paragraph '>{ele.content2}</p>
                            </div>
                            {ind < pointData.length-1 && <div className='line-seprator my-3'></div>}
                        </>
                    )
                })
            }
            

        </div>
    </div>
  )
}

export default InadmissibilityPoints