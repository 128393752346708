import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import AssessmentForm from '../components/AssessmentForm/AssessmentForm'
import {Helmet} from "react-helmet"

function Assessments() {
  return (
    <>
    <Helmet>
      <title>Start your initial assessment - VCIC Immigration</title>
      <meta name="description" content='We are a Canadian immigration consultation firm. Enter your personal details for our immigration consultant to assess your case.'/>
      <link rel="canonical" href="https://vcicimmigration.com/start-your-free-initial-assessment/"></link>
    </Helmet>
        <HeroBanner
            subHeading=""
            Heading="Canadian Immigration "
            spanHeading=" Consultation"
            postParagraph="Start Your Free Initial Assessment"
            postParagraphCenter={true}
            Paragraph=""
            btnText=""
            bgImg="/images/creatives/assessment-bg.jpg"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            isChevron={false}
        />
        
        <AssessmentForm/>
    </>
  )
}

export default Assessments