import React from 'react'
import CommonBanner from "../components/Hero Banner/HeroBanner"
import { Helmet } from 'react-helmet'

function contact() {
  return (
    <>
    <Helmet>
      <title>Book a free call - VCIC Immigration</title>
      <meta name="description" content='Immigrating to Canada could become complex. Book a free call with our immigration consultant today to get all your questions answered!'/>
      <link rel="canonical" href="https://vcicimmigration.com/book-a-free-call/"></link>
    </Helmet>
        <CommonBanner
          subHeading=""
          Heading="Get in touch "
          spanHeading="with us!"
          postParagraph="Book a free 30 Minute Consultation"
          Paragraph="This would be a 30 minute free consultation. We will reach you via email or phone. We will answer any questions you have about the immigration process! We will let you know how can we help you out with your case and if you wish to proceed further we will send you a retainer agreement! Start your Canadian Immigration Consultation now!"
          btnText=""
          bgImg="/images/creatives/contact-hero-bg.jpg"
          btn={false}
          sideImage={false}
          socialLinks={true}
          serviceForm={true}
        />
    </>
  )
}

export default contact