import React from 'react'
import "./ProvincialText.css"

const listPoint1 = [
    "Students","Skilled workers","Business people"," Semi-skilled workers" 
]
const listPoint2 = [
    "Alberta","Nova Scotia",  
    "British Columbia","Ontario",
    "Manitoba" ,"Prince Edward Island" ,
    "New Brunswick" ,"Saskatchewan",
    "Newfoundland and Labrador" ,"Yukon",
    "Northwest Territories"
]

function ProvincialText({
    shiftRow=false,
    img
}) {
  return (
    <div className='provincial-text-container'>
        <div className='text-container standard-padding-space '>
        <div className='container'>
            <div className={`row d-flex ${ shiftRow ? "flex-row-reverse" : ""} align-items-center justify-content-between gap-4 gap-md-0`}>
                <div className='col-md-6 h-100 my-auto'>
                    <img src={img} className='img-fluid' alt="text "/>
                </div>
                <div className='col-md-6 h-100 my-auto px-md-auto px-4'>
                    <p className='content body-paragraph mb-0'>Each province and territory have its own “streams” (immigration programs that target certain groups) and requirements. For example, in a program stream, provinces and territories may target:</p>

                    <div className='row gy-3 py-3'>
                    {
                        listPoint1.map(ele =>{
                            return(
                                <div className='d-flex align-items-center col-lg-6 gap-lg-1 gap-xl-2 gap-2 icon-list'>
                                    <img src='/images/icons/hero-bullets.png' alt="icon" className='list-bullet'/>
                                    <p className='body-paragraph mb-0'>{ele}</p>
                                </div>)
                        })
                    }
                    </div>

                    <p className='body-paragraph mb-0 mt-3'>To be nominated by a province or territory one must follow the instructions on their website and contact them directly:</p>
                    <div className='row gy-3 py-3'>
                    {
                        listPoint2.map(ele =>{
                            return(
                                <div className='d-flex align-items-center col-lg-6 gap-lg-1 gap-xl-2 gap-2 icon-list'>
                                    <img src='/images/icons/hero-bullets.png' className='list-bullet' alt='hero-bullet'/>
                                    <p className='body-paragraph mb-0'>{ele}</p>
                                </div>)
                        })
                    }
                    </div>

                    <p className='body-paragraph mt-2'>The criteria by province and territory vary and can change without notice.</p>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default ProvincialText