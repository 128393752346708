import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TextContent from '../components/TextContent/TextContent'
import CardBlog from "../components/CardBlog/CardBlog"
import TestimonialForm from '../components/TestimonialForm/TestimonialForm'
import InadmissibleText from '../components/InadmissibleText/InadmissibleText'
import CenteredText from '../components/CenteredText/CenteredText'
import CenteredTextLineDiv from "../components/CenteredTextLineDiv/CenteredTextLineDiv"
import WorkPermitHeadingText from '../components/WorkPermitHeadingText/WorkPermitHeadingText'
import WorkPermitCenteredText from '../components/WorkPermitCenteredText/WorkPermitCenteredText'
import WorkParmitAdjecentList from '../components/WorkParmitAdjecentList/WorkParmitAdjecentList'
import WorkPermitWorkAutherization from '../components/WorkPermitWorkAutherization/WorkPermitWorkAutherization'
import WorkPermitEligibleText from '../components/WorkPermitEligibleText/WorkPermitEligibleText'
import { Helmet } from 'react-helmet'

const list1 = [
    {
        title:"Employer-specific work permit",
        content:"This type of work permit allows you to work for a single Canadian employer. Applying requires a valid job offer from a Canadian company."
    },
    {
        title:"Open work permit",
        content:"A work permit that is open allows you to work for any employer in Canada without needing a job offer. However, you must meet specific requirements in order to be eligible to apply for this type of work permit."
    },
    {
        title:"International Experience Canada (IEC) work permit",
        content:"This type of work permit is available to those between the ages of 18 and 35 from certain countries who want to both work and travel in Canada for a period of up to 2 years. It is part of the International Experience Canada (IEC) program, which offers three categories: Working Holiday, Young Professionals, and International Co-op."
    },
    {
        title:"Intra-Company Transfer work permit",
        content:"This type of work permit is for employees of an overseas firm who are being moved to a Canadian branch, subsidiary, or affiliate of the same company."
    },
    {
        title:"NAFTA work permit",
        content:"This work permit is for citizens of the United States or Mexico who are coming to work in Canada as part of the North American Free Trade Agreement (NAFTA)."
    },
    {
        title:"Agricultural worker program",
        content:"This type of work permit is for agricultural laborers who come to Canada to work on Canadian farms during the harvest"
    },
    {
        title:"Caregiver program",
        content:"This type of work permit is for individuals who are coming to the United States to work as caregivers for children, seniors, or people with disabilities."
    },
]

const list2 = [
    "Highly skilled workers hired for highly compensated roles in Canada fall under Category A.",
    "Candidates in Category B are being sought after for positions in high demand in Canada and need specific knowledge."
]

const list2_1 = [
    "Provide proof of the employment offer.",
    "Exceeding the required language proficiency minimum.", 
    "Having the necessary education.", 
    "Work history relevant to the position."
]

const list3 = [
    {
        title:"Determine your eligibility",
        content:"Be sure to satisfy the prerequisites for the kind of work permit you intend to request. Qualifying criteria may include factors such as your age, education, work background, language capabilities, and health."
    },
    {
        title:"Obtain a job offer",
        content:"In order to apply for a work permit that is specific to an employer, you must have a legitimate job offer from a Canadian employer. The employer must obtain a favorable Labor Market Impact Assessment (LMIA) if necessary."
    },
    {
        title:"Gather supporting documents",
        content:"You will need to provide documents such as your passport, educational certificates, and proof of work experience."
    },
    {
        title:"Complete the application form",
        content:"Fill out the work permit application form and make sure you provide accurate and complete information."
    },
    {
        title:"Pay the application fee",
        content:"There is a cost associated with applying for a Canadian work permit. The amount of the fee varies based on the kind of work permit and the place where you submit your application."
    },
    {
        title:"Submit the application",
        content:"Once you have filled out the application form and collected all the necessary documents, submit your application to the designated visa office or through the online portal."
    },
    {
        title:"Wait for a decision",
        content:"It can take several weeks or even months to process an application for a Canadian work permit. During this time, the visa officer might need more information from you or might set up an interview with you."
    },
]

const list4 = [
    {
        title:"Employer-specific work permits",
        content:"The processing fee for an employer-specific work permit is CAD 155."
    },
    {
        title:"Open work permits",
        content:"The processing fee for an open work permit is CAD 100."
    },
    {
        title:"International Experience Canada (IEC) work permits",
        content:"The fee for an IEC work permit is CAD 255."
    },
]

const list5 = [
    {
        title:"Inadmissibility",
        content:"If an individual is deemed to be ineligible to enter Canada due to criminal or medical problems, or any other reason, their application for a work permit may be denied."
    },
    {
        title:"Insufficient documentation",
        content:"If an applicant doesn’t give all the required paperwork to back up their work permit request, or if the documents given are not full or exact, their application may be denied."
    },
    {
        title:"Failure to meet eligibility requirements",
        content:"If an applicant does not fulfill the criteria necessary for the kind of work permit they are trying to obtain, their application could be denied."
    },
    {
        title:"Labour market concerns",
        content:"If the Canadian government determines that there are enough Canadian workers available to fill the job position, they may deny an application for a work permit."
    },
    {
        title:"Security concerns",
        content:"If an applicant is deemed to be a security risk or a threat to Canadian society, their application may be refused."
    },
    {
        title:"Financial concerns",
        content:"If an applicant does not have enough money to support themselves and their family while in Canada, their application may be refused."
    },
]

function workPermit() {
  return (
    <>
    <Helmet>
        <title>Work Permit in Canada - VCIC Immigration</title>
        <meta name="description" content="Need a work permit for Canada? Learn about the requirements to obtain a work permit and what it takes to start working in Canada."/>
        <link rel="canonical" href="https://vcicimmigration.com/work-permit-in-canada/"></link>
    </Helmet>
    <HeroBanner
        Heading="Work permit in "
        spanHeading="Canada"
        subHeading=""
        postParagraph=''
        Paragraph={"About 470,000 Foreigners Have Work Permits For Canada In 2019, Compared To 340,000 In 2017 And 390,000 In 2018.\n Canada is a land of possibilities, and many people from all over the globe are interested in working there. But in order to work in Canada, you must first obtain a work permit. Are you thinking of working in Canada but don’t know how to start? \n This guide will lead you through the process of getting a work permit in Canada. From finding out if you qualify to send in your application, this article will provide you with all the information you need to make the process go as smoothly as possible."}
        btnText=""
        bgImg="/images/creatives/work-permit-hero-bg.jpg"
        darkBg={true}
        headingCenter={true}
        btn={false}
        socialLinks={false}
        serviceForm={false}
        isChevron={true}
    />

<div style={{marginTop:"130px"}}></div>

    <InadmissibleText
            heading={"What Are "}
            spanHeading={"Canada Work Permit Types?"}
             img="/images/creatives/work-permit-text-1.png"
             shiftRow={true}
             content1="There are several types of Canadian work permits that you can apply for depending on your situation:"
             postHeading=""
             listPointList={true}
             listPoint={list1}
        />

<div style={{marginTop:"100px"}}></div>
    <WorkPermitEligibleText

    />

    <div style={{marginTop:"80px"}}></div>

    <TextContent
        shiftRow={false}
        img="/images/creatives/work-permit-text-2.png"
        heading="What Is The"
        spanHeading=" Global Skills Stream?"
        content={"With the support of the Global Skills Stream initiative, Canadian firms will have quicker and more dependable access to the best personnel from abroad, allowing them to remain competitive in the global economy. IT, engineering, and highly skilled professionals are eligible for this fast-track work visa program. \n Two categories of workers may apply for a work permit through the Global Skills Stream:"}
        btn={false}
        statSection={false}
        listPointList={true}
        listPoint={list2}
        postListContent={"Both the employer and the foreign employee must meet specific requirements to be eligible for the program, such as:"}
        postListPointList={true}
        postListPoint={list2_1}
        mobileTextCenter={true}
        endContent={"The program offers qualifying applicants a faster path to permanent residency. The work visa is typically provided for up to two years.\n Employers in Canada can access the top foreign talent thanks to the Global Skills Stream. That makes Canada more appealing to highly trained workers around the world."}
    />

    <div style={{marginTop:"120px"}}></div>

    <InadmissibleText
        heading={"How To Apply/"}
        spanHeading={"Obtain A Work Permit In Canada?"}
         img="/images/creatives/work-permit-text-3.png"
         shiftRow={true}
         content1="If you are an individual from a foreign country who wishes to work in Canada, you must acquire a Canadian work permit. Here are the general steps to take when applying for a Canadian work permit:"
         postHeading=""
         listPointList={true}
         listPoint={list3}
    />

    <WorkPermitCenteredText
        heading1="Can I Include My Family On My "
        spanHeading1="Work Permit Application?"
        afterSpanHeading1=""
        content1={"Yes, you can list your family members on your work visa application in some scenarios. \n Your spouse or partner (if any) and any dependent children may be permitted to join you in Canada and attend school there if you are seeking a working visa that an employer supports and the job offer is for a skilled position."}
        btn1={false}
        heading2="How Can I Include "
        spanHeading2="My Spouse And My Children On My Work Permit Application?"
        afterSpanHeading2=""
        content2={"If you wish to have your partner and dependent children included on your work permit application, you can indicate this on the form. You will need to provide their information, such as their full name, date of birth, and how they are related to you.\n If your spouse or children also need a visa to enter Canada, they must apply for a temporary resident visa or electronic travel authorization (ETA) on their own. You can submit their visa applications with yours, or they can complete the process online at a later date."}
        btn2={false}
    />
   

    <div style={{marginTop:"80px"}}></div>

    <InadmissibleText
        heading={"How Much"}
        spanHeading={" Does It Cost To Get A Work Permit?"}
        img="/images/creatives/work-permit-text-4.png"
        shiftRow={false}
        content1="Here are some general guidelines for the fees associated with Canadian work permits:"
        postHeading=""
        listPointList={true}
        listPoint={list4}
        content3={{content:"There may be additional fees for biometric information (CAD 85 per person) or a medical exam (costs vary depending on the country) with all these permits."}}
    />

    <CenteredTextLineDiv
        heading="What Documents Are "
        spanHeading="Required To Apply For A Canadian Work Permit?"
        afterSpanHeading=""
        content={"As of March 2023, the fees for the FSW program are as follows: \n A valid passport or travel document \n A job offer letter \n Proof of education and/or work experience \n Copy of Labour Market Impact Assessment (LMIA). \n Biometrics for Canadian work permits \n Medical exam for Canadian work permit \n Police clearances for Canadian work permits \n A completed application form \n Application fee \n Marriage certificate (if applicable) \n Evidence of financial means"}
        btn={false}
        dividerLength={true}
    />

    <CenteredText
        heading="What Is The "
        spanHeading="Processing Time Of A Work Permit Application?"
        afterSpanHeading=""
        content={"A Canadian work permit application may be processed in a few weeks or several months. For instance, a conventional work permit application typically takes 2 to 4 months, whereas a Global Talent Stream work visa application may take 2 weeks."}
        btn={false}
    />
    
    <div style={{marginTop:"100px"}}></div>

    <InadmissibleText
        shiftRow={true}
        heading={"Reasons A "}
        spanHeading={"Work Permit Application Is Refused"}
        img="/images/creatives/work-permit-text-5.png"
        content1="There are several reasons why a work permit application may be refused by Canadian immigration authorities. Here are some common reasons:"
        postHeading=""
        listPointList={true}
        listPoint={list5}
        btn={true}
        btnText="BOOK A FREE CALL"
        btnRef="/book-a-free-call"
    />

    <WorkPermitHeadingText/>

    <WorkPermitCenteredText
        heading1="What Is The Processing Time Of A "
        spanHeading1="LMIA Application?"
        afterSpanHeading1=""
        content1={"Canada aims to process LMIA applications within 10 business days of receiving a complete application. However, the actual processing time can take longer depending on the complexity of the application, the accuracy and completeness of the submitted documents, and the volume of applications being processed."}
        btn1={false}
        heading2="How Can I Check The Status Of "
        spanHeading2="My Work Permit Application?"
        afterSpanHeading2=""
        content2={"You can use the Canadian government’s online application tracking system to stay up to date on your application progress. You will need your application number, as well as your name, date of birth, and passport number to access the system. This information is usually given to you when you submit your work permit application."}
        btn2={false}
    />

    <WorkParmitAdjecentList
         shiftRow={true}
         img="/images/creatives/work-permit-text-6.png"
         mobileTextCenter={true}
    />

    <WorkPermitCenteredText
        heading1="Open Work "
        spanHeading1="Permit Canada"
        afterSpanHeading1=""
        content1={"An Open Work Permit allows foreigners to do any job in Canada without restrictions and without an LMIA.\n Foreign spouses/domestic partners of temporary foreign workers, international students, and spouses/domestic partners sponsored by domestic spouse/domestic partner sponsorship are eligible to apply for an Open Work Permit.\n International students may also apply for an open work permit under the Graduate Work Permit Program. International Experience Canada (IEC) candidates in the Working Holiday category are also eligible for an Open Work Permit."}
        btn1={false}
        heading2="Bridging Open "
        spanHeading2="Work Permit Canada"
        afterSpanHeading2=""
        content2={"The bridging open work permit (BOWP) is a way to keep a worker in Canada working while his or her application for permanent residence is being processed.\n In-Canada applicants who have made an application to immigrate to Canada under either the Federal Skilled Worker (FSW) Class, the Federal Skilled Trades (FST) Class, the Canadian Experience Class (CEC) or one of the Provincial Nominee Programs (PNP) may be considered for a bridging open work permit if their current work permit is due to expire (within four months). A foreign worker legally working in Canada who has made, or will soon make, an application for permanent residence under one of these immigration programs may then continue to work until a decision is made on his or her application for permanent residence."}
        btn2={false}
        alignText="start"
    />

    <WorkPermitWorkAutherization/>

    <TestimonialForm/>
    <CardBlog/>
    
</>
  )
}

export default workPermit