import React from 'react'
import "./PartnerCompanies.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules"

function PartnerCompanies({
  pTop=false,
}) {
  return (
    <div className='partnerCompanies_container  standard-padding-space' style={pTop ? {paddingTop:"9rem",paddingBottom:"4.2rem"}:{}}>
      <div className='container'>
        <div className='text-container pb-4 w-100'>
          <h3 className='body-heading text-center'>Our <span className='color-yellow'>Certifications</span> And <span className='color-yellow'>Associations</span></h3>

        </div>
        <div className="companies-logo-box py-4">
        <Swiper
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay:1500,
              disableOnInteraction:false
            }}
            modules={[Autoplay]}
            breakpoints={{
                200:{
                  slidesPerView: 1,
                },
                550: {
                  slidesPerView: 2,
                },
                800: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 5,
                },
              }}
            className="company-list"
            >
              <SwiperSlide className="d-flex align-items-center justify-content-center">
                <img alt="icon" src="/images/creatives/partner-logo-1.png" />
              </SwiperSlide>
              <SwiperSlide className="d-flex align-items-end justify-content-center">
                <img alt="icon" src="/images/creatives/partner-logo-2.png" />
              </SwiperSlide>
              <SwiperSlide className="d-flex align-items-end justify-content-center">
                <img alt="icon" src="/images/creatives/partner-logo-3.png" />
              </SwiperSlide>
              <SwiperSlide className="d-flex align-items-end justify-content-center">
                <img alt="icon" src="/images/creatives/partner-logo-4.png" />
              </SwiperSlide>
              <SwiperSlide className="d-flex align-items-end justify-content-center">
                <img alt="icon" src="/images/creatives/partner-logo-5.png" />
              </SwiperSlide>  
              <SwiperSlide className="d-flex align-items-end justify-content-center">
                <img alt="icon" src="/images/creatives/partner-logo-6.png" />
              </SwiperSlide>  


              <SwiperSlide className="d-flex align-items-center justify-content-center">
                <img src="/images/creatives/partner-logo-1.png" alt="icon" />
              </SwiperSlide>
              <SwiperSlide className="d-flex align-items-end justify-content-center">
                <img src="/images/creatives/partner-logo-2.png" alt="icon" />
              </SwiperSlide>
              <SwiperSlide className="d-flex align-items-end justify-content-center">
                <img src="/images/creatives/partner-logo-3.png" alt="icon" />
              </SwiperSlide>
              <SwiperSlide className="d-flex align-items-end justify-content-center">
                <img src="/images/creatives/partner-logo-4.png" alt="icon" />
              </SwiperSlide>
              <SwiperSlide className="d-flex align-items-end justify-content-center">
                <img src="/images/creatives/partner-logo-5.png" alt="icon" />
              </SwiperSlide>  
              <SwiperSlide className="d-flex align-items-end justify-content-center">
                <img src="/images/creatives/partner-logo-6.png" alt="icon" />
              </SwiperSlide>  
        </Swiper>
        </div>
        </div>
    </div>
  )
}

export default PartnerCompanies