import React from 'react'
import HeroBanner from '../components/Hero Banner/HeroBanner'
import TextContent from '../components/TextContent/TextContent'
import CardBlog from "../components/CardBlog/CardBlog"
import BussinessImmigration from "../components/BussinessImmigration/BussinessImmigration"
import { Helmet } from 'react-helmet'

function bussiness() {
    return (
        <>
        <Helmet>
            <title>Business Immigration in Canada - VCIC Immigration</title>
            <meta name="description" content='Want to immigrate to Canada through business? Learn all you need to know about business immigration in Canada here.'/>
            <link rel="canonical" href="https://vcicimmigration.com/business-immigration-in-canada/"></link>
        </Helmet>
        <HeroBanner
            Heading="Business Immigration in Canada: "
            spanHeading="A Path to Success as an Entrepreneur"
            subHeading=""
            postParagraph=''
            Paragraph="We fuel your business expansion with strategic immigration solutions, providing a pathway to global opportunities and sustained growth."
            btnText=""
            bgImg="/images/creatives/bussiness-bg.jpg"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            isChevron={true}
        />
    
        <div style={{marginTop:"80px"}}></div>
    
        <TextContent
            shiftRow={true}
            img="/images/creatives/bussiness-text-1.png"
            heading="Business Immigration In Canada:"
            spanHeading=" Path To Success As An Entrepreneur"
            subHeading=""
            postHeading=""
            postParagraph=''
            content={"Canada welcomes business investment and entrepreneurship. It is a desirable location for business investors and entrepreneurs from all over the world due to its robust economy, high standard of living, and favorable government policies. Numerous business immigration programs are available to assist you in achieving your objectives if you are interested in investing or starting a business in Canada. \n The purpose of Canada’s Business Immigration programs is to bring in investors, business owners, and those with the necessary skills, experience, and resources to boost the country’s economy. You can begin your journey toward entrepreneurial success by applying for permanent residency in Canada through these programs."}
            btn={false}
            btnText=''
            btnRef=''
            statSection={false}
            listPointList={false}
            mobileTextCenter={true}
        />
        <TextContent
            shiftRow={false}
            img="/images/creatives/bussiness-text-2.png"
            heading=""
            spanHeading=""
            subHeading=""
            postHeading=""
            postParagraph=''
            content={"The Start-up Visa Program, the Self-Employed Persons Program, and the Investor Visa Program are just a few of the business immigration programs that are available. Among other things, language proficiency, educational qualifications, and business experience are some of the program’s eligibility requirements. \n Entrepreneurs who have a novel idea for a business and the ability to bring it to life in Canada are eligible for the Start-up Visa Program. Entrepreneurs who have been committed by a designated Canadian venture capital fund, angel investor group, or business incubator are eligible to participate in this program.\n Individuals who have relevant self-employment experience in cultural activities, athletics, or farming and who intend to and are capable of being self-employed in Canada are eligible for the Self-Employed Persons Program.\n Individuals who are willing to passively invest CAD$800,000 in the Canadian economy and have a net worth of at least CAD$1.6 million are eligible for the Investor Visa Program. \n In conclusion, individuals who possess the skills, experience, and capital to contribute to the economy of Canada can find success as entrepreneurs through business immigration to Canada. There is a business immigration program that can assist you in achieving your objectives, including starting a business, investing in Canada, or becoming self-employed. So why delay? In Canada, you can immediately begin your path to success as an entrepreneur!"}
            btn={false}
            btnText=''
            btnRef=''
            statSection={false}
            listPointList={false}
            mobileTextCenter={true}
        />

        <BussinessImmigration/>
    
        <CardBlog/>
        
    </>
      )
}

export default bussiness