import React,{useState,useEffect} from 'react';
import './App.css';
import "./bootstrap.min.css"
import { Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import Home from "./screens/home"
import About from "./screens/about"
import Contact from './screens/contact';
import Service from './screens/service';
import Assessments from './screens/assessments';
import News from './screens/news'
import NewsInner from './screens/newsInner';
import ServiceInner from './screens/serviceInner';
import Footer from "./components/Footer/Footer"
import Visit from './screens/visit';
import Study from './screens/study';
import Citizenship from './screens/citizenship';
import Bussiness from './screens/bussiness';
import ProvincialNominee from './screens/provincialNominee';
import Inadmissible from './screens/inadmissibe';
import CareGiver from './screens/careGiver';
import ExpressEntry from './screens/expressEntry';
import FSTC from './screens/FSTC';
import CanadianExpressClass from './screens/canadianExpressClass';
import IntraWorkTransfer from './screens/intraWorkTransfer';
import FedralSkilledWorker from './screens/fedralSkilledWorker';
import IEC from './screens/IEC';
import WorkPermit from './screens/workPermit';

import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";
import FamilySponsor from './screens/familySponsor';

import NotFound from './screens/NotFound';
import ThankYou from './screens/ThankYou';
import PrivacyPolicy from './screens/PrivacyPolicy';

import LocationPages from "./components/LocationPages_/LocationPages"
import LocationScreen from "./screens/LocationScreens/LocationScreen"
import RefugeePage from './screens/RefugeePage';
import ECards from './screens/ECards';


function App() {
  const [blogRoute , setBlogRoute] = useState(null)

  useEffect(()=>{
    axios.get("/blogs-id")
    .then((res)=>{
        if(res.data.status === "success")
        {
          setBlogRoute(res.data.data)
           
        }
    })
    .catch((err)=>{
      console.log("eror =>",err);
    })
  },[])
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to="/" replace={true} />}/>
        <Route path="/immigrate" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/book-a-free-call" element={<Contact />} />
        <Route path="/service-areas" element={<Service />} />
        <Route path="/blog" element={<News />} />
        <Route path="/visa-to-visit-canada" element={<Visit />} />
        <Route path="/private-refugee" element={<RefugeePage />} />

        {/* <Route path="/servicesinner" element={<ServiceInner />} /> */}
        {/* <Route path="/newsinner" element={<NewsInner />} /> */}
        <Route path="/study-permit-for-canada" element={<Study />} />
        <Route path="/start-your-free-initial-assessment" element={<Assessments />} />
        <Route path="/citizenship-in-canada" element={<Citizenship />} />
        <Route path="/business-immigration-in-canada" element={<Bussiness />} />
        <Route path="/provincial-nominee-program" element={<ProvincialNominee />} />
        <Route path="/inadmissibility" element={<Inadmissible />} />
        <Route path="/caregiver-visa-canada" element={<CareGiver />} />
        <Route path="/express-entry-canada" element={<ExpressEntry />} />
        <Route path="/federal-skilled-trades-class" element={<FSTC />} />
        <Route path="/canadian-experience-class" element={<CanadianExpressClass />} />
        <Route path="/intra-company-transfers" element={<IntraWorkTransfer />} />
        <Route path="/federal-skilled-worker-program" element={<FedralSkilledWorker />} />
        <Route path="/work-permit-in-canada/working-holiday-visa-in-canada" element={<IEC />} />
        <Route path="/work-permit-in-canada" element={<WorkPermit />} />
        <Route path="/spousal-sponsorship-canada" element={<FamilySponsor />} />
        <Route path="/e-card" element={<ECards />} />
        

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />


        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/blogs" element={<BlogsScreen />} />
        
        <Route path='/location/:slug' element={<LocationPages />} />
        <Route path='/admin/location-pages' element={<LocationScreen />}/>

        {
          blogRoute &&
            blogRoute.map((ele)=>{
              return(<Route path={`/${ele}`} element={<NewsInner />} />)
            })
          }
        {/* <Route path="/blogs/:id" element={<NewsInner />} /> */}
        <Route path="/login" element={<LoginScreen />} />

        <Route path="/thank-you" element={<ThankYou />} />

        <Route path="*" element={<NotFound />} />

      </Routes>
      <Footer/>
    </>
  );
}

export default App;