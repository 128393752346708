import React from 'react'
import "./WorkParmitAdjecentList.css"

const list = [
    "Reciprocal employment", "International trade agreements", 
    "Entrepreneurs & self-employed", "International youth exchange programs",
    "Intra-company transferees", "Minister-designated exemptions",
    "French-speaking skilled workers",
]

function WorkParmitAdjecentList({
    shiftRow=false,
    img,
    mobileTextCenter=false
}) {
  return (
    <div className='WorkParmitAdjecentList-container standard-margin-space'>
        <div className='container'>
            <div className={`row d-flex ${ shiftRow ? "flex-row-reverse" : ""} align-items-center justify-content-between gy-4`}>
                <div className='col-md-6 h-100 my-auto'>
                    <img src={ img} className='img-fluid' alt='img'/>
                </div>
                <div className='col-md-6 px-2' style={shiftRow?{paddingLeft:"10px"}:{paddingRight:"10px"}}>
                    <div className={`text-content d-flex flex-column align-items-start justify-content-center gap-lg-1 h-100 ${mobileTextCenter && "px-md-auto px-2"} `} >
                        
                        <h3 className='heading body-heading'>LMIA Exempt <span className='color-yellow'>Work Permit</span></h3>
                        
                        <p className='content body-paragraph mb-0'>
                            <div>
                            <p className='content body-paragraph my-1 mb-2'>Most Canadian work permits require an LMIA, or Labor Market Impact Assessment. Some employers skip the LMIA document retrieval process as it can be time consuming. Luckily, there are some work permits in Canada that are not covered by her LMIA.</p>
                            <p className='content body-paragraph my-1 mb-2'> Employers are often required to apply for an LMIA before hiring foreign workers in Canada. A positive LMIA (also known as a Letter of Confirmation) indicated that the employer had unsuccessfully tried to recruit a Canadian citizen or permanent resident for the position and had to hire a foreign national. Most Canadian work permits require foreign applicants to provide a copy of a positive LMIA along with their LMIA number.</p>
                            </div>
                        </p>

                        <h4 className='body-heading my-2' style={{fontSize:"24px",fontWeight:"normal"}}>Significant Benefit</h4>
                        <p className='content body-paragraph my-1 mb-2'>This exemption is available if the employer can demonstrate that it brings significant social, cultural or economic benefits to Canada.</p>
                        
                        {
                            <div className='location-list-container'>
                                <div className='row'>
                                    {
                                        list.map((ele)=>{
                                            return(
                                                <div className='d-flex align-items-center col-md-6 gap-2 icon-list'>
                                                    <img src='/images/icons/hero-bullets.png' className='list-bullet mt-1' alt='img'/>
                                                    <p className='list-para mb-0'>{ele}</p>
                                                </div>)
                                        })
                                    }
                                </div>
                            </div>    
                        }
                        
                       
                        {/* { btn && <button className="mt-3 genral-btn" onClick={()=>{navigate(btnRef)}}>{btnText}</button>} */}
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default WorkParmitAdjecentList