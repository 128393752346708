import React from 'react';
import "./OurProcess.css";

const processData = [
    {img:"/images/creatives/process1.png",title:"BOOK A CONSULTATION",content:"Book a free call or start your free assessment to explore available pathways, determine the best immigration pathway and move forward with the process."},
    {img:"/images/creatives/process2.png",title:"RETAINER AGREEMENT",content:"We will send you a retainer agreement that clearly outlines the terms and conditions of our representation for your immigration application."},
    {img:"/images/creatives/process3.png",title:"DOCUMENT COLLECTION",content:"Upon receiving the signed agreement, the process of collecting documents will begin."},
    {img:"/images/creatives/process4.png",title:"APPLICATION PREPARATION",content:"After initial documents are collected, we will start preparing your immigration application."},
    {img:"/images/creatives/process5.png",title:"APPLICATION SUBMISSION",content:"Quality Check: Your immigration application is checked three times before submitting it to IRCC."},
    {img:"/images/creatives/process6.png",title:"COORDINATION WITH IRCC",content:"After submission, we will act as an authorized consultant between you and IRCC to coordinate any further document submissions or clarifications that may be needed by IRCC."},
    {img:"/images/creatives/process7.png",title:"WELCOME TO CANADA",content:"If the process goes as expected and the results are in your favour, get ready to start a new life in Canada!"},
];

function OurProcess() {
    return (
        <div className='process-container standard-padding-space'>
        <div className='container'>
            <div className='text-container text-center'>
                <h3 className='body-heading'>Our <span className='color-yellow'>Process</span></h3>
                <p className='body-paragraph'>We pride ourselves on having a transparent process. We make sure that the process is smooth and straightforward for you.</p>
            </div>
            <div className='process-list'>
                <div className='row gy-3 justify-content-center'>
                    {processData.map((ele, index) => (
                        <div key={index} className='col-lg-4 col-md-4 py-3'>
                            <div className='border-box justify-content-center'>
                                <img className='banner-img' src={ele.img} alt={ele.title} />
                                <h3 className='card-title'>{ele.title}</h3>
                                <p className='body-paragraph mt-3'>{ele.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
    );
}

export default OurProcess;
