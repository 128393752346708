import React from 'react'
import "./InadmissibleText.css"
import { useNavigate } from 'react-router-dom'

function InadmissibleText({
    heading,
    spanHeading,
    img,
    shiftRow=false,
    content1,
    postHeading="",
    content2="",
    listPoint=null,
    topHeading=false,
    content3="",
    btn=false,
    btnText="",
    btnRef="",
}) {
    const navigate = useNavigate()
  return (
    <div className='inadmissible-text-container mt-5'>
        {
            topHeading 
            && 
            (<div className='text-content container text-center py-5 mx-auto'>
                <h3 className='body-heading'>What Are The <span className='color-yellow'>Consequences Of Inadmissibility?</span></h3>
                <p className='body-paragraph'>Any individual who is deemed as ‘inadmissible’ is not allowed to enter the country and may be deported if he/she tries to enter. Inadmissibility will affect an individuals ability to obtain a visa or immigrate to Canada.</p>
            </div>)

        }
        <div className='text-container standard-margin-space'>
            <div className='container'>
                <div className={`row d-flex ${ shiftRow ? "flex-row-reverse" : ""} align-items-center justify-content-between gy-5 gap-md-0 gap-3`}>
                    <div className='col-md-6 h-100 my-auto'>
                        <img src={img} alt="text content" className='img-fluid'/>
                    </div>
                    <div className='col-md-6 h-100 my-auto px-4 px-md-3'>
                        <h3 className='body-heading'>
                            {heading}
                            <span className='color-yellow'>{spanHeading}</span>
                        </h3>
                        <p className="content body-paragraph mb-0 mt-3">{content1}</p>

                        <h3 className='post-heading mt-3'>{postHeading}</h3>
                        <p className='content body-paragraph mb-0'>{content2}</p>

                        <div className=' mb-3 pt-2'>
                        {
                            listPoint?
                            listPoint.map(ele =>{
                                return(
                                    <div className='d-flex align-items-start gap-md-3 gap-2 icon-list mb-3'>
                                        <img src='/images/icons/hero-bullets.png' className='list-bullet mt-2' alt="Hero-img"/>
                                        <p className='body-paragraph mb-0'><b>{ele.title}{ele.title==""?"":" :"}</b> {ele.content}</p>
                                    </div>)
                            })
                            :
                            <></>
                        }
                        </div>
                        {content3=="" ?<></>: <p className='content body-paragraph mb-0'><b>{content3.title}</b>{content3.content}</p>}
                        { btn && <button className="mt-4 genral-btn mx-auto" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default InadmissibleText